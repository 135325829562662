/**
 * Site
 *
 * Primary entry into React app
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-07
 */

// NPM modules
import React, { useEffect, useState } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';

// Material UI
import { ThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import Hash from 'shared/generic/hash';
import { afindi, clone, sortByKey } from 'shared/generic/tools';

// Shared hooks
import { useEvent } from 'shared/hooks/event';
import { useResize } from 'shared/hooks/resize';

// CSS Theme
import Theme from 'components/Theme'

// Site composite modules
import NotVerified from 'components/composites/NotVerified';
import Stopped from 'components/composites/Stopped';

// Site component modules
import Errors from 'components/Errors';
import Header from 'components/Header';
import Offline from 'components/Offline';

// Page component modules
import Account from 'components/pages/Account';
import Admin from 'components/pages/admin';
import Employee from 'components/pages/employee';
import Employer from 'components/pages/employer';
import Forgot from 'components/pages/Forgot';
import HowTo from 'components/pages/HowTo';
import Posting from 'components/pages/Posting';
import PrivacyPolicy from 'components/pages/PrivacyPolicy';
import Root from 'components/pages/root';
import TermsAndConditions from 'components/pages/TermsAndConditions';
import Unsubscribe from 'components/pages/Unsubscribe';
import Verify from 'components/pages/Verify';

// Data modules
import Messaging from 'data/messaging';

// Rest init
import 'rest_init';

// CSS
import 'sass/site.scss';

// Init the hash
Hash.init();

/**
 * Site
 *
 * Primary site component
 *
 * @name Site
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Site(props) {

	// State
	let [mobile, mobileSet] = useState(document.documentElement.clientWidth < 600 ? true : false);
	let [offline, offlineSet] = useState(!window.navigator.onLine);
	let [user, userSet] = useState(null);
	let [locale, localeSet] = useState(user ? user.locale : 'fr-CA');

	// Hooks
	let history = useHistory();

	// load effect
	useEffect(() => {
		if(Rest.session()) {
			Rest.read('main', 'session', {}).done(res => {
				Events.trigger('signedIn', res.data.user);
			});
		} else {
			userSet(false);
		}

		// Track online/offline
		window.addEventListener('online', () => offlineSet(false));
		window.addEventListener('offline', () => offlineSet(true));

	}, []);

	// Sign In/Out hooks
	useEvent('signedIn', user => {

		// If the user is employer
		if(user.employer && user.employer.length) {
			user.employer_current = user.employer[0]._id;
			Messaging.start(user.employer_current, 'r');
		}

		// Else if the user is an employee
		else if(user.employee) {
			Messaging.start(user._id, 'e');
		}

		// Set the user
		userSet(user);

		// Set the locale based on the user
		localeSet(user.locale);
	});
	useEvent('signedOut', () => {
		Messaging.stop();
		userSet(false);
		history.push('/');
	});

	// Locale hook
	useEvent('locale', locale => {
		localeSet(locale);
		if(user) {
			Rest.update('main', 'locale', {
				locale: locale
			});
		}
	});

	// Changed employer hook
	useEvent('employer_change', employer => {

		// Clone the current employers
		let lEmployers = user.employer || [];

		// If we find the employer and the name changed
		let i = afindi(lEmployers, '_id', employer._id);
		if(i > -1 && lEmployers[i].name !== employer.name) {

			// Set the new name and update the user
			lEmployers[i].name = employer.name;

			// Sort the values
			lEmployers.sort(sortByKey('name'));

			// Set the new user
			user.employer = lEmployers;
			userSet(clone(user));
		}
	});

	// Current employer hook
	useEvent('employer_current', _id => {

		// Move to home
		history.push('/employer');

		// Overwrite the user
		userSet(val => {
			val.employer_current = _id;
			return clone(user);
		});

		// Update messaging
		Messaging.start(_id, 'r');
	});

	// New employer hook
	useEvent('employer_new', employer => {

		// Overwrite the user
		userSet(val => {

			// Clone the current employers
			let lEmployers = user.employer || [];

			// Add the new employer
			lEmployers.push({
				_id: employer._id,
				name: employer.name
			});

			// Sort the values
			lEmployers.sort(sortByKey('name'));

			// Overwrite the employers
			val.employer = lEmployers;

			// Set the new employer to the current
			val.employer_current = employer._id;

			// Return the new user
			return clone(val);
		});
	});

	// Resize hooks
	useResize(() => mobileSet(document.documentElement.clientWidth < 600 ? true : false));

	// Use is no longer STOPped
	function userNotStopped() {
		userSet(val => {
			if(val) {
				delete val.stop;
				return clone(val);
			} else {
				return val;
			}
		});
	}

	// User is verified
	function userVerified() {
		userSet(val => {
			if(val) {
				val.verified = true;
				return clone(val);
			} else {
				return val;
			}
		});
	}

	// Render
	return (
		<StylesProvider injectFirst={true}>
			<ThemeProvider theme={Theme}>
				{offline &&
					<Offline locale={locale} mobile={mobile} />
				}
				<Header
					locale={locale}
					mobile={mobile}
					user={user || false}
				/>
				{user !== null &&
					<div id="content" className="flexGrow">
						<Switch>
							{user &&
								<Route exact path="/account">
									<Account
										locale={locale}
										mobile={mobile}
										user={user}
									/>
								</Route>
							}
							{user && user.admin &&
								<Route path="/admin">
									<Admin
										mobile={mobile}
										user={user}
									/>
								</Route>
							}
							{user && user.employee &&
								<Route path="/employee">
									<Employee
										locale={locale}
										mobile={mobile}
										user={user}
									/>
								</Route>
							}
							{user && user.employer &&
								<Route path="/employer">
									<Employer
										current={user.employer_current}
										locale={locale}
										mobile={mobile}
										user={user}
									/>
								</Route>
							}
							<Route path="/forgot">
								<Forgot locale={locale} />
							</Route>
							<Route exact path="/how-to">
								<HowTo locale={locale} />
							</Route>
							<Route exact path="/posting/:_id">
								<Posting locale={locale} mobile={mobile} user={user} />
							</Route>
							<Route exact path="/privacy-policy">
								<PrivacyPolicy locale={locale} />
							</Route>
							<Route exact path="/terms-and-conditions">
								<TermsAndConditions locale={locale} />
							</Route>
							<Route exact path="/unsubscribe">
								<Unsubscribe locale={locale} />
							</Route>
							<Route path="/verify">
								<Verify
									onVerified={userVerified}
									user={user}
								/>
							</Route>
							<Route path="/">
								<Root
									locale={locale}
									mobile={mobile}
									user={user}
								/>
							</Route>
						</Switch>
					</div>
				}
				{(user && user.verified !== true) &&
					<NotVerified
						locale={locale}
						onVerified={userVerified}
					/>
				}
				{(user && user.stop) &&
					<Stopped
						locale={locale}
						onNotStopped={userNotStopped}
						twilioNumbers={user.stop}
					/>
				}
				<Errors />
			</ThemeProvider>
		</StylesProvider>
	);
}

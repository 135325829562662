/**
 * Main
 *
 * Displays the main employer page
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-20
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// Shared components
import ConfirmButton from 'shared/components/ConfirmButton';

// Composite components
import HoursDisplay from 'components/composites/HoursDisplay';
import Share from 'components/composites/Share';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { nice } from 'shared/generic/dates';
import { min, safeLocalStorageBool } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

/**
 * Posting
 *
 * Displays a single posting
 *
 * @name Posting
 * @access private
 * @param Object props Attributes sent to the component
 * @returns React.Component
 */
function Posting(props) {

	// State
	let [share, shareSet] = useState(false);

	// Share variables
	let sDate = props.value.times.type === 'gig' ?
				min(props.value.times.shifts.map(o => o.starts)).substr(0,16):
				props.value.times.starts;
	let oShare = {
		url: `https://${window.location.host}/posting/${props.value._id}`,
		text: `${props.info.professions[props.value.profession][props.locale]}, ${props.value.salary_amount}/${translate('common', 'salary_type_' + props.value.salary_type, props.locale)}, ${sDate}`
	}

	// Called to mark an open posting as filled
	function markFilled() {

		// Send the request to the server
		Rest.update('main', 'posting/filled', {
			_id: props.value._id
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If we're successful
			if(res.data) {

				// Notify the parent the posting was marked as filled
				props.onFilled(props.value._id);
			}
		});
	}

	// Render
	return (
		<React.Fragment>
			<Box className="employer_posting flexColumns">
				<Box className="details flexGrow">
					<Typography className="posting_name">
						<span>{props.info.professions[props.value.profession][props.locale]}</span>
						&nbsp;&nbsp;
						<span className="salary blue_oval">
							${props.value.salary_amount}/{translate('common', 'salary_type_' + props.value.salary_type, props.locale)}
							{props.value.tip_average &&
								<span> + &#x2248;${props.value.tip_average}/{translate('common', 'salary_type_hourly', props.locale)}</span>
							}
						</span>
					</Typography>
					<Typography className="posting_type">{translate('common', 'times_' + props.value.times.type, props.locale)}</Typography>
					{props.value.times.type !== '' &&
						<React.Fragment>
							{props.value.times.type === 'gig' ?
								<Box>
									{props.value.times.shifts.map((o,i) =>
										<Typography key={i}>
											{nice(o.starts.substr(0,10), props.locale, (props.mobile ? 'short' : 'long'), false, false)}
											<span className="blue"> @ </span>
											{o.starts.substr(11,5)}
											<HoursDisplay locale={props.locale} value={o.hours} />
										</Typography>
									)}
								</Box>
							:
								<Typography className="starts">
									{nice(props.value.times.starts, props.locale, (props.mobile ? 'short' : 'long'), false, false)}
									{props.value.times.ends &&
										<span> - {nice(props.value.times.ends, props.locale, (props.mobile ? 'short' : 'long'), false, false)}</span>
									}
									<HoursDisplay locale={props.locale} value={props.value.times.hours} />
								</Typography>
							}
						</React.Fragment>
					}
					{props.value.skills && props.value.skills.length > 0 &&
						<Typography className="skills">
							{props.value.skills.map(s =>
								<span key={s} className="skill">{props.info.skills[s][props.locale]}</span>
							)}
						</Typography>
					}
					{props.value.description && props.value.description.split('\n').map((s,i) => <Typography key={i} className="description">{s}</Typography>)}
					{props.value.filled === false &&
						<Box className="center">
							<ConfirmButton
								buttonProps={{
									variant: 'contained'
								}}
								component={Button}
								confirmText={translate('employer', 'mark_filled_confirm', props.locale)}
								onClick={markFilled}
							>
								{translate('employer', 'mark_filled', props.locale)}
							</ConfirmButton>
						</Box>
					}
				</Box>
				<Box className="flexStatic center">
					{props.value.filled === false &&
						<Typography>
							<Link to={'/employer/posting/edit/' + props.value._id}>
								<i className="fas fa-edit" title={translate('employer', 'posting_edit', props.locale)} />
							</Link>
						</Typography>
					}
					<Typography>
						<Link to={'/employer/posting/matches/' + props.value._id}>
							<i className="fas fa-address-card" title={translate('common', 'matches', props.locale)}>
								{props.value.matches > 0 && <span className="count">{props.value.matches}</span>}
							</i>
						</Link>
					</Typography>
					{props.value.filled === false &&
						<Typography>
							<Link to={'/employer/posting/match/' + props.value._id}>
								<i className="fas fa-people-arrows" title={translate('employer', 'posting_start', props.locale)}>
									{props.value.potential_matches > 0 && <span className="count">{props.value.potential_matches}</span>}
								</i>
							</Link>
						</Typography>
					}
					{props.value.filled === false &&
						<Typography onClick={() => shareSet(true)}>
							<i className="fas fa-share-alt link" title={translate('common', 'share', props.locale)} />
						</Typography>
					}
					{props.value.filled === true &&
						<Typography>
							<Link to={'/employer/posting/copy/' + props.value._id}>
								<i className="fas fa-copy" title={translate('employer', 'posting_copy', props.locale)} />
							</Link>
						</Typography>
					}
				</Box>
			</Box>
			{share &&
				<Dialog
					className="employer_posting_share"
					onClose={() => shareSet(false)}
					open={true}
					TransitionComponent={Transition}
				>
					<DialogContent className="center">
						<Share
							locale={props.locale}
							text={oShare.text}
							url={oShare.url}
						/>
					</DialogContent>
				</Dialog>
			}
			<hr className="blue" />
		</React.Fragment>
	);
}

// Valid props
Posting.propTypes = {
	locale: PropTypes.string.isRequired,
	info: PropTypes.object.isRequired,
	onFilled: PropTypes.func,
	value: PropTypes.object.isRequired
}

/**
 * Main
 *
 * Handles main employer page
 *
 * @name Main
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Main(props) {

	// State
	let [filled, filledSet] = useState([]);
	let [showFilled, showFilledSet] = useState(safeLocalStorageBool('show_filled', false));
	let [postings, postingsSet] = useState([]);

	// Posting effect
	useEffect(() => {

		// Init new arrays
		let lFilled = [];
		let lPostings = [];

		// If we have an employer and it's approved
		if(props.employer && props.employer.approved) {

			// Go through each posting
			for(let o of props.employer.postings) {
				if(!o.filled) {
					lPostings.push(o);
				} else {
					lFilled.push(o);
				}
			}
		}

		// Store the postings found
		filledSet(lFilled);
		postingsSet(lPostings);

	}, [props.employer]);

	// Render
	return (
		<Box className="singlePage padding" style={{padding: '15px'}}>
			<Box className="flexColumns">
				<Box className="flexStatic">
					<Typography variant="h3">{translate('employer', 'postings', props.locale)}</Typography>
				</Box>
				{props.employer.approved &&
					<React.Fragment>
						<Box className="flexGrow">
							<Link to="/employer/posting/create">
								<Tooltip title={translate('employer', 'posting_add', props.locale)}>
									<IconButton>
										<i className="fas fa-plus header" />
									</IconButton>
								</Tooltip>
							</Link>
						</Box>
						{filled.length > 0 &&
							<Box className="flexStatic">
								<FormControlLabel
									control={
										<Switch
											checked={showFilled}
											color="primary"
											onChange={ev => {
												showFilledSet(ev.target.checked);
												localStorage.setItem('show_filled', ev.target.checked ? 'x' : '');
											}}
										/>
									}
									label={translate('employer', 'show_filled', props.locale)}
									labelPlacement="start"
								/>
							</Box>
						}
					</React.Fragment>
				}
			</Box>
			{props.employer && props.employer.approved ?
				<React.Fragment>
					<hr className="blue" />
					{showFilled && filled.map(o =>
						<Posting
							key={o._id}
							locale={props.locale}
							info={props.info}
							value={o}
						/>
					)}
					{props.employer.postings.length === 0 ?
						<Typography>{translate('employer', 'postings_none', props.locale)}</Typography>
					:
						postings.map(o =>
							<Posting
								onFilled={props.filled}
								key={o._id}
								locale={props.locale}
								info={props.info}
								value={o}
							/>
						)
					}
				</React.Fragment>
			:
				<Typography>{translate('employer', 'not_approved', props.locale)}</Typography>
			}
		</Box>
	);
}

// Valid props
Main.propTypes = {
	employer: PropTypes.oneOfType([PropTypes.object,PropTypes.bool]).isRequired,
	filled: PropTypes.func.isRequired,
	locale: PropTypes.string.isRequired,
	info: PropTypes.object.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}

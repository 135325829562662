/**
 * Matches: Matches
 *
 * Handles signup stats page
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-11
 */

// NPM modules
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import { iso, increment } from 'shared/generic/dates';
import Events from 'shared/generic/events';
import { safeLocalStorage } from 'shared/generic/tools';

// Constants
const CHART_COLOURS = {
	full: 'rgb(255, 255, 0, 0.9)',
	employee: 'rgb(255, 0, 0, 0.9)',
	posting: 'rgb(0, 255, 0, 0.9)'
}

/**
 * Matches
 *
 * Displays a button to allow clearing all existing matches
 *
 * @name Matches
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Matches(props) {

	// State
	let [end, endSet] = useState(iso(new Date(), false));
	let [range, rangeSet] = useState(safeLocalStorage('match_stats_graph_range', 'day'));
	let [start, startSet] = useState(iso(increment(-14), false));
	let [stats, statsSet] = useState(false);

	// Change change
	useEffect(() => {
		localStorage.setItem('match_stats_graph_range', range);
	}, [range]);

	// Called to fetch the stats from the server
	function statsFetch() {

		// Make the REST request
		Rest.read('admin', 'match/stats/graph', {
			range_type: range,
			range: [start, end]
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If we have data, set the stats
			if(res.data) {
				statsProcess(res.data);
			}
		});
	}

	// Process the incoming data so it fits the Chart
	function statsProcess(data) {

		// Pull off the dates
		let lDates = data.dates;
		delete data.dates;

		// Init the datasets
		let lDataSets = []

		// Go through the remaining data and create individual datasets
		for(let k in data) {
			lDataSets.push({
				label: k,
				data: lDates.map(s => data[k][s]),
				fill: false,
				borderColor: CHART_COLOURS[k],
				lineTensions: 0.1,
				beginAtZero: true
			});
		}

		// Set the stats
		statsSet({
			labels: lDates,
			datasets: lDataSets
		});
	}

	// Generate today date
	let sToday = iso(new Date(), false);

	// Render
	return (
		<Box className="singlePage padding">
			<Box className="filter">
				<TextField
					inputProps={{
						min: '2022-01-01',
						max: sToday
					}}
					label="Start"
					onChange={ev => startSet(ev.target.value)}
					placeholder="Start"
					type="date"
					variant="outlined"
					value={start}
					InputLabelProps={{
						shrink: true,
						style: {backgroundColor: 'black', padding: '0 5px'}
					}}
				/>
				<span> to </span>
				<TextField
					inputProps={{
						min: '2022-01-01',
						max: sToday
					}}
					label="End"
					onChange={ev => endSet(ev.target.value)}
					placeholder="End"
					type="date"
					variant="outlined"
					value={end}
					InputLabelProps={{
						shrink: true,
						style: {backgroundColor: 'black', padding: '0 5px'}
					}}
				/>
				<span>&nbsp;</span>
				<FormControl size="small" variant="outlined">
					<InputLabel htmlFor="filter-range" style={{backgroundColor: 'black', padding: '0 5px'}}>Range</InputLabel>
					<Select
						inputProps={{id: 'filter-range'}}
						native
						onChange={ev => rangeSet(ev.target.value)}
						value={range}
					>
						<option value="day">Day</option>
						<option value="week">Week</option>
						<option value="month">Month</option>
					</Select>
				</FormControl>&nbsp;
				<Button
					color="primary"
					onClick={statsFetch}
					variant="contained"
				>Fetch</Button>
			</Box>
			{stats !== false &&
				<Box style={{marginTop: '10px'}}>
					{stats.length === 0 ?
						<Typography>No stats found</Typography>
					:
						<Line
							beginAtZero={true}
							data={stats}
							options={{scales:{yAxes:{ticks:{beginAtZero:true,callback:value => { if(Number.isInteger(value)) return value; }}}}}}
						/>
					}
				</Box>
			}
		</Box>
	);
}

// Valid props
Matches.propTypes = {
	mobile: PropTypes.bool.isRequired
}


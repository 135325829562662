/**
 * Touch
 *
 * Functions for dealing with touch features
 *
 * @author Chris Nasr <chris@ouroboroscooding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-05-22
 */

/**
 * Is Touch
 *
 * Returns true if the device has touch capabilities
 *
 * @name isTouch
 * @access public
 * @return Boolean
 */
export function isTouch() {
	return (('ontouchstart' in window) ||
			(navigator.maxTouchPoints > 0) ||
			(navigator.msMaxTouchPoints > 0));
}

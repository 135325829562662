/**
 * Messaging: Conversation
 *
 * Displays a single conversation
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-19
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Composite components
import EmployeeView from 'components/composites/employee/View';
import Messages from 'components/composites/Messages'
import PostingView from 'components/composites/employer/posting/View';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import PageVisibility from 'shared/generic/pageVisibility';
import { clone, combine } from 'shared/generic/tools';

// Data modules
import Messaging from 'data/messaging';
import translate from 'data/translate';

/**
 * Conversation
 *
 * Handles employer messaging
 *
 * @name Conversation
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Conversation(props) {

	// State
	let [blockConfirm, blockConfirmSet] = useState(false);
	let [content, contentSet] = useState('');
	let [convo, convoSet] = useState(null);
	let [displayName, displayNameSet] = useState(false);
	let [employee, employeeSet] = useState(null);
	let [menuAnchor, menuAnchorSet] = useState(null);
	let [posting, postingSet] = useState(null);

	// Hooks
	let { _id } = useParams();
	let history = useHistory();

	// Convo effect
	useEffect(() => {

		// Fetch the convo
		convoFetch();

		// Track any live changes
		Messaging.subscribe(_id, messaging);

		// Track page visibility changes
		function pageVis(property, state) {
			if(state === 'visible') {
				convoFetch();
			}
		}
		PageVisibility.subscribe(pageVis);

		// Unsubscribe on exit
		return () => {
			Messaging.unsubscribe(_id, messaging);
			PageVisibility.unsubscribe(pageVis);
		}

	// eslint-disable-next-line
	}, [_id]);

	// Block the convo
	function block() {

		// Hide the menu
		menuAnchorSet(null);

		// Block the convo
		Messaging.block(convo._id, () => {

			// Hide the block confirm dialog
			blockConfirmSet(false);
		});
	}

	// Trap content key presses
	function contentPress(ev) {
		if(ev.key === 'Enter' && ev.shiftKey === false) {
			ev.preventDefault();
			send();
			return false;
		}
	}

	// Fetch the messages in a conversation
	function convoFetch() {

		// Clear the current conversation
		convoSet(null);

		// Get the messages from the Rest service
		Rest.read('main', 'employer/conversation', {
			_id: _id
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {

				// Set the conversation
				convoSet(res.data);

				// If there's any unread
				if(res.data.employer_info.unread) {

					// Clear the unread
					Messaging.read(_id);
				}
			}
		});
	}

	// Save the new display name
	function displayNameSave() {

		// Get the name
		let sName = displayName.trim();

		// If it's empty, do nothing
		if(sName === '') {
			return;
		}

		// If it's the same
		if(sName === convo.employer_info.display_name) {
			displayNameSet(false);
		}

		// Rename the convo
		Messaging.rename(convo._id, sName, () => {

			// Hide the rename edit
			displayNameSet(false);
		});
	}

	// Fetch employee data and show the preview
	function employeeView() {

		// Hide the menu (just in case)
		menuAnchorSet(null);

		// Get the employee from the server
		Rest.read('main', 'employee', {
			_id: convo.employee
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If we got the employee
			if(res.data) {

				// Add the background image
				res.data.background = (res.data.photos && res.data.photos.length) ?
					res.data.photos[0].url :
					process.env.REACT_APP_NO_PHOTO_URL;

				// Create list of professions
				let lProfessions = [res.data.professions.one];
				for(let s of ['two', 'three']) {
					if(res.data.professions[s] !== null) {
						lProfessions.push(res.data.professions[s]);
					}
				}
				res.data.professions = lProfessions;

				// Set the state
				employeeSet(res.data);
			}
		});
	}

	// Called to view a posting
	function postingView(_id) {

		// Get the posting from the server
		Rest.read('main', 'posting', {
			_id: _id
		}).done(res => {
			if(res.error && !res._handled) {
				if(res.error.code === 2003) {
					Events.trigger('error', translate('errors', 'not_found', props.locale, {
						name: translate('common', 'posting', props.locale)
					}));
				} else {
					Events.trigger('error', res.error);
				}
			} else if(res.data) {
				// Add the background image
				res.data.background = (res.data.employer.photos && res.data.employer.photos.length) ?
					res.data.employer.photos[0].url :
					process.env.REACT_APP_NO_PHOTO_URL;
				postingSet(res.data);
			}
		});
	}

	// Hide the current conversation
	function hide() {

		// Hide the menu
		menuAnchorSet(null);

		// Hide the convo
		Messaging.hide(convo._id, () => {

			// Redirect to the conversation list
			history.push('/employer/messaging');
		});
	}

	// Receives any websocket messages or updates
	function messaging(msg) {

		// Switch on message type
		switch(msg.name) {

			// If we got new info
			case 'info':

				// Merge it with the existing convo
				convoSet(val => combine(val, msg.data));
				break;

			// If we got a new message
			case 'message':

				// Add it to the end of the messages
				convoSet(val => {
					val.messages.push(msg.data);
					return clone(val);
				});

				// Let the server know we read it
				Messaging.read(_id);
				break;

			// Unknown message
			default:
				Events.trigger('error', JSON.stringify(msg));
				break;
		}
	}

	// Send the message
	function send() {

		// If the message is empty, do nothing
		if(content.trim() === '') {
			return;
		}

		// Send a message
		Messaging.send(convo._id, content, () => {

			// Add the message to the state
			let oConvo = clone(convo);
			oConvo.employer_info.unread += 1;
			oConvo.messages.push({
				creator: 'r',
				created: Date.now()/1000,
				content: content,
				read: false
			});
			convoSet(oConvo);

			// Clear content
			contentSet('');
		});
	}

	// Unblock the convo
	function unblock() {

		// Hide the menu
		menuAnchorSet(null);

		// Unblock the convo
		Messaging.unblock(convo._id);
	}

	// If we are still loading the conversation
	if(convo === null) {
		return (
			<Box className="padding">
				<Typography>{translate('common', 'loading', props.locale)}</Typography>
			</Box>
		);
	}

	// Render
	return (
		<Box className="conversation flexRows">
			<Box className="conversation_display flexColumns">
				<Box className="flexGrow">
					{displayName ? (
						<React.Fragment>
							<TextField
								onChange={ev => displayNameSet(ev.target.value)}
								type="text"
								value={displayName}
								variant="outlined"
							/>
							<i className="fas fa-save" onClick={displayNameSave} />
						</React.Fragment>
					) : (
						<Typography className="link" onClick={employeeView}>{convo.employer_info.display_name}</Typography>
					)}
				</Box>
				<Box className="flexStatic">
					<i aria-controls="convo-menu" aria-haspopup="true" className="menu fas fa-ellipsis-v" onClick={ev => menuAnchorSet(ev.target)} />
					<Menu
						id="convo-menu"
						anchorEl={menuAnchor}
						keepMounted
						open={Boolean(menuAnchor)}
						onClose={ev => menuAnchorSet(null)}
					>
						<MenuItem onClick={employeeView}>
							{translate('messages', 'profile', props.locale)}
						</MenuItem>
						<MenuItem onClick={() => { displayNameSet(convo.employer_info.display_name); menuAnchorSet(null)}}>
							{translate('messages', 'rename', props.locale)}
						</MenuItem>
						<MenuItem onClick={hide}>
							{translate('messages', 'hide', props.locale)}
						</MenuItem>
						{convo.employer_info.blocked ? (
							<MenuItem onClick={unblock}>
								{translate('messages', 'unblock', props.locale)}
							</MenuItem>
						) : (
							<MenuItem onClick={ev => blockConfirmSet(true)}>
								{translate('messages', 'block', props.locale)}
							</MenuItem>
						)}
					</Menu>
				</Box>
				{blockConfirm &&
					<Dialog
						onClose={() => blockConfirmSet(false)}
						open={true}
					>
						<DialogContent>
							<Typography>{translate('messages', 'block_confirm', props.locale, {name: convo.employer_info.display_name})}</Typography>
						</DialogContent>
						<DialogActions>
							<Button className="red" variant="contained" onClick={ev => blockConfirmSet(false)}>{translate('common', 'cancel', props.locale)}</Button>
							<Button variant="contained" onClick={block}>{translate('messages', 'block', props.locale)}</Button>
						</DialogActions>
					</Dialog>
				}
			</Box>
			<Box className="conversation_messages flexGrow">
				<Messages
					owner="r"
					onPosting={postingView}
					value={convo}
					{...props}
				/>
			</Box>
			{(convo.employee_info.blocked || convo.employer_info.blocked) ? (
				<Box className="center padding">
					<Typography>{translate('messages', (convo.employer_info.blocked ? 'blocker_message' : 'blockee_message'), props.locale)}</Typography>
				</Box>
			) : (
				<Box className="conversation_send flexStatic">
					<TextField
						multiline
						onKeyDown={contentPress}
						onChange={ev => contentSet(ev.target.value)}
						rows={props.mobile ? 1 : 3}
						variant="outlined"
						value={content}
					/>
					<Button
						color="primary"
						disabled={content.trim() === ''}
						size={props.mobile ? "small" : "large"}
						onClick={send}
						variant="contained"
					>
						Send
					</Button>
				</Box>
			)}
			{employee &&
				<EmployeeView
					close={() => employeeSet(false)}
					locale={props.locale}
					info={props.info}
					mobile={props.mobile}
					value={employee}
				/>
			}
			{posting &&
				<PostingView
					close={() => postingSet(false)}
					locale={props.locale}
					info={props.info}
					mobile={props.mobile}
					value={posting}
				/>
			}
		</Box>
	);
}

// Valid props
Conversation.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}

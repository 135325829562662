/**
 * Communication
 *
 * Handles entering communication information
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2022-01-14
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

// Material UI Phone Number
import MuiPhoneInput from 'material-ui-phone-number';

// Shared generic modules
import { clone } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

/**
 * Communication
 *
 * Displays a form to set communication choice
 *
 * @name Communication
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Communication(props) {

	// Capture choice changes
	function choice(ev) {
		let oValue = clone(props.value);
		oValue.choice = ev.target.value;
		if(oValue.choice !== 'sms') {
			delete oValue.number;
		}
		props.onChange(oValue);
	}

	// Capture number changes
	function number(val) {
		let oValue = clone(props.value);
		oValue.number = val;
		props.onChange(oValue);
	}

	// Render the component
	return (
		<React.Fragment>
			<Grid item xs={12} sm={6} className="field">
				<Typography>{translate('communication', 'title', props.locale)}</Typography>
				<FormControl
					error={props.errors.choice ? true : false}
					variant={props.variant}
				>
					<Select
						name="choice"
						native
						onChange={choice}
						value={props.value.choice}
					>
						<option value="email">{translate('communication', 'email', props.locale)}</option>
						<option value="sms">{translate('communication', 'sms', props.locale)}</option>
						<option value="none">{translate('communication', 'none', props.locale)}</option>
					</Select>
					{props.errors.choice &&
						<FormHelperText>{translate('errors', props.errors.choice, props.locale)}</FormHelperText>
					}
				</FormControl>
			</Grid>
			{props.value.choice === 'sms' &&
				<Grid item xs={12} sm={6} className="field">
					<Typography>{translate('common', 'phone', props.locale)}</Typography>
					<MuiPhoneInput
						error={props.errors.number ? true : false}
						helperText={props.errors.number ? translate('errors', props.errors.number, props.locale) : ''}

						defaultCountry="ca"
						name="number"
						onChange={number}
						onlyCountries={['ca']}
						value={props.value.number || ''}
						variant={props.variant}
					/>
				</Grid>
			}
		</React.Fragment>
	);
}

// Valid props
Communication.propTypes = {
	errors: PropTypes.object,
	locale: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.object.isRequired,
	variant: PropTypes.string
}

// Default props
Communication.defaultProps = {
	errors: {},
	variant: 'standard'
}

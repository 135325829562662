/**
 * Messages
 *
 * Handles displaying messages in a single conversation
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-19
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Shared generic modules
import { nice } from 'shared/generic/dates';

// Data modules
import translate from 'data/translate';

// Constants
const PATHS = {
	e: {
		root: 'employee',
		translate: 'other_employee'
	},
	r: {
		root: 'employer',
		translate: 'other_employer'
	}
}

/**
 * System Message
 *
 * Handles displaying a single system message
 *
 * @name UserMessage
 * @access private
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
function SystemMessage(props) {

	// Switch the message type
	let sMessage = '';
	let oProps = {};
	switch(props.data.type) {
		case 'match':
			sMessage = translate('messages', 'match', props.locale, {
				"profession": props.info['professions'][props.data.profession][props.locale],
				"date": nice(props.created, props.locale, props.mobile ? 'short' : 'long')
			});
			oProps = {
				className: 'link',
				onClick: () => props.onPosting(props.data.posting)
			}
			break;

		case 'other_conversation':
			sMessage = translate('messages', PATHS[props.owner].translate, props.locale);
			oProps = {
				className: 'link',
				onClick: () => props.history.push(`/${PATHS[props.owner].root}/messaging/${props.data.conversation}`)
			}
			break;

		default:
			sMessage = JSON.stringify(props.data);
			break;
	}

	return (
		<Box className="conversation_system">
			<Typography {...oProps}>
				<span>{sMessage}</span>
				&nbsp;<i className="fas fa-info-circle" style={{fontSize: '1rem'}} />
			</Typography>
		</Box>
	)
}

// Valid props
SystemMessage.propTypes = {
	created: PropTypes.number.isRequired,
	history: PropTypes.object.isRequired,
	info: PropTypes.object.isRequired,
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	onPosting: PropTypes.func,
	owner: PropTypes.oneOf(['e', 'r']).isRequired,
	data: PropTypes.any.isRequired
}

/**
 * Messages
 *
 * Displays system and user messages
 *
 * @name Messages
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Messages(props) {

	// State
	let [messages, messagesSet] = useState(null);

	// Refs
	let bottomRef = useRef();
	let history = useHistory();

	// Value effect
	useEffect(() => {
		if(props.value) {
			let oInfo = props.owner === 'e' ? 'employer_info' : 'employee_info';
			let iUnread = props.value[oInfo].unread;
			for(let i = props.value.messages.length-1; i >= 0; --i) {
				let o = props.value.messages[i];
				if(o.creator === 's' || o.creator === props.owner) {
					if(iUnread > 0) {
						o.read = false;
						--iUnread;
					} else {
						o.read = true;
					}
				}
			}
			messagesSet(props.value.messages);
			setTimeout(() => {
				if(bottomRef.current) {
					bottomRef.current.scrollIntoView({ behavior: "smooth"});
				}
			}, 100);
		}
	}, [props.owner, props.value])

	// If we don't have the messages yet
	if(messages === null) {
		return (
			<Box className="padding">
				<Typography>{translate('common', 'loading', props.locale)}</Typography>
			</Box>
		);
	}

	// Render the component
	return (
		<React.Fragment>
			{messages.map((o,i) =>
				o.creator === 's' ? (
					<SystemMessage
						key={i}
						created={o.created}
						history={history}
						info={props.info}
						locale={props.locale}
						mobile={props.mobile}
						owner={props.owner}
						onPosting={props.onPosting}
						data={o.data}
					/>
				) : (
					<Box key={i} className={'conversation_message ' + (props.owner === o.creator ? 'outgoing' : 'incoming')}>
						<Box className="conversation_message_content">
							{o.content.split('\n').map((s,i) =>
								<Typography key={i}>{s}</Typography>
							)}
						</Box>
						<Box className="conversation_footer">
							<span>{nice(o.created, props.locale, (props.mobile ? 'short' : 'long'))}</span>
							{(o.creator === props.owner && o.read) &&
								<span> - {translate('messages', 'read', props.locale)}</span>
							}
						</Box>
					</Box>
				)
			)}
			<Box className="conversation_scroll" ref={bottomRef} />
		</React.Fragment>
	);
}

// Valid props
Messages.propTypes = {
	info: PropTypes.object.isRequired,
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	owner: PropTypes.oneOf(['e', 'r']).isRequired,
	onPosting: PropTypes.func,
	value: PropTypes.object.isRequired
}

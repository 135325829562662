/**
 * Admin Incomplete Signups
 *
 * Handles all the users who've signed up but not completed the process
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-10
 */

// NPM modules
import Tree from 'format-oc/Tree';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Shared components
import { Results } from 'shared/components/Format';

// Local components
import Employee from 'components/pages/admin/composites/Employee';
import Employer from 'components/pages/admin/composites/Employer';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';

// Create the signup status tree
const SignupStatusTree = new Tree({
	__name__: 'SignupStatus',
	_id: {__type__: 'uuid'},
	_created: {__type__: 'timestamp', __react__: {title: 'Signed Up At'}},
	_updated: {__type__: 'timestamp', __react__: {title: 'Last Updated'}},
	email: {__type__: 'string', __react__: {title: 'E-Mail Address'}},
	statuses: {__array__: 'unique', __type__: 'string', __react__: {title: 'Steps Completed'}}
});

// Constant Defines
const STATUS_CREATED_EMPLOYEE = 0x02
const STATUS_CREATED_EMPLOYER = 0x04

/**
 * User
 *
 * Handles displaying the proper tabs based on the status
 *
 * @name User
 * @access private
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
function User(props) {

	// State
	let [data, dataSet] = useState(null);

	// Load effect
	useEffect(() => {

		// If the user has an employee account
		if(props.value.status & STATUS_CREATED_EMPLOYEE) {
			employeeFetch(props.value._id);
		}

		// Else, if the user has an employer account
		else if(props.value.status & STATUS_CREATED_EMPLOYER) {
			employerFetch(props.value._id);
		}

		// Else, no account yet
		else {
			dataSet(false);
		}

	// eslint-disable-next-line
	}, []);

	// Fetch the employee data
	function employeeFetch(user_id) {
		Rest.read('main', 'employee', {
			_id: user_id
		}).done(res => {
			dataSet({
				type: 'employee',
				value: res.data
			});
		});
	}

	// Fetch the employer data
	function employerFetch(user_id) {
		Rest.read('main', 'employer/first', {
			user_id: user_id
		}).done(res => {
			dataSet({
				type: 'employer',
				value: res.data
			});
		});
	}

	// If we're loading data
	if(data === null) {
		return <Box className="padding"><Typography>Loading...</Typography></Box>
	}

	// If the user has no profile data
	if(data === false) {
		return <Box className="padding"><Typography>No profile data</Typography></Box>
	}

	// If the user type is employee
	if(data.type === 'employee') {
		return (
			<Employee
				locales={props.locales}
				info={props.info}
				value={data.value}
			/>
		);
	} else {
		return (
			<Employer
				locales={props.locales}
				info={props.info}
				value={data.value}
			/>
		);
	}
}

/**
 * IncompleteSignups
 *
 * Handles displaying users who've signed up and haven't finished the process
 *
 * @name IncompleteSignups
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function IncompleteSignups(props) {

	// State
	let [records, recordsSet] = useState([]);
	let [info, infoSet] = useState([]);

	// Load effect
	useEffect(() => {

		// Fetch the info
		infoFetch();

		// Fetch the records
		recordsFetch();

	}, []);

	// Fetch records
	function recordsFetch() {
		Rest.read('admin', 'user/incomplete', {}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {
				recordsSet(res.data);
			}
		});
	}

	// Fetch all info about and for the employer
	function infoFetch() {
		Rest.read('main', 'info', {}).done(res => {
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}
			if(res.data) {
				infoSet(res.data);
			}
		});
	}

	// Render
	return (
		<Box className="admin_incomplete_signup padding">
			<Box className="header flexColumns">
				<Typography className="title flexGrow">Incomplete Signups</Typography>
			</Box>
			{records.length === 0 ?
				<Typography>All users complete</Typography>
			:
				<Results
					actions={[
						{tooltip: "View User", icon: 'fas fa-search', component: User, props: {
							locales: props.locales,
							info: info
						}},
					]}
					custom={{
						statuses: row => row.statuses.join(', ')
					}}
					data={records}
					noun=""
					order="desc"
					orderBy="_created"
					remove={false}
					service=""
					tree={SignupStatusTree}
					update={false}
				/>
			}
		</Box>
	);
}

// Valid props
IncompleteSignups.propTypes = {
	locales: PropTypes.array.isRequired,
	mobile: PropTypes.bool.isRequired
}

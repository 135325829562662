/**
 * Admin Send Page
 *
 * Handles all the send functions of the site
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2022-01-29
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Material UI
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

// Local modules
import SMS from './SMS';

// Tabs
const _TABS = [
	'SMS'
]

/**
 * Send
 *
 * Handles switching between send functions
 *
 * @name Send
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Send(props) {

	// State
	let [tab, tabSet] = useState(0);

	// Render
	return (
		<Box className="admin_templates">
			<AppBar position="static" color="default">
				<Tabs
					indicatorColor="primary"
					onChange={(ev, tab) => tabSet(tab)}
					value={tab}
					variant="fullWidth"
				>
					{_TABS.map((label,i) =>
						<Tab key={i} label={label} />
					)}
				</Tabs>
			</AppBar>
			<Box>
				{_TABS[tab] === 'SMS' &&
					<SMS
						locales={props.locales}
						mobile={props.mobile}
					/>
				}
			</Box>
		</Box>
	);
}

// Valid props
Send.propTypes = {
	locales: PropTypes.array.isRequired,
	mobile: PropTypes.bool.isRequired
}

/**
 * Translate
 *
 * Function to fetch translations and avoid failures when they're missing
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-11-17
 */

// Translations
import Translations from './translations';

/**
 * Translate
 *
 * Looks for the string in the translation table and returns it, else a default
 * error message
 *
 * @name translate
 * @access public
 * @param String group The group to check for translations
 * @param String name The name of the translation to fetch
 * @param String locale The locale used to fetch the correct translation
 * @param Object vars Variables that can be replaced in the translation
 * @return String
 */
export default function translate(group, name, locale, vars=null) {

	// Init the return
	let sRet = null;

	// Check if the group/name exists
	try {
		sRet = Translations[group][name][locale] || null;
	} catch(err) {
		return `Translations[${group}][${name}][${locale}]`
	}

	// Check if the locale exists
	if(sRet === null) {
		return `Translations[${group}][${name}][${locale}]`
	}

	// If we have vars
	if(vars) {

		// Go through each and replace the string
		for(let k in vars) {
			sRet = sRet.replace('%' + k + '%', vars[k]);
		}
	}

	// Return the found string
	return sRet;
}
/**
 * Employee View
 *
 * Displays the view of employee information that potential employers see, as
 * well as the preview employees see of their own profile
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-11-25
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

// Shared generic modules
import { isTouch } from 'shared/generic/touch';

// Data modules
import translate from 'data/translate';

/**
 * EmployeeView
 *
 * Displays employee info
 *
 * @name EmployeeView
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function EmployeeView(props) {

	// State
	let [offset, offsetSet] = useState({});

	// Hooks
	let swipeHandlers = useSwipeable({
		onSwipedLeft: swipeLeft,
		onSwipedRight: swipeRight,
		onSwiping: swiping,
		preventDefaultTouchmoveEvent: true
	});

	// If we have no swipe
	if(!props.swipe) {
		swipeHandlers = {}
	}

	// Called on a swipe left, or a thumbs down
	function swipeLeft() {
		props.swipe('left');
	}

	// Called on a swipe right, or a thumbs up
	function swipeRight() {
		props.swipe('right');
	}

	// Called during swiping
	function swiping(ev) {
		offsetSet({
			borderLeft: '1px solid white',
			borderRight: '1px solid white',
			left: ev.deltaX - 1,
			right: (ev.deltaX - (ev.deltaX * 2)) + 1
		});
	}

	// Render
	return (
		<Box id="view" className="flexRows" style={offset}>
			{(props.close && props.close !== true) &&
				<Box className="close">
					<Button variant="contained" onClick={props.close}>{translate('common', 'preview_close', props.locale)}</Button>
				</Box>
			}
			<Hidden only={['md', 'lg', 'xl']}>
				<Box {...swipeHandlers} className="image" style={{backgroundImage: `url(${props.value.background})`}}>
					<Box className="gradient flexRows">
						{(!isTouch() && props.swipe) &&
							<Box className="clickContainer">
								<Box className="clickLeft">
									<i className="fas fa-thumbs-down"  onClick={swipeLeft} />
								</Box>
								<Box className="clickRight">
									<i className="fas fa-thumbs-up"  onClick={swipeRight} />
								</Box>
							</Box>
						}
						<Box className="flexGrow">&nbsp;</Box>
						<Box className="details flexStatic">
							<Box className="flexColumns">
								<Box className="flexGrow">
									<Typography className="name">{(props.value.fname || '') + ' ' + (props.value.lname || '')}</Typography>
								</Box>
								{props.value.covidVaccination && props.value.covidVaccination.flag &&
									<i className="fas fa-syringe name_icon" />
								}
							</Box>
							<Box className="professions">
								{props.value.professions.map(s =>
									<span key={s} className="profession">{props.info.professions[s][props.locale]}</span>
								)}
							</Box>
							{props.value.skills && props.value.skills.length > 0 &&
								<Typography className="skills">
									{props.value.skills.map(s =>
										<span key={s} className="skill">{props.info.skills[s][props.locale]}</span>
									)}
								</Typography>
							}
							{props.value.bio && props.value.bio.length > 0 && props.value.bio.split('\n').map((s,i) => <Typography key={i}>{s}</Typography>)}
							{props.value.experience.length > 0 &&
								<Box className="experience">
									<hr className="blue" />
										{props.value.experience.map((o,i) =>
											<Box key={i} className="job flexColumns">
												<Box className="flexGrow">
													<Typography className="establishment">{o.establishment}</Typography>
													<Typography className="profession">{props.info.professions[o.profession][props.locale]}</Typography>
												</Box>
												<Box className="right flexGrow">
													<Typography className="address">{o.address.city}, {o.address.division ? (o.address.division + ', ') : ''} {o.address.country}</Typography>
													<Typography className="from_till">{o.from} - {o.till || translate('common', 'present', props.locale)}</Typography>
												</Box>
											</Box>
										)}
									<hr className="blue" />
								</Box>
							}
							<Box className="languages">
								{props.value.languages.map(s =>
									<span key={s} className="blue_oval">{props.info.languages[s][props.locale]}</span>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</Hidden>
			<Hidden only={['xs', 'sm']}>
				<Box className="container md relative flexGrow" style={{height: '90%'}}>
					{(!isTouch() && props.swipe) &&
						<Box className="clickContainer">
							<Box className="clickLeft">
								<i className="fas fa-thumbs-down"  onClick={swipeLeft} />
							</Box>
							<Box className="clickRight">
								<i className="fas fa-thumbs-up"  onClick={swipeRight} />
							</Box>
						</Box>
					}
					<Box {...swipeHandlers} className="flexColumns" style={{height: '100%'}}>
						<Box className="details flexGrow">
							<Box className="flexColumns">
								<Box className="flexGrow">
									<Typography className="name">{(props.value.fname || '') + ' ' + (props.value.lname || '')}</Typography>
								</Box>
								{props.value.covidVaccination && props.value.covidVaccination.flag &&
									<i className="fas fa-syringe name_icon" />
								}
							</Box>
							<Box className="professions">
								{props.value.professions.map(s =>
									<span key={s} className="profession">{props.info.professions[s][props.locale]}</span>
								)}
							</Box>
							{props.value.skills && props.value.skills.length > 0 &&
								<Typography className="skills">
									{props.value.skills.map(s =>
										<span key={s} className="skill">{props.info.skills[s][props.locale]}</span>
									)}
								</Typography>
							}
							{props.value.bio && props.value.bio.length > 0 && props.value.bio.split('\n').map((s,i) => <Typography key={i}>{s}</Typography>)}
							{props.value.experience.length > 0 &&
								<Box className="experience">
									<br />
									<hr className="blue" />
									<br />
									{props.value.experience.map((o,i) =>
										<Box key={i} className="job flexColumns">
											<Box className="flexGrow">
												<Typography className="establishment">{o.establishment}</Typography>
												<Typography className="profession">{props.info.professions[o.profession][props.locale]}</Typography>
											</Box>
											<Box className="right flexGrow">
												<Typography className="address">{o.address.city}, {o.address.division ? (o.address.division + ', ') : ''} {o.address.country}</Typography>
												<Typography className="from_till">{o.from} - {o.till || translate('common', 'present', props.locale)}</Typography>
											</Box>
										</Box>
									)}
									<br />
									<hr className="blue" />
									<br />
								</Box>
							}
							<Box className="languages">
								{props.value.languages.map(s =>
									<span key={s} className="blue_oval">{props.info.languages[s][props.locale]}</span>
								)}
							</Box>
						</Box>
						<Box className="image flexGrow" style={{backgroundImage: `url(${props.value.background})`}}>
							&nbsp;
						</Box>
					</Box>
				</Box>
			</Hidden>
		</Box>
	);
}

// Valid props
EmployeeView.propTypes = {
	close: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	locale: PropTypes.string.isRequired,
	info: PropTypes.object.isRequired,
	mobile: PropTypes.bool.isRequired,
	swipeHandlers: PropTypes.object,
	swipeLeft: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	swipeRight: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	value: PropTypes.object.isRequired
}

// Default props
EmployeeView.defaultProps = {
	close: false,
	swipeHandlers: {},
	swipeLeft: false,
	swipeRight: false
}
/**
 * Conversation Item
 *
 * Handles displaying a single conversation item in the list
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-28
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

// Shared generic modules
import { relative } from 'shared/generic/dates';

// Constants
const PATHS = {
	e: {
		root: 'employee',
		info: 'employee_info',
		oinfo: 'employer_info'
	},
	r: {
		root: 'employer',
		info: 'employer_info',
		oinfo: 'employee_info'
	}
}

/**
 * ConversationItem
 *
 * Displays the conversation item
 *
 * @name ConversationItem
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function ConversationItem(props) {

	// Render
	return (
		<React.Fragment key={props.value._id}>
			<Link to={`/${PATHS[props.owner].root}/messaging/${props.value._id}`}>
				<ListItem button>
					<ListItemText
						className={props.value[PATHS[props.owner].info].unread ? 'unread' : 'read'}
						disableTypography={true}
						primary={
							<Box className="primary flexColumns">
								<Box className="flexGrow">
									<Typography>{props.value[PATHS[props.owner].info]['display_name']}</Typography>
								</Box>
								{props.value[PATHS[props.owner].oinfo].blocked &&
									<Box className="flexStatic">
										<i className="fas fa-user-slash red" />
									</Box>
								}
								{props.value[PATHS[props.owner].info].blocked &&
									<Box className="flexStatic">
										<i className="fas fa-user-slash" />
									</Box>
								}
								{props.value[PATHS[props.owner].info].hidden &&
									<Box className="flexStatic">
										<i className="fas fa-eye-slash" />
									</Box>
								}
								{props.value[PATHS[props.owner].info].unread > 0 &&
									<Box className="count flexStatic">
										<Typography>{props.value[PATHS[props.owner].info].unread}</Typography>
									</Box>
								}
							</Box>
						}
						secondary={
							<Typography className="secondary">
								{relative(props.value._updated, props.locale, (props.mobile ? 'short' : 'long'))}
							</Typography>
						}
					/>
				</ListItem>
			</Link>
			<Divider />
		</React.Fragment>
	);
}

// Valid props
ConversationItem.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	owner: PropTypes.oneOf(['e', 'r']).isRequired,
	value: PropTypes.object.isRequired
}

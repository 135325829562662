/**
 * Admin Users Postings component
 *
 * Handles displaying the specific job postings for the employer
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-03
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';

// Locale modules
import translate from 'data/translate';

/**
 * Postings
 *
 * Handles fetching and displaying postings specific info
 *
 * @name Postings
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Postings(props) {

	// State
	let [postings, postingsSet] = useState(false);

	// Load/ID effect
	useEffect(() => {

		// Fetch the postings info
		Rest.read('admin', 'employer/postings', {
			employer: props.employer
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data, store it
			if(res.data) {
				postingsSet(res.data);
			}
		});

	}, [props.employer]);

	// If there's no postings yet
	if(postings === false || props.info === false) {
		return <Typography>Loading...</Typography>
	}

	// Render
	return (
		<Box className="padding">
			<Typography variant="h3">Count: {postings.length}</Typography>
			{postings.map(o =>
				<Paper key={o._id} className="padding">
					<Typography><strong>{props.info['professions'][o.profession]['en-CA']}</strong></Typography>
					<Typography>{o.skills && o.skills.map((_id, i) =>
							props.info.skills[_id]['en-CA'] + (i + 1 !== o.skills.length ? ', ' : '')
					)}</Typography>
					<Typography>{o.description}</Typography>
					<Typography>{o.salary_amount} / {o.salary_type}</Typography>
					<Typography>
						{translate('common', 'times_' + o.times.type, 'en-CA')}
						&nbsp;-&nbsp;
						{o.times.type === 'gig' ?
							o.times.shifts.map(o => o.starts).join(' | ') :
							o.times.starts
						}
					</Typography>
					<Typography>{o.filled ? 'Filled' : 'Open'}</Typography>
					<Typography>Interested: {o.potential_matches}</Typography>
					<Typography>Matches: {o.matches}</Typography>
					<Typography><a className="blue" href={`https://${window.location.host}/posting/${o._id}`} target="_blank" rel="noreferrer">https://{window.location.host}/posting/{o._id}</a></Typography>
				</Paper>
			)}
		</Box>
	);
}

// Valid props
Postings.propTypes = {
	employer: PropTypes.string.isRequired,
	info: PropTypes.object.isRequired
}

/**
 * Admin Page
 *
 * Handles all the admin functions of the site
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-14
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';

// Local modules
import AwaitingApproval from './AwaitingApproval';
import ClearMatches from './ClearMatches';
import EstablishmentTypes from './EstablishmentTypes';
import FAQs from './FAQs';
import IncompleteSignups from './IncompleteSignups';
import Languages from './Languages';
import Locales from './Locales';
import Postings from './Postings';
import Professions from './Professions';
import Send from './Send';
import Skills from './Skills';
import Stats from './Stats';
import Templates from './Templates';
import Users from './Users';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import { afindi, clone } from 'shared/generic/tools';

/**
 * Admin
 *
 * Handles switching between admin functions
 *
 * @name Admin
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Admin(props) {

	// State
	let [locales, localesSet] = useState([]);

	// Load effect
	useEffect(() => {
		localesFetch();
	}, [])

	// Called when a new locale is added
	function localeAdd(locale) {
		localesSet(data => {
			data.push(locale);
			return clone(data);
		});
	}

	// Get all active and non-active locales
	function localesFetch() {
		Rest.read('admin', 'locales', {}).done(res => {
			if(res.data) {
				localesSet(res.data);
			}
		});
	}

	function localeUpdate(locale) {
		localesSet(data => {
			let i = afindi(locales, '_id', locale._id);
			if(i > -1) {
				data[i] = locale;
				return clone(data);
			}
		});
	}

	// Render
	return (
		<Box id="admin">
			<Switch>
				<Route exact path="/admin">
					<Stats
						mobile={props.mobile}
					/>
				</Route>
				<Route exact path="/admin/awaiting_approval">
					<AwaitingApproval
						locales={locales}
						mobile={props.mobile}
					/>
				</Route>
				<Route exact path="/admin/incomplete_signups">
					<IncompleteSignups
						locales={locales}
						mobile={props.mobile}
					/>
				</Route>
				<Route exact path="/admin/postings">
					<Postings
						locales={locales}
						mobile={props.mobile}
					/>
				</Route>
				<Route exact path="/admin/users">
					<Users
						locales={locales}
						mobile={props.mobile}
					/>
				</Route>
				<Route exact path="/admin/locales">
					<Locales
						locales={locales}
						mobile={props.mobile}
						onLocaleAdded={localeAdd}
						onLocaleUpdated={localeUpdate}
					/>
				</Route>
				<Route exact path="/admin/languages">
					<Languages
						locales={locales}
						mobile={props.mobile}
					/>
				</Route>
				<Route exact path="/admin/professions">
					<Professions
						locales={locales}
						mobile={props.mobile}
					/>
				</Route>
				<Route exact path="/admin/skills">
					<Skills
						locales={locales}
						mobile={props.mobile}
					/>
				</Route>
				<Route exact path="/admin/establishment_types">
					<EstablishmentTypes
						locales={locales}
						mobile={props.mobile}
					/>
				</Route>
				<Route exact path="/admin/templates">
					<Templates
						locales={locales}
						mobile={props.mobile}
					/>
				</Route>
				<Route exact path="/admin/faqs">
					<FAQs
						locales={locales}
						mobile={props.mobile}
					/>
				</Route>
				<Route exact path="/admin/send">
					<Send
						locales={locales}
						mobile={props.mobile}
					/>
				</Route>
				<Route exact path="/admin/clear">
					<ClearMatches
						mobile={props.mobile}
					/>
				</Route>
			</Switch>
		</Box>
	);
}

// Valid props
Admin.propTypes = {
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}

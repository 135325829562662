/**
 * Continue: Employee
 *
 * Handles setting up an employee account
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-18
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Composite components
import Address from 'components/composites/Address';
import EmployeeView from 'components/composites/employee/View';
import Experience from 'components/composites/Experience'
import PhotoUpload from 'components/composites/PhotoUpload';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { afindi, clone } from 'shared/generic/tools';

// Data modules
import locales from 'data/locales';
import translate from 'data/translate';

/**
 * CovidVaccination
 *
 * Handles the covid check and photo
 *
 * @name CovidVaccination
 * @access private
 * @param Object props Attributes sent to the component
 * @returns React.Component
 */
function CovidVaccination(props) {

	// Render
	return (
		<React.Fragment>
			<FormControlLabel
				control={
					<Checkbox
						checked={props.value.flag}
						onChange={ev => props.onChange({flag: ev.target.checked})}
						color="primary"
					/>
				}
				label={translate('employee_setup', 'covidQuestion', props.locale)}
			/>
		</React.Fragment>
	);
}

// Valid props
CovidVaccination.propTypes = {
	errors: PropTypes.object.isRequired,
	locale: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.object.isRequired
}

/**
 * Professions
 *
 * Handles selecting the employees 3 desired professions
 *
 * @name Professions
 * @access private
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
function Professions(props) {

	// State
	let [one, oneSet] = useState({});
	let [two, twoSet] = useState({});
	let [three, threeSet] = useState({});

	// Value effect
	useEffect(() => {

		// Get the locales
		let lLocales = locales.get();

		// Calculate the one list
		let oOne = clone(props.list);
		if(props.value.two) {
			for(let o of lLocales) {
				let i = afindi(oOne[o._id], '_id', props.value.two);
				if(i > -1) {
					oOne[o._id].splice(i, 1);
				}
			}
		}
		if(props.value.three) {
			for(let o of lLocales) {
				let i = afindi(oOne[o._id], '_id', props.value.three);
				if(i > -1) {
					oOne[o._id].splice(i, 1);
				}
			}
		}
		oneSet(oOne);

		// Calculate the two list
		if(props.value.one) {
			let oTwo = clone(props.list);
			for(let o of lLocales) {
				let i = afindi(oTwo[o._id], '_id', props.value.one);
				if(i > -1) {
					oTwo[o._id].splice(i, 1);
				}
			}
			if(props.value.three) {
				for(let o of lLocales) {
					let i = afindi(oTwo[o._id], '_id', props.value.three);
					if(i > -1) {
						oTwo[o._id].splice(i, 1);
					}
				}
			}
			twoSet(oTwo);

			// Calculate the three set
			if(props.value.two) {
				let oThree = clone(props.list);
				for(let o of lLocales) {
					let i = afindi(oThree[o._id], '_id', props.value.one);
					if(i > -1) {
						oThree[o._id].splice(i, 1);
					}
				}
				for(let o of lLocales) {
					let i = afindi(oThree[o._id], '_id', props.value.two);
					if(i > -1) {
						oThree[o._id].splice(i, 1);
					}
				}
				threeSet(oThree);
			}
		}
	}, [props.list, props.value]);

	// Render
	return (
		<React.Fragment>
			<Grid item xs={12} sm={6} className="field">
				<Typography>{translate('common', 'profession_one', props.locale)}</Typography>
				<FormControl
					error={props.errors.one ? true : false}
					variant={props.variant}
				>
					<Select
						labelId="employeeProfessionOne"
						native
						onChange={ev => {
							let oValue = clone(props.value);
							oValue.one = ev.target.value;
							return props.onChange(oValue);
						}}
						value={props.value.one || ''}
					>
						{props.value.one === null &&
							<option value=''>&nbsp;</option>
						}
						{one[props.locale] && one[props.locale].map(o =>
							<option key={o._id} value={o._id}>{o.text}</option>
						)}
					</Select>
					{props.errors.one &&
						<FormHelperText>{translate('errors', props.errors.one, props.locale)}</FormHelperText>
					}
				</FormControl>
			</Grid>
			{props.value.one ?
				<React.Fragment>
					<Grid item xs={12} sm={6} className="field">
						<Typography>{translate('common', 'profession_two', props.locale)}</Typography>
						<FormControl
							error={props.errors.two ? true : false}
							variant={props.variant}
						>
							<Select
								labelId="employeeProfessionTwo"
								native
								onChange={ev => {
									let oValue = clone(props.value);
									oValue.two = ev.target.value === '' ? null : ev.target.value;
									return props.onChange(oValue);
								}}
								value={props.value.two || ''}
							>
								<option value=''></option>
								{two[props.locale] && two[props.locale].map(o =>
									<option key={o._id} value={o._id}>{o.text}</option>
								)}
							</Select>
							{props.errors.two &&
								<FormHelperText>{translate('errors', props.errors.two, props.locale)}</FormHelperText>
							}
						</FormControl>
					</Grid>
					{props.value.two &&
						<React.Fragment>
							<Grid item xs={12} sm={6} className="field">
								<Typography>{translate('common', 'profession_three', props.locale)}</Typography>
								<FormControl
									error={props.errors.three ? true : false}
									variant={props.variant}
								>
									<Select
										labelId="employeeProfessionThree"
										native
										onChange={ev => {
											let oValue = clone(props.value);
											oValue.three = ev.target.value === '' ? null : ev.target.value;
											return props.onChange(oValue);
										}}
										value={props.value.three || ''}
									>
										<option value=''></option>
										{three[props.locale] && three[props.locale].map(o =>
											<option key={o._id} value={o._id}>{o.text}</option>
										)}
									</Select>
									{props.errors.three &&
										<FormHelperText>{translate('errors', props.errors.three, props.locale)}</FormHelperText>
									}
								</FormControl>
							</Grid>
							<Hidden mdDown>
								<Grid item lg={6}>&nbsp;</Grid>
							</Hidden>
						</React.Fragment>
					}
				</React.Fragment>
			:
				<Hidden mdDown>
					<Grid item lg={6}>&nbsp;</Grid>
				</Hidden>
			}
		</React.Fragment>
	);
}

// Valid props
Professions.propTypes = {
	errors: PropTypes.object.isRequired,
	list: PropTypes.object.isRequired,
	locale: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.object.isRequired,
	variant: PropTypes.string
}

/**
 * Employee
 *
 * Handles employee setup
 *
 * @name Employee
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Employee(props) {

	// State
	let [data, dataSet] = useState({
		address: {},
		languages: [],
		professions: {
			one: null,
			two: null,
			three: null
		},
		skills: [],
		experience: []
	});
	let [errors, errorsSet] = useState({});
	let [info, infoSet] = useState(false);
	let [photos, photosSet] = useState([]);
	let [preview, previewSet] = useState(false);
	let [setup, setupSet] = useState(false);

	// Hooks
	let history = useHistory();

	// Load effect
	useEffect(() => {

		// Fetch only the active info
		Rest.read('main', 'setup', {}).done(res => {

			// Store the info
			infoSet(res.data);

			// Init the new structure
			let oSetup = {};

			// Go through each type and sort alphabeticall
			for(let s of ['languages', 'professions', 'skills']) {
				oSetup[s] = locales.sortObject(res.data[s]);
			}

			// Set the setup
			setupSet(oSetup);
		});

	}, []);

	// Field changed
	function fieldChanged(name, value) {
		dataSet(data => {
			if(typeof value === 'string') {
				value = value.trim() === '' ? null : value;
			}
			if(value !== null) {
				data[name] = value;
			} else {
				delete data[name];
			}
			return clone(data);
		});
	}

	// Called when the photo changes
	function photosChange(photos) {

		// Clear errors
		errorsSet(val => {
			if(val.photos) {
				let oClone = clone(val);
				delete oClone.photos;
				return oClone;
			}
			return val;
		});

		// If we got photos
		if(photos) {

			// Set the photos state
			photosSet(photos);

			// Set or overwrite the photo for the update data
			dataSet(val => {
				let oClone = clone(val);
				oClone.photos = photos;
				return oClone;
			});
		}

		// Else, they were removed
		else {

			// Clear the photos set
			photosSet([]);

			// Remove the photo from the update data
			dataSet(val => {
				if(val.photos) {
					let oClone = clone(val);
					delete oClone.photos;
					return oClone;
				}
				return val;
			});
		}
	}

	// Called when there's an error uploading a file
	function photosError(error) {
		errorsSet(val => {
			let oClone = clone(val);
			oClone.photos = error;
			return oClone;
		});
	}

	// Show the preview
	function previewShow() {

		// Clone the data
		let oPreview = clone(data);

		// Add the background
		oPreview.background = (photos.length) ?
			photos[0].url :
			process.env.REACT_APP_NO_PHOTO_URL;

		// Create list of professions
		let lProfessions = [];
		for(let s of ['one', 'two', 'three']) {
			if(data.professions[s] !== null) {
				lProfessions.push(data.professions[s]);
			}
		}
		oPreview.professions = lProfessions;

		// Set the preview
		previewSet(oPreview);
	}

	// Called to set employee record
	function submit() {

		// Reset errors
		errorsSet({});

		// Send the data to the server
		Rest.create('main', 'employee', data).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 1001 || res.error.code === 1002) {
					errorsSet(Rest.toTree(res.error.msg));
				} else if(res.error.code === 1002) {
					errorsSet({photos: [res.error.msg[1], res.error.msg[2] || '']});
				} else {
					Events.trigger('error', res.error);
				}
			}

			// If we're successful
			if(res.data) {

				// Update the user
				let oUser = clone(props.user);
				oUser['employee'] = res.data;
				Events.trigger('signedIn', oUser);

				// Redirect to how to page
				history.push('/how-to');
			}
		});
	}

	// Render
	return (
		<Box className="singlePage padding flexRows">
			{setup === false ?
				<Typography className="flexGrow">{translate('common', 'loading', props.locale)}...</Typography>
			:
				<Grid container spacing={2} className="flexGrow">
					<Grid item xs={12} sm={6} className="field">
						<Typography>{translate('common', 'first_name', props.locale)}</Typography>
						<TextField
							error={errors.fname ? true : false}
							helperText={errors.fname ? translate('errors', errors.fname, props.locale) : ''}
							onChange={ev => fieldChanged('fname', ev.target.value)}
							type="text"
							value={data.fname || ''}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6} className="field">
						<Typography>{translate('common', 'last_name', props.locale)}</Typography>
						<TextField
							error={errors.lname ? true : false}
							helperText={errors.lname ? translate('errors', errors.lname, props.locale) : ''}
							onChange={ev => fieldChanged('lname', ev.target.value)}
							type="text"
							value={data.lname || ''}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} className="field">
						<Address
							errors={errors.address || {}}
							fields={['postal_code']}
							locale={props.locale}
							onChange={address => fieldChanged('address', address)}
							value={data.address}
							variant="outlined"
						/>
					</Grid>
					<Professions
						errors={errors.professions || {}}
						list={setup.professions}
						locale={props.locale}
						onChange={value => fieldChanged('professions', value)}
						value={data.professions}
						variant="outlined"
					/>
					<Grid item xs={12} lg={6} className="field">
						<Typography>{translate('common', 'skills', props.locale)}</Typography>
						<FormControl
							error={errors.skills ? true : false}
							variant="outlined"
						>
							<Select
								labelId="employeeSkillsControl"
								multiple
								value={data.skills}
								onChange={ev => fieldChanged('skills', ev.target.value)}
								input={<Input id="employeeSkillsInput" />}
								renderValue={selected => (
									<div>
										{selected.map(_id =>
											<Chip key={_id} label={info.skills[_id][props.locale]} />
										)}
									</div>
								)}
							>
								{setup.skills[props.locale].map(o =>
									<MenuItem key={o._id} value={o._id}>{o.text}</MenuItem>
								)}
							</Select>
							{errors.skills &&
								<FormHelperText>{translate('errors', errors.skills, props.locale)}</FormHelperText>
							}
						</FormControl>
					</Grid>
					<Grid item xs={12} lg={6} className="field">
						<Typography>{translate('employee_setup', 'languages', props.locale)}</Typography>
						<FormControl
							error={errors.languages ? true : false}
							variant="outlined"
						>
							<Select
								labelId="employeeLanguagesControl"
								multiple
								value={data.languages}
								onChange={ev => fieldChanged('languages', ev.target.value)}
								input={<Input id="employeeLanguagesInput" />}
								renderValue={selected => (
									<div>
										{selected.map(_id =>
											<Chip key={_id} label={info.languages[_id][props.locale]} />
										)}
									</div>
								)}
							>
								{setup.languages[props.locale].map(o =>
									<MenuItem key={o._id} value={o._id}>{o.text}</MenuItem>
								)}
							</Select>
							{errors.languages &&
								<FormHelperText>{translate('errors', errors.languages, props.locale)}</FormHelperText>
							}
						</FormControl>
					</Grid>
					<Grid item xs={12} lg={6} className="field">
						<Typography>{translate('common', 'bio', props.locale)}</Typography>
						<TextField
							error={errors.bio ? true : false}
							helperText={errors.bio ? translate('errors', 'bio_' + errors.bio, props.locale) : ''}
							multiline
							onChange={ev => fieldChanged('bio', ev.target.value)}
							type="text"
							value={data.bio || ''}
							variant="outlined"
							inputProps={{
								maxLength: 300
							}}
						/>
					</Grid>
					<Hidden mdDown>
						<Grid item lg={6}>&nbsp;</Grid>
					</Hidden>
					<Grid item xs={12}>
						<hr className="blue" />
					</Grid>
					<Grid item xs={12}>
						<Typography>{translate('common', 'experience', props.locale)}</Typography>
						<Experience
							errors={errors.experience}
							locale={props.locale}
							onChange={value => fieldChanged('experience', value)}
							professions={setup.professions}
							value={data.experience}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12}>
						<hr className="blue" />
					</Grid>
					<Grid item xs={12} lg={6}>
						<Typography>{translate('common', 'photo', props.locale)}</Typography>
						<PhotoUpload
							locale={props.locale}
							mobile={props.mobile}
							onChange={photosChange}
							onError={photosError}
							value={photos}
						/>
						{errors.photos &&
							<Typography className="error">{translate('errors', errors.photos[0], props.locale, {misc: errors.photos[1]})}</Typography>
						}
					</Grid>
					<Hidden mdDown>
						<Grid item lg={6}>&nbsp;</Grid>
					</Hidden>
					<Grid item xs={12} lg={6}>
						<CovidVaccination
							errors={errors.covidVaccination || {}}
							locale={props.locale}
							onChange={val => fieldChanged('covidVaccination', val)}
							value={data.covidVaccination || {flag: false}}
						/>
					</Grid>
					<Hidden mdDown>
						<Grid item lg={6}>&nbsp;</Grid>
					</Hidden>
					<Grid item xs={12}>
						<Box className="flexColumns">
							<Box className="flexStatic">
								<Button variant="contained" onClick={previewShow}>{translate('common', 'preview', props.locale)}</Button>
							</Box>
							<Box className="actions flexGrow">
								<Button variant="contained" onClick={submit}>{translate('common', 'submit', props.locale)}</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			}
			{preview &&
				<EmployeeView
					close={() => previewSet(false)}
					locale={props.locale}
					info={info}
					mobile={props.mobile}
					value={preview}
				/>
			}
		</Box>
	);
}

// Valid props
Employee.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}

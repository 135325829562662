/**
 * Popup
 *
 * Displays a popup message
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-04-03
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

/**
 * Popup
 *
 * Handles a popup message
 *
 * @name Popup
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Popup(props, action=null) {

	// Render
	return (
		<Snackbar
			action={
				<React.Fragment>
					{props.action}
					<IconButton
						color="inherit"
						onClick={props.onClose}
					>
						<i className="fas fa-times-circle"/>
					</IconButton>
				</React.Fragment>
			}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			autoHideDuration={6000}
			message={props.content.split('\n').map((s,i) => <Typography key={i}>{s}</Typography>)}
			onClose={props.onClose}
			open={true}
			TransitionComponent={(props) => <Slide {...props} direction="up" />}
			transitionDuration={1000}
			TransitionProps={{
				onExited: props.onClose
			}}
		/>
	);
}

Popup.propTypes = {
	action: PropTypes.any,
	content: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired
}

Popup.defaultProps = {
	action: ''
}

/**
 * Account
 *
 * Handles changing email or password
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-06
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Composite components
import Communication from 'components/composites/Communication';
import Popup from 'components/composites/Popup';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { clone, compare, isObject } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

/**
 * Account
 *
 * Handles account page
 *
 * @name Account
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Account(props) {

	// State
	let [comm, commSet] = useState(props.user.communication);
	let [errors, errorsSet] = useState(false);
	let [message, messageSet] = useState(false);

	// Refs
	let refConfirm = useRef();
	let refEmail = useRef();
	let refPasswd = useRef();

	// Change their communication preference
	function commChange() {

		// Reset errors
		errorsSet(false);

		// If there's no difference
		if(compare(comm, props.user.communication)) {
			return;
		}

		// Send the request
		Rest.update('main', 'communication', {
			communication: comm
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 1001) {
					errorsSet({communication: {number: 'invalid'}});
				} else {
					Events.trigger('error', res.error);
				}
			} else if(res.data) {

				// Notify the user
				messageSet(translate('account', 'updated', props.locale));

				// Update the communication state of the user
				let oUser = clone(props.user);
				oUser.communication = comm;

				// If we got back stop numbers
				if(isObject(res.data) && res.data.stop) {
					oUser.stop = res.data.stop;
				} else if('stop' in oUser) {
					delete oUser.stop;
				}

				// Call the sign in event
				Events.trigger('signedIn', oUser);
			}
		})
	}

	// Change their email
	function emailChange() {

		// Get the email
		let email = refEmail.current.value.trim()

		// Reset errors
		errorsSet(false);

		// If it hasn't changed, do nothing
		if(email === props.user.email) {
			return;
		}

		// Send the data to the server
		Rest.update('main', 'email', {
			email: email,
			url: 'https://' + window.location.host + '/verify/{locale}/{key}'
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 1001) {
					errorsSet(Rest.toTree(res.error.msg));
				} else if(res.error.code === 2004) {
					errorsSet({email: 'email_used'});
				} else {
					Events.trigger('error', res.error);
				}
			} else if(res.data) {

				// Notify the user
				messageSet(translate('account', 'updated', props.locale));

				// Clear the verified state
				let oUser = clone(props.user);
				oUser.verified = false;
				Events.trigger('signedIn', oUser);
			}
		});
	}

	// Change their password
	function passwdChange() {

		// Get the values
		let passwd = refPasswd.current.value.trim();
		let confirm = refConfirm.current.value.trim();

		// If the passwords do not match
		if(passwd !== confirm) {
			errorsSet({confirm_password: 'mismatch'});
			return;
		}

		// Reset errors
		errorsSet(false);

		// Send the data to the server
		Rest.update('main', 'passwd', {
			passwd: passwd
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 2102) {
					errorsSet({passwd: 'password_strength'});
				} else {
					Events.trigger('error', res.error);
				}
			} else if(res.data) {
				// Notify the user
				messageSet(translate('account', 'updated', props.locale));
			}
		});
	}

	// Render
	return (
		<Box className="padding singlePage">
			<Container maxWidth="md" className="padding">
				<Typography variant="h2">{translate('common', 'account', props.locale)}</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12} className="field">
						<Typography>E-mail</Typography>
						<TextField
							error={errors.email ? true : false}
							helperText={errors.email ? translate('errors', errors.email, props.locale) : ''}
							defaultValue={props.user.email}
							inputRef={refEmail}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} className="actions">
						<Button variant="contained" onClick={emailChange}>{translate('common', 'update', props.locale)}</Button>
					</Grid>
					<Grid item xs={12} sm={6} className="field">
						<Typography>{translate('common', 'password', props.locale)}</Typography>
						<TextField
							error={errors.passwd ? true : false}
							helperText={errors.passwd ? translate('errors', errors.passwd, props.locale) : ''}
							inputRef={refPasswd}
							type="password"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6} className="field">
						<Typography>{translate('common', 'confirm_password', props.locale)}</Typography>
						<TextField
							error={errors.confirm_password ? true : false}
							helperText={errors.confirm_password ? translate('errors', errors.confirm_password, props.locale) : ''}
							inputRef={refConfirm}
							type="password"
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} className="actions">
						<Button variant="contained" onClick={passwdChange}>{translate('common', 'update', props.locale)}</Button>
					</Grid>
					<Communication
						errors={errors.communication || {}}
						locale={props.locale}
						onChange={commSet}
						value={comm}
						variant="outlined"
					/>
					<Grid item xs={12} className="actions">
						<Button variant="contained" onClick={commChange}>{translate('common', 'update', props.locale)}</Button>
					</Grid>
				</Grid>
			</Container>
			{message &&
				<Popup
					content={message}
					onClose={() => messageSet(false)}
				/>
			}
		</Box>
	);
}

// Valid props
Account.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}

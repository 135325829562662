/**
 * Admin Users User component
 *
 * Handles displaying the specific user info for the employee/employer
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-11-23
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import { iso } from 'shared/generic/dates';
import Events from 'shared/generic/events';
import { afindi, clone, nicePhone } from 'shared/generic/tools';

/**
 * User
 *
 * Handles fetching and displaying user specific info
 *
 * @name User
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function User(props) {

	// State
	let [user, userSet] = useState(false);

	// Hooks
	let history = useHistory();

	// Load/ID effect
	useEffect(() => {

		// Fetch the user info
		Rest.read('admin', 'user', {
			_id: props._id
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data, store it
			if(res.data) {

				// Find the locale
				let i = afindi(props.locales, '_id', res.data.locale)
				res.data.localeName = (i > -1) ? props.locales[i].name : 'Unknown';
				userSet(res.data);
			}
		});

	}, [props._id, props.locales]);

	// Delete the user completely
	/*function remove() {

		Rest.delete('admin', 'user/delete', {
			_id: props._id
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If we were successful
			if(res.data) {
				Events.trigger('admin_user_search_remove', props._id);
			}
		});
	}*/

	// Called to update the enterprise flag
	function enterpriseUpdate(ev) {

		// Get the value
		let bFlag = ev.target.checked;

		// Send the request
		Rest.update('admin', 'user/enterprise', {
			_id: props._id,
			value: bFlag
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If we succeeded
			if(res.data) {

				// Update the user
				userSet(val => {
					val.enterprise = bFlag;
					return clone(val);
				});
			}
		})
	}

	// Called to login as the user
	function loginAs() {

		// Make the request to the server
		Rest.create('admin', 'login/as', {
			_id: props._id
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If we're successful
			if(res.data) {

				// Set the session with the service
				Rest.session(res.data.session);

				// Call the sign in event
				Events.trigger('signedIn', res.data.user);

				// Redirect to home
				history.push('/');
			}
		});
	}

	// If there's no user yet
	if(user === false) {
		return <Typography>Loading...</Typography>
	}

	// Render
	return (
		<Box className="padding flexColumns">
			<Box className="flexGrow">
				<Typography><strong>ID: </strong>{user._id} <i className="fa-solid fa-arrow-up-right-from-square" onClick={loginAs} /></Typography>
				<Typography><strong>Email: </strong>{user.email}</Typography>
				<Typography><strong>Email Verified: </strong>{user.verified === true ? 'Yes' : ('No "' + user.verified + '"')}</Typography>
				<Typography><strong>Locale: </strong>{user.localeName}</Typography>
				<Typography><strong>Comm Pref: </strong>{user.communication.choice} {user.communication.choice === 'sms' && nicePhone(user.communication.number)}</Typography>
				<Typography><strong>Created: </strong>{iso(user._created)}</Typography>
			</Box>
			<Box className="flexGrow">
				<Typography>
					<strong>Enterprise: </strong>
					<Switch
						checked={user.enterprise}
						onChange={enterpriseUpdate}
						size="small"
					/>
				</Typography>
			</Box>
		</Box>
	);
}

// Valid props
User.propTypes = {
	_id: PropTypes.string.isRequired
}

/**
 * Languages
 *
 * Handles get/set of languages
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-14
 */

// NPM modules
//import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// Composite components
import CreateByLocale from 'components/pages/admin/composites/CreateByLocale';
import ResultByLocale from 'components/pages/admin/composites/ResultByLocale';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { afindi, clone } from 'shared/generic/tools';

/**
 * Languages
 *
 * Displays languages and allows for editing them
 *
 * @name Languages
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Languages(props) {

	// State
	let [create, createSet] = useState(false);
	let [results, resultsSet] = useState([]);
	let [locs, locsSet] = useState([]);

	// Load Effect
	useEffect(() => {
		fetch();
	}, []);

	// Locales effect
	useEffect(() => {
		let lLocales = [];
		for(let o of props.locales) {
			if(o.active) {
				lLocales.push(o);
			}
		}
		locsSet(lLocales);
	}, [props.locales])

	// Called on a new language being created
	function created(language) {
		let lResults = clone(results);
		lResults.unshift(language);
		resultsSet(lResults);
		createSet(false);
	}

	// Fetch all languages
	function fetch() {
		Rest.read('admin', 'languages', {}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {
				resultsSet(res.data);
			}
		});
	}

	// Called on an existing language being updated
	function updated(language) {
		resultsSet(results => {
			let iIndex = afindi(results, '_id', language._id);
			if(iIndex > -1) {
				results[iIndex] = language;
				return clone(results);
			} else {
				return results;
			}
		});
	}

	// Results render
	let Results = null;

	// If we're still loading the results
	if(results === null) {
		Results = <Typography>Loading...</Typography>
	} else if(results.length === 0) {
		Results = <Typography>Nothing returned</Typography>
	} else {
		Results = results.map(o =>
			<ResultByLocale
				key={o._id}
				locales={locs}
				noun="language"
				onUpdated={updated}
				result={o}
			/>
		);
	}

	// Render
	return (
		<Box className="languages padding">
			<Box className="header flexColumns">
				<Typography className="title flexGrow">Languages</Typography>
				<Box className="flexStatic">
					<Tooltip title="Create New Language">
						<IconButton onClick={ev => createSet(b => !b)}>
							<i className="fas fa-plus-circle"/>
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
			{create &&
				<CreateByLocale
					locales={locs}
					noun="language"
					onCancel={ev => createSet(false)}
					onSuccess={created}
				/>
			}
			{Results}
		</Box>
	);
}

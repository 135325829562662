/**
 * Share
 *
 * Displays social media share buttons
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-20
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
	EmailShareButton,
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton
} from 'react-share';

// Composite components
import Popup from 'components/composites/Popup';

// Generic modules
import Clipboard from 'shared/generic/clipboard';

// Data modules
import translate from 'data/translate';

/**
 * Share
 *
 * Displays buttons for social media sharing
 *
 * @name Share
 * @access public
 * @param Object props Attributes sent to the component
 * @returns React.Component
 */
export default function Share(props) {

	// State
	let [copied, copiedSet] = useState(false);

	// Called to copy the url to the clipboard
	function copy() {
		Clipboard.copy(props.url);
		copiedSet(true);
	}

	// Render
	return (
		<React.Fragment>
			<div className="social flexColumns">
				<div className="flexGrow">
					<FacebookShareButton
						url={props.url}
						quote={props.text}
					>
						<i className="fab fa-facebook-f" />
					</FacebookShareButton>
				</div>
				<div className="flexGrow">
					<TwitterShareButton
						url={props.url}
						title={props.text}
					>
						<i className="fab fa-twitter" />
					</TwitterShareButton>
				</div>
				<div className="flexGrow">
					<WhatsappShareButton
						url={props.url}
						title={props.text}
						separator=":: "
					>
						<i className="fab fa-whatsapp" />
					</WhatsappShareButton>
				</div>
				<div className="flexGrow">
					<LinkedinShareButton
						url={props.url}
					>
						<i className="fab fa-linkedin-in" />
					</LinkedinShareButton>
				</div>
				<div className="flexGrow">
					<EmailShareButton
						url={props.url}
						subject={props.text}
					>
						<i className="fas fa-envelope" />
					</EmailShareButton>
				</div>
			</div>
			<div className="url flexColumns">
				<div className="flexGrow">
					<input readOnly={true} type="text" value={props.url} />
				</div>
				<div className="flexStatic">
					<button aria-label="copy" className="react-share__ShareButton" style={{backgroundColor: 'transparent', border: 'none', padding: '0px', font: 'inherit', color: 'inherit', cursor: 'pointer'}} onClick={copy}>
						<i className="fas fa-copy" />
					</button>
				</div>
			</div>
			{copied &&
				<Popup
					content={translate('common', 'copied', props.locale)}
					onClose={() => copiedSet(false)}
				/>
			}
		</React.Fragment>
	);
}

// Valid props
Share.propTypes = {
	locale: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired
}
/**
 * Confirm Button
 *
 * Component for button with a two click process to confirm the first click
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-05-17
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

// Package modules
import { clone } from '../generic/tools';

/**
 * Confirm Button
 *
 * Default export of the module, the actual button component
 *
 * @name ConfirmButton
 * @access public
 * @param Object props Properties passed to the component
 * @return React.Component
 */
export default function ConfirmButton(props) {

	// State
	let [confirm, confirmSet] = useState(false);

	// Ref
	let timerRef = useRef();

	// Called to go through with the click
	function confirmClick(ev) {

		// Cancel the timer
		if(timerRef.current !== 0) {
			clearTimeout(timerRef.current);
		}

		// Clear the confirm
		confirmSet(false);

		// Let the parent know
		props.onClick(ev);
	}

	// Called to show confirm
	function firstClick() {

		// Start the timer
		timerRef = setTimeout(() => {
			confirmSet(val => false);
		}, props.timeout * 1000);

		// Set confirm
		confirmSet(true);
	}

	// Init props
	let buttonProps = clone(props.buttonProps);

	// If we are confirm
	if(confirm) {
		buttonProps.onClick = confirmClick;
		if(props.confirmColor) {
			buttonProps.color = props.confirmColor;
		}
	} else {
		buttonProps.onClick = firstClick;
		if(props.color) {
			buttonProps.color = props.color;
		}
	}

	// Render
	return confirm ?
		<props.component key="confirm" {...buttonProps}>{props.confirmText}</props.component> :
		<props.component key="initial" {...buttonProps}>{props.children}</props.component>
	;
}

// Valid props
ConfirmButton.propTypes = {
	buttonProps: PropTypes.object,
	confirmText: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	timeout: PropTypes.number
}

// Default props
ConfirmButton.defaultProps = {
	buttonProps: {},
	confirmText: 'Are you sure?',
	timeout: 5
}

/**
 * Admin Templates Page
 *
 * Handles all the template functions
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2022-01-21
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import { clone, compare, omap, ucfirst } from 'shared/generic/tools';

/**
 * Email
 *
 * Handles showing and updating a single locale in a template
 *
 * @name Email
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
function Email(props) {

	// Called when any value is changed
	function change(name, value) {
		let oValue = clone(props.value);
		oValue[name] = value;
		props.onChanged(oValue);
	}

	// Render
	return (
		<React.Fragment>
			{props.value.subject !== undefined &&
				<Box className="field">
					<Typography>Subject</Typography>
					<TextField
						onChange={ev => change('subject', ev.target.value)}
						type="text"
						value={props.value.subject || ''}
						variant="outlined"
					/>
				</Box>
			}
			<Box className="field">
				<Typography>Text</Typography>
				<TextField
					multiline
					onChange={ev => change('text', ev.target.value)}
					type="text"
					value={props.value.text || ''}
					variant="outlined"
				/>
			</Box>
			<Box className="field">
				<Typography>HTML</Typography>
				<TextField
					multiline
					onChange={ev => change('html', ev.target.value)}
					type="text"
					value={props.value.html || ''}
					variant="outlined"
				/>
			</Box>
		</React.Fragment>
	);
}

Email.propTypes = {
	onChanged: PropTypes.func.isRequired,
	value: PropTypes.object.isRequired
}

/**
 * Template
 *
 * Handles showing and updating a single template
 *
 * @name Template
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
function Template(props) {

	// State
	let [changed, changedSet] = useState(false);
	let [errors, errorsSet] = useState(false);
	let [value, valueSet] = useState(null);

	// Props Value effect
	useEffect(() => {
		valueSet(clone(props.value));
	}, [props.value]);

	// Value effect
	useEffect(() => {
		changedSet(!compare(props.value, value));
	// eslint-disable-next-line
	}, [value]);

	// Called when a locale is changed
	function localeChanged(id, val) {
		valueSet(value => {
			value.locales[id] = val;
			return clone(value);
		});
	}

	// Called to save the template details
	function save() {

		// Return immediately if nothing has been changed from the original
		if(!changed) {
			return;
		}

		// Reset errors
		errorsSet(false);

		// Send the data to the server
		Rest.update('admin', 'template', value).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 1001) {
					errorsSet(res.error.msg.map(l => l[0] + ' is ' + l[1]))
				} else {
					errorsSet([JSON.stringify(res.error)]);
				}
			}

			// If we're successful
			if(res.data) {

				// Let the parent know
				props.onUpdated(value);
			}
		});
	}

	// Called when the sms locale is changed
	function smsChanged(id, val) {
		valueSet(value => {
			value.sms.locales[id] = val;
			return clone(value);
		});
	}

	// Loading
	if(!value) {
		return <Box className="padding">Loading...</Box>
	}

	// Render
	return (
		<React.Fragment>
			<Box className="header flexStatic flexColumns padding">
				<Typography className="title flexGrow">{ucfirst(value.name.replace(/_/g, ' '))}</Typography>
				<Tooltip title="Save Template">
					<IconButton onClick={save}>
						<i className="fas fa-save" style={{color: changed ? 'white' : 'darkgrey'}} />
					</IconButton>
				</Tooltip>
			</Box>
			<Box className="flexGrow padding">
				{errors &&
					<Box className="errors">{errors.map(s => <Typography>{s}</Typography>)}</Box>
				}
				<Paper className="padding">
					<Typography variant="h3">{value.name}</Typography>
				</Paper>
				<Paper className="padding">
					<Typography variant="h3">Email</Typography>
					{props.locales.map(o =>
						<Accordion key={'email_' + o._id}>
							<AccordionSummary
								expandIcon={<i className="fas fa-chevron-down" />}
							>
								<Typography>{o.name}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Email
									onChanged={val => localeChanged(o._id, val)}
									value={value.locales[o._id] || {}}
								/>
							</AccordionDetails>
						</Accordion>
					)}
				</Paper>
				{value.sms.allow &&
					<Paper className="padding">
						<Typography variant="h3">SMS</Typography>
						{props.locales.map(o =>
							<Accordion key={'sms_' + o._id}>
								<AccordionSummary
									expandIcon={<i className="fas fa-chevron-down" />}
								>
									<Typography>{o.name}</Typography>
								</AccordionSummary>
								<AccordionDetails className="field">
									<TextField
										multiline
										onChange={ev => smsChanged(o._id, ev.target.value)}
										type="text"
										value={value.sms.locales[o._id] || ''}
										variant="outlined"
									/>
								</AccordionDetails>
							</Accordion>
						)}
					</Paper>
				}
			</Box>
		</React.Fragment>
	);
}

// Valid props
Template.propTypes = {
	locales: PropTypes.array.isRequired,
	mobile: PropTypes.bool.isRequired,
	onUpdated: PropTypes.func.isRequired,
	value: PropTypes.object.isRequired
}

/**
 * Templates
 *
 * Displays the list of available templates
 *
 * @name Templates
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Templates(props) {

	// State
	let [tpl, tplSet] = useState(null);
	let [tpls, tplsSet] = useState(null);

	// Load effect
	useEffect(() => {
		Rest.read('admin', 'template', {}).done(res => {
			tplsSet(res.data.reduce((res, o) => Object.assign(res, {[o._id]: o}), {}));
		});
	}, []);

	// Called when a template is updated
	function templateUpdated(value) {
		tplsSet(tpls => {
			tpls[value._id] = value;
			return clone(tpls);
		});
	}

	// Render
	return (
		<Box className="admin_templates flexColumns">
			{tpls === null ?
				<Typography>Loading...</Typography>
			:
				<React.Fragment>
					<Box className="menu flexStatic">
						<List component="nav">
							{omap(tpls, (o =>
								<ListItem key={o._id} className={o._id === tpl ? 'selected' : ''} onClick={() => tplSet(o._id)}>
									<ListItemText>{ucfirst(o.name.replace(/_/g, ' '))}</ListItemText>
								</ListItem>
							))}
						</List>
					</Box>
					<Box className="flexGrow flexRows">
						{tpl &&
							<Template
								key={tpl}
								locales={props.locales}
								mobile={props.mobile}
								onUpdated={templateUpdated}
								value={tpls[tpl]}
							/>
						}
					</Box>
				</React.Fragment>
			}
		</Box>
	);
}

// Valid props
Templates.propTypes = {
	locales: PropTypes.array.isRequired,
	mobile: PropTypes.bool.isRequired
}

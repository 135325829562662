/**
 * Admin Awaiting Approval
 *
 * Handles all the employers not yet approved
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-11-22
 */

// NPM modules
import Tree from 'format-oc/Tree';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Shared components
import { Results } from 'shared/components/Format';

// Local components
import Employer from 'components/pages/admin/composites/Employer';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic
import Events from 'shared/generic/events';
import { clone, nicePhone } from 'shared/generic/tools';

// Load the employee definition
import EmployerDef from 'definitions/employer';
let EmployerCustom = clone(EmployerDef);
EmployerCustom.__react__ = {
	results: ['_id', '_created', 'name', 'phone']
}
EmployerCustom._id.__react__ = {title: 'ID'}
EmployerCustom._created.__react__ = {title: 'Created'}
EmployerCustom.name.__react__ = {title: 'Establishment Name'}
EmployerCustom.phone.__react__ = {title: 'Phone Number'}

// Create the employer tree
const EmployerTree = new Tree(EmployerCustom);

/**
 * AwaitingApproval
 *
 * Handles searching and managing employer users
 *
 * @name AwaitingApproval
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function AwaitingApproval(props) {

	// State
	let [records, recordsSet] = useState([]);
	let [info, infoSet] = useState([]);

	// Load effect
	useEffect(() => {

		// Fetch the establishment info
		infoFetch();

		// Fetch the records
		recordsFetch();

		// Watch for reload
		Events.subscribe('admin_user_search_remove', recordsFetch);

		// Return callback for unload
		return () => {
			Events.unsubscribe('admin_user_search_remove', recordsFetch);
		}

	}, []);

	// Fetch records
	function recordsFetch() {
		Rest.read('admin', 'employer/unapproved', {}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {
				recordsSet(res.data);
			}
		});
	}


	// Fetch all info about and for the employer
	function infoFetch() {
		Rest.read('main', 'info', {}).done(res => {
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}
			if(res.data) {
				infoSet(res.data);
			}
		});
	}

	// Render
	return (
		<Box className="admin_awaiting_approval padding">
			<Box className="header flexColumns">
				<Typography className="title flexGrow">Awaiting Approval</Typography>
			</Box>
			{records.length === 0 ?
				<Typography>No one left to approve</Typography>
			:
				<Results
					actions={[
						{tooltip: "View Employer", icon: 'fas fa-search', component: Employer, props: {
							approve: true,
							locales: props.locales,
							info: info
						}},
					]}
					custom={{
						phone: row => nicePhone(row.phone)
					}}
					data={records}
					noun=""
					order="asc"
					orderBy="_created"
					remove={false}
					service=""
					tree={EmployerTree}
					update={false}
				/>
			}
		</Box>
	);
}

// Valid props
AwaitingApproval.propTypes = {
	locales: PropTypes.array.isRequired,
	mobile: PropTypes.bool.isRequired
}

/**
 * Forgot Pass
 *
 * Handles submitting a request to change the password
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-05-21
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared Hooks
import { useOutsideClick } from 'shared/hooks/outsideClick';

// Data modules
import translate from 'data/translate';

/**
 * Forgot Pass
 *
 * Handles the form to sign into the site
 *
 * @name login
 * @access private
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function ForgotPass(props) {

	// State
	let [email, emailSet] = useState('');
	let [error, errorSet] = useState(false);
	let [success, successSet] = useState(false);

	// Refs
	let refSelf = useRef();

	// Hooks
	useOutsideClick(refSelf, () => props.onClose());

	// Traps "Enter"
	function keyPress(ev) {
		if(ev.key === 'Enter') {
			forgotPass();
		}
	}

	// Sign In
	function forgotPass() {

		// Reset error message
		errorSet(false);

		// Make the request to the server
		Rest.create('main', 'passwd_forgot', {
			email: email.trim(),
			url: 'https://' + window.location.host + '/forgot/{locale}/{email}/{key}'
		}, {session: false}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 2100) {
					errorSet(translate('signin', 'invalid', props.locale));
				} else {
					errorSet(translate('common', 'unknown', props.locale));
				}
			}

			// If we're successful
			if(res.data) {

				// Show success
				successSet(true);

				// Hide the form after 10 seconds
				setTimeout(() => {
					props.onClose()
				}, 10000);
			}
		});
	}

	// Render
	return (
		<Box id="forgotPassword" ref={refSelf}>
			{success ?
				<Typography>
					{translate('password_forgot', 'request_success', props.locale)}
				</Typography>
			:
				<React.Fragment>
					{error &&
						<Typography className="error">{error}</Typography>
					}
					<Box className="field">
						<Typography>{translate('common', 'email', props.locale)}</Typography>
						<TextField
							onChange={ev => emailSet(ev.target.value)}
							onKeyPress={keyPress}
							type="text"
							value={email}
							variant="outlined"
						/>
					</Box>
					<Box className="actions field">
						<Button
							className="white"
							disabled={email.trim() === ''}
							onClick={forgotPass}
							variant="contained"
						>
							{translate('common', 'request', props.locale)}
						</Button>
					</Box>
				</React.Fragment>
			}
		</Box>
	)
}

// Valid props
ForgotPass.propTypes = {
	locale: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired
}

/**
 * Match
 *
 * Displays the possible matches for the posting based on the distance
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-28
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

// Composite components
import EmployeeView from 'components/composites/employee/View';
import Popup from 'components/composites/Popup';
import SliderLabel from 'components/composites/SliderLabel';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { clone, safeLocalStorage } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

/**
 * PostingMatch
 *
 * Handles main employee page
 *
 * @name PostingMatch
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function PostingMatch(props) {

	// State
	let [distance, distanceSet] = useState(parseInt(safeLocalStorage('distance', '10')));
	let [last, lastSet] = useState(null);
	let [matches, matchesSet] = useState([]);
	let [message, messageSet] = useState(false);
	let [options, optionsSet] = useState(false);

	// Hooks
	let history = useHistory();
	let { _id } = useParams();

	// Load effect
	useEffect(() => {
		Events.subscribe('employer_current', employerChanged);
		return () => {
			Events.unsubscribe('employer_current', employerChanged);
		}
	});

	// Distance effect
	useEffect(() => {
		matchesFetch('match');
	// eslint-disable-next-line
	}, [distance]);

	// Employer changed
	function employerChanged() {
		history.push('/employer');
	}

	// Fetch matches
	function matchesFetch(type) {

		// Store the new distance
		localStorage.setItem('distance', distance.toString());

		// Get the matches from the server
		Rest.read('main', 'posting/search/' + type, {
			_id: _id,
			distance: distance
		}).done(res => {
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			} else if(res.data) {

				// Go through each match
				for(let o of res.data) {

					// Add the background image
					o.background = (o.photos && o.photos.length) ?
						o.photos[0].url :
						process.env.REACT_APP_NO_PHOTO_URL;

					// Create list of professions
					let lProfessions = [o.professions.one];
					for(let s of ['two', 'three']) {
						if(o.professions[s] !== null) {
							lProfessions.push(o.professions[s]);
						}
					}
					o.professions = lProfessions;
				}

				// Set the matches
				matchesSet(res.data);
			}
		});
	}

	// Remove the latest employee so we can load the next
	function next() {
		let lMatches = clone(matches);
		lastSet(lMatches.shift());
		matchesSet(lMatches);
	}

	// Called on a swipe
	function swipe(type) {

		// If it's a left swipe
		if(type === 'left') {

			// Send the choice to the server
			Rest.create('main', 'posting/match', {
				_id: _id,
				choice: 2,
				employee_id: matches[0]._id
			}).done(res => {
				if(res.error && !res._handled) {
					Events.trigger('error', res.error);
				} else if(res.data) {

					// Show reject message
					messageSet(translate('common', 'match_reject', props.locale, {name: (matches[0].fname + ' ' + matches[0].lname)}));

					// Let the parent know
					props.counts(_id, 'reject');

					// Load the next employee
					next();
				}
			});
		}

		// Else if it's a right swipe
		else if(type === 'right') {

			// Send the choice to the server
			Rest.create('main', 'posting/match', {
				_id: _id,
				choice: 1,
				employee_id: matches[0]._id
			}).done(res => {
				if(res.error && !res._handled) {
					Events.trigger('error', res.error);
				} else if(res.data) {

					// Fetch the accept message
					let sMsg = translate('common', 'match_accept', props.locale, {name: (matches[0].fname + ' ' + matches[0].lname)});

					// Load the next employee
					next();

					// If the employee also matched
					if(res.data.employee_choice === 1) {
						sMsg += '\n' + translate('common', 'new_match', props.locale);
					}

					// Let the parent know
					props.counts(_id, 'accept');

					// Set the message popup
					messageSet(sMsg);
				}
			});
		}
	}

	// Undo the last action
	function undo() {

		// Let the server know to remove the last match change
		Rest.delete('main', 'posting/match', {
			_id: _id,
			employee_id: last._id
		}).done(res=> {
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			} else if(res.data) {

				// Add back the last match
				let lMatches = clone(matches);
				lMatches.unshift(last);
				matchesSet(lMatches);

				// Remove last and message popup
				lastSet(null);
				messageSet(false);
			}
		});
	}

	// Render
	return (
		<Box className="singlePage flexRows">
			<Box className="options_button" onClick={ev => optionsSet(true)}>
				<i className="fas fa-filter" />
			</Box>
			<Drawer anchor="top" open={options} onClose={() => optionsSet(false)}>
				<Box className="options_content">
					<Box className="options_content_slider">
						<Slider
							aria-labelledby="distance-slider"
							getAriaValueText={value => value + 'km'}
							marks={[
									{value: 1, label: '1'},
									{value: 5, label: '5'},
									{value: 10, label: '10'},
									{value: 20, label: '20'},
									{value: 30, label: '30'},
									{value: 40, label: '40'},
									{value: 50, label: '50'}
								]}
							max={50}
							min={1}
							onChange={(ev, val) => distanceSet(val)}
							step={1}
							value={distance}
							valueLabelDisplay="auto"
							ValueLabelComponent={SliderLabel}
						/>
					</Box>
				</Box>
			</Drawer>
			<Box className="flexGrow">
				<Box className="container md center padding">
					<Typography><br /><br /></Typography>
					<Typography>{translate('employer', 'no_possible_matches', props.locale)}</Typography>
					<Typography>{translate('employer', 'change_distance', props.locale)}</Typography>
				</Box>
				{matches.length > 1 &&
					<EmployeeView
						key={matches[1]._id}
						locale={props.locale}
						info={props.info}
						mobile={props.mobile}
						value={matches[1]}
					/>
				}
				{matches.length > 0 &&
					<EmployeeView
						key={matches[0]._id}
						locale={props.locale}
						info={props.info}
						mobile={props.mobile}
						swipe={swipe}
						value={matches[0]}
					/>
				}
			</Box>
			{message &&
				<Popup
					action={<Button color="secondary" size="small" onClick={undo}>UNDO</Button>}
					content={message}
					onClose={() => messageSet(false)}
				/>
			}
		</Box>
	);
}

// Valid props
PostingMatch.propTypes = {
	counts: PropTypes.func.isRequired,
	locale: PropTypes.string.isRequired,
	info: PropTypes.object.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}

/**
 * Times: Gig
 *
 * For creating or editing shifts associated with a Gig
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-06-06
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Local components
import Hours from './Hours';

// Shared generic modules
import { iso } from 'shared/generic/dates';
import { afindi, clone, omap, sortByKey, uuidv4 } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';
import Translations from 'data/translations';

/**
 * Gig
 *
 * Handles the shifts for a gig
 *
 * @name Gig
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Gig(props) {

	// Called to add a new shift
	function add() {

		// Add a new value at the end
		props.value.shifts.push({
			key: uuidv4(),
			starts: iso(new Date(), false) + ' 12:00:00',
			hours: {min: 4}
		});

		// Tell the parent
		props.onChange(clone(props.value));
	}

	// Called when any date changes
	function dateChange(key, dt) {

		// Find the index
		let i = afindi(props.value.shifts, 'key', key);

		// If we have an index
		if(i > -1) {
			let oValue = clone(props.value);
			oValue.shifts[i]['starts'] = dt;
			oValue.shifts.sort(sortByKey('starts'));
			props.onChange(oValue);
		}
	}

	// Called when any hours section changes
	function hoursChange(key, val) {

		// Find the index
		let i = afindi(props.value.shifts, 'key', key);

		// If we have an index
		if(i > -1) {
			let oValue = clone(props.value);
			oValue.shifts[i]['hours'] = val;
			props.onChange(oValue);
		}
	}

	// Called to remove an existing shift
	function remove(key) {

		// Find the index
		let i = afindi(props.value.shifts, 'key', key);

		// If we have an index
		if(i > -1) {
			let oValue = clone(props.value);
			oValue.shifts.splice(i, 1);
			props.onChange(oValue);
		}
	}

	// Render
	return (
		<Box className="gigShifts">
			{props.value.shifts.map((o,i) =>
				<React.Fragment key={o.key}>
					<Box className="flexColumns">
						<Box className="flexGrow field">
							<Typography>{translate('common', 'date', props.locale)}</Typography>
							<TextField
								inputProps={{min: iso(new Date(), false)}}
								onChange={ev => dateChange(o.key, ev.target.value + ' ' + props.value.shifts[i].starts.substr(11,8))}
								type="date"
								value={o.starts.substr(0,10)}
								variant={props.variant}
							/>
						</Box>
						<Box className="flexGrow field">
							<Typography>{translate('common', 'time', props.locale)}</Typography>
							<FormControl variant={props.variant}>
								<Select
									native
									onChange={ev => dateChange(o.key, props.value.shifts[i].starts.substr(0,10) + ' ' + ev.target.value)}
									value={o.starts.substr(11,8)}
								>
									{omap(Translations.hours, (o,i) =>
										<option key={i} value={i}>{o[props.locale]}</option>
									)}
								</Select>
							</FormControl>
						</Box>
					</Box>
					<Hours
						errors={(props.errors.shifts && props.errors.shifts[i] && props.errors.shifts[i].hours) || {}}
						locale={props.locale}
						maximums={{min: 16, max: 16}}
						onChange={val => hoursChange(o.key, val)}
						title={translate('common', 'hours', props.locale)}
						value={o.hours || 0}
						variant={props.variant}
					/>
					<Box className="actions">
						<i className="fas fa-minus-square" onClick={ev => remove(o.key)} />
					</Box>
				</React.Fragment>
			)}
			<Box className="field flexColumns">
				<Box className="flexGrow"> </Box>
				<Box className="flexStatic">
					<i className="fas fa-plus-square" onClick={add} />
				</Box>
			</Box>
		</Box>
	);
}

// Valid props
Gig.propTypes = {
	errors: PropTypes.object.isRequired,
	locale: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.object.isRequired,
	variant: PropTypes.string
}

// Default props
Gig.defaultProps = {
	variant: 'standard'
}
/**
 * Times: Hours
 *
 * For associated hours with a shift or weekly job
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2022-01-31
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared generic modules
import { clone } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

/**
 * Times: Hours
 *
 * Handles fixed vs flexible hours on a shift or weekly
 *
 * @name Hours
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Hours(props) {

	// Called when either value changes
	function changeHour(ev) {

		// Modify the field
		props.value[ev.target.name] = parseInt(ev.target.value, 0);

		// Tell the parent
		props.onChange(clone(props.value));
	}

	// Called when the type changes
	function changeType(ev) {

		// If we changed to a single item, it's fixed
		if(ev.target.value === 'fixed') {
			delete props.value.max;
		}

		// Else, we changed to two items, it's flexible
		else {
			props.value.max = props.value.min + 1;

			// If the max is more than allowed
			if(props.value.max > props.maximums.max) {
				props.value.max = props.maximums.max;
				props.value.min = props.maximums.max - 1;
			}
		}

		// Tell the parent
		props.onChange(clone(props.value));
	}

	// Called when an hour field is left to make sure min isn't more than max,
	//	and max isn't less than min
	function checkLimits(ev) {

		// Get the name and value
		let sName = ev.target.name;
		let iValue = parseInt(ev.target.value, 0);

		// If we were on min
		if(sName === 'min') {

			// If we have a max, and the value is more than it
			if(props.value.max !== undefined && iValue >= props.value.max) {
				iValue = props.value.max - 1;
			}
		}

		// Else, if we were on max
		else {

			// If the value is less than min
			if(iValue <= props.value.min) {
				iValue = props.value.min + 1;
			}
		}

		// If the value is less than the component minimum
		if(iValue < props.minimums[sName]) {
			iValue = props.minimums[sName];
		}

		// If the value is more than the component maximum
		if(iValue > props.maximums[sName]) {
			iValue = props.maximums[sName];
		}

		// Set the value
		props.value[ev.target.name] = iValue;

		// Tell the parent
		props.onChange(clone(props.value));
	}

	// Render
	return (
		<Box className="flexColumns">
			<Box className="flexGrow field">
				<Typography>{props.title}</Typography>
				<FormControl variant={props.variant}>
					<Select
						native
						onChange={changeType}
						value={props.value.max === undefined ? 'fixed' : 'flexible'}
					>
						<option value="fixed">{translate('common', 'fixed', props.locale)}</option>
						<option value="flexible">{translate('common', 'flexible', props.locale)}</option>
					</Select>
				</FormControl>
			</Box>
			<Box className="flexGrow field flexColumns">
				<Box className="flexGrow field">
					<Typography>{translate('common', (props.value.max === undefined ? 'duration' : 'duration_min'), props.locale)}</Typography>
					<TextField
						error={props.errors.min ? true : false}
						helperText={props.errors.min ? translate('errors', props.errors.min, props.locale) : ''}
						inputProps={{
							min: props.minimums.min,
							max: props.maximums.min
						}}
						name="min"
						onBlur={checkLimits}
						onChange={changeHour}
						type="number"
						value={props.value.min}
						variant={props.variant}
					/>
				</Box>
				{props.value.max !== undefined &&
					<Box className="flexGrow field">
						<Typography>{translate('common', 'duration_max', props.locale)}</Typography>
						<TextField
							error={props.errors.max ? true : false}
							helperText={props.errors.max ? translate('errors', props.errors.max, props.locale) : ''}
							inputProps={{
								min: props.minimums.max,
								max: props.maximums.max
							}}
							name="max"
							onBlur={checkLimits}
							onChange={changeHour}
							type="number"
							value={props.value.max}
							variant={props.variant}
						/>
					</Box>
				}
			</Box>
		</Box>
	);
}

// Valid props
Hours.propTypes = {
	errors: PropTypes.object.isRequired,
	locale: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	maximums: PropTypes.shape({
		min: PropTypes.number.isRequired,
		max: PropTypes.number.isRequired
	}),
	minimums: PropTypes.shape({
		min: PropTypes.number.isRequired,
		max: PropTypes.number.isRequired
	}),
	title: PropTypes.string.isRequired,
	value: PropTypes.object.isRequired,
	variant: PropTypes.string
};

// Default props
Hours.defaultProps = {
	maximums: {
		min: 48,
		max: 48
	},
	minimums: {
		min: 1,
		max: 1
	}
};
/**
 * Times: Part
 *
 * For creating or editing values associated with a full-time posting
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2022-01-31
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Local components
import Hours from './Hours';

// Shared generic modules
import { iso } from 'shared/generic/dates';
import { clone } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

/**
 * Part
 *
 * Handles the data for a full-time posting
 *
 * @name Part
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Part(props) {

	// Called when any date changes
	function change(field, val) {

		// Modify the index
		props.value[field] = val;

		// Tell the parent
		props.onChange(clone(props.value));
	}

	// Render
	return (
		<React.Fragment>
			<Box className="field">
				<Typography>{translate('common', 'starts', props.locale)}</Typography>
				<TextField
					error={props.errors.starts ? true : false}
					helperText={props.errors.starts ? translate('errors', props.errors.starts, props.locale) : ''}
					inputProps={{min: iso(new Date(), false)}}
					onChange={ev => change('starts', ev.target.value)}
					type="date"
					value={props.value.starts || ''}
					variant={props.variant}
				/>
			</Box>
			<Hours
				errors={(props.errors && props.errors.hours) || {}}
				locale={props.locale}
				onChange={val => change('hours', val)}
				maximums={{max: 48, min: 29}}
				title={translate('common', 'weekly_hours', props.locale)}
				value={props.value.hours || 0}
				variant={props.variant}
			/>
		</React.Fragment>
	);
}

// Valid props
Part.propTypes = {
	errors: PropTypes.object.isRequired,
	locale: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.object.isRequired,
	variant: PropTypes.string
}

// Default props
Part.defaultProps = {
	variant: 'standard'
}
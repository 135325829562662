/**
 * Admin Users Employers Page
 *
 * Handles all the employer management
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-11-21
 */

// NPM modules
import Tree from 'format-oc/Tree';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Local components
import Employer from 'components/pages/admin/composites/Employer';

// Shared components
import { Results, Search } from 'shared/components/Format';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic
import Events from 'shared/generic/events';
import { afindi, clone, nicePhone } from 'shared/generic/tools';

// Load the employee definition
import EmployerDef from 'definitions/employer';
let EmployerCustom = clone(EmployerDef);
EmployerCustom.__react__ = {
	search: ['_id', 'name', 'phone'],
	results: ['_id', '_created', 'name', 'type', 'phone']
}
EmployerCustom._id.__react__ = {title: 'ID'}
EmployerCustom._created.__react__ = {title: 'Created'}
EmployerCustom.name.__react__ = {title: 'Establishment Name'}
EmployerCustom.type.__react__ = {title: 'Establishment Type'}
EmployerCustom.phone.__react__ = {title: 'Phone Number'}

// Create the employer tree
const EmployerTree = new Tree(EmployerCustom);
/**
 * Employers
 *
 * Handles searching and managing employer users
 *
 * @name Employers
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Employers(props) {

	// State
	let [records, recordsSet] = useState(false);
	let [info, infoSet] = useState(false);

	// Load effect
	useEffect(() => {

		// Fetch the info
		infoFetch();

		// Track removals
		Events.subscribe('admin_user_search_remove', removeRecord);

		// Stop tracking removals
		return () => {
			Events.unsubscribe('admin_user_search_remove', removeRecord);
		}

	// eslint-disable-next-line
	}, []);

	// Remove a record
	function removeRecord(_id) {
		recordsSet(values => {
			let i = afindi(values, '_id', _id);
			if(i > -1) {
				values.splice(i, 1);
				return clone(values);
			}
			return values;
		});
	}

	// Fetch all info about and for the employer
	function infoFetch() {
		Rest.read('main', 'info', {}).done(res => {
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}
			if(res.data) {
				infoSet(res.data);
			}
		});
	}

	// Render
	return (
		<Box className="admin_user_employers padding">
			<Search
				hash="employers"
				label="above"
				name="employers"
				noun="employer/search"
				service="admin"
				success={results => recordsSet(results)}
				tree={EmployerTree}
			/>
			{(info !== false && records !== false) && (
				records.length === 0 ?
					<Typography>No results.</Typography> :
					<Results
						actions={[
							{tooltip: "View Employer", icon: 'fas fa-search', component: Employer, props: {
								locales: props.locales,
								info: info
							}}
						]}
						custom={{
							phone: row => nicePhone(row.phone),
							type: row => info['establishmentTypes'][row.type] ? info['establishmentTypes'][row.type]['en-CA'] : ''
						}}
						data={records}
						noun=""
						order="asc"
						orderBy="_created"
						remove={false}
						service=""
						tree={EmployerTree}
						update={false}
					/>
			)}
		</Box>
	);
}

// Valid props
Employers.propTypes = {
	locales: PropTypes.array.isRequired,
	mobile: PropTypes.bool.isRequired
}

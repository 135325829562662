/**
 * Admin Send SMS Page
 *
 * Handles all the sms send functions
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-09-17
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';

/**
 * SMS
 *
 * Handles updating SMS templates
 *
 * @name SMS
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function SMS(props) {

	// State
	let [results, resultsSet] = useState(false);

	// Refs
	let refNumbers = useRef();
	let refContent = useRef();

	// Send the message
	function send() {

		// Get the numbers and content
		let sNumbers = refNumbers.current.value.trim();
		let sContent = refContent.current.value.trim();

		// If either is empty, do nothing
		if(sNumbers === '' || sContent === '') {
			return;
		}

		// Clear results
		resultsSet(false);

		// Turn the numbers into a list
		let lNumbers = sNumbers.split(',').map(s => s.trim());

		// Send to the server
		Rest.create('admin', 'send/sms', {
			numbers: lNumbers,
			content: sContent
		}).done(res => {
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			} else {
				resultsSet(res.data);
			}
		});
	}

	// Render
	return (
		<Box className="admin_send_sms padding">
			<Box className="field">
				<Typography>Comma separated phone numbers</Typography>
				<TextField
					inputRef={refNumbers}
					variant="outlined"
				/>
			</Box>
			<Box className="field">
				<Typography>Content of Message</Typography>
				<TextField
					inputRef={refContent}
					multiline
					variant="outlined"
				/>
			</Box>
			<br />
			<Box className="actions">
				<Button onClick={send} variant="contained">Send</Button>
			</Box>
			{results &&
				<pre>
					{results.map(l => {
						let s = (l[1].success) ? 'success' : `error (${l[1].error})`;
						return `${l[0]}: ${s}\n`;
					})}
				</pre>
			}
		</Box>
	);
}

// Valid props
SMS.propTypes = {
	locales: PropTypes.array.isRequired,
	mobile: PropTypes.bool.isRequired
}

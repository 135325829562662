/**
 * FAQ Record
 *
 * Handles a single FAQ record
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-15
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// Local components
import Entry from './Entry';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { clone } from 'shared/generic/tools';

/**
 * Record
 *
 * Handles displaying questions/answers and editing them
 *
 * @name Record
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Record(props) {

	// State
	let [changed, changedSet] = useState(false);
	let [data, dataSet] = useState(props.value);

	// Add a new question/answer
	function add() {

		// Add a new entry based on the locales to the data
		dataSet(val => {
			let entry = {
				question: {},
				answer: {}
			}
			for(let l of props.locales) {
				entry.question[l._id] = l.name + val.entries.length;
				entry.answer[l._id] = l.name + val.entries.length;
			}
			val.entries.push(entry);
			return clone(val);
		});

		// Mark a change
		changedSet(true);
	}

	// Move a question/answer to the bottom
	function bottom(index) {
		dataSet(val => {
			let lEntries = val.entries.splice(index, 1);
			val.entries.push(lEntries[0]);
			return clone(val);
		});

		// Mark a change
		changedSet(true);
	}

	// Move a question/answer down
	function down(index) {
		dataSet(val => {
			let iLast = val.length - 1;
			let lEntries = val.entries.splice(index, 1);
			if(index === iLast) {
				val.entries.push(lEntries[0]);
			} else {
				val.entries.splice(index+1, 0, lEntries[0]);
			}
			return clone(val);
		});

		// Mark a change
		changedSet(true);
	}

	// Remove an entry
	function remove(index) {
		dataSet(val => {
			val.entries.splice(index, 1);
			return clone(val);
		});

		// Mark a change
		changedSet(true);
	}

	// Save the record
	function save() {

		// Send the data to the server
		Rest.update('admin', 'faq', data).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {

				// Set changed flag
				changedSet(false);
			}
		});
	}

	// Move a question/answer to the top
	function top(index) {
		dataSet(val => {
			let lEntries = val.entries.splice(index, 1);
			val.entries.unshift(lEntries[0]);
			return clone(val);
		});

		// Mark a change
		changedSet(true);
	}

	// Move a question/answer up
	function up(index) {
		dataSet(val => {
			let lEntries = val.entries.splice(index, 1);
			if(index-1 === 0) {
				val.entries.unshift(lEntries[0]);
			} else {
				val.entries.splice(index-1, 0, lEntries[0]);
			}
			return clone(val);
		});

		// Mark a change
		changedSet(true);
	}

	// Called when an entry is updated
	function updated(index, value) {
		dataSet(val => {
			val.entries[index] = value;
			return clone(val);
		});

		// Mark a change
		changedSet(true);
	}

	// Render
	return (
		<Box className="faqs_record">
			<Box className="actions">
				<Tooltip title="Add Question/Answer">
					<IconButton onClick={add}>
						<i className="fas fa-plus" />
					</IconButton>
				</Tooltip>
				<Tooltip title="Save FAQ">
					<IconButton onClick={save}>
						<i className="fas fa-save" style={{color: changed ? 'black' : 'lightgrey'}} />
					</IconButton>
				</Tooltip>
			</Box>
			{data.entries.map((o, i) =>
				<Paper key={i} className="padding flexColumns">
					<Box className="flexGrow">
						<Entry
							locales={props.locales}
							onUpdated={value => updated(i, value)}
							value={o}
						/>
					</Box>
					<Box className="center flexStatic">
						<Typography>
							<Tooltip title="Delete Entry">
								<IconButton onClick={ev => remove(i)}>
									<i className="fas fa-trash" />
								</IconButton>
							</Tooltip>
						</Typography>
						{i !== 0 &&
							<React.Fragment>
								<Typography>
									<Tooltip title="Move to Top">
										<IconButton onClick={ev => top(i)}>
											<i className="fas fa-angle-double-up" />
										</IconButton>
									</Tooltip>
								</Typography>
								<Typography>
									<Tooltip title="Move Up">
										<IconButton onClick={ev => up(i)}>
											<i className="fas fa-angle-up" />
										</IconButton>
									</Tooltip>
								</Typography>
							</React.Fragment>
						}
						{i !== (data.entries.length - 1) &&
							<React.Fragment>
								<Typography>
									<Tooltip title="Move Down">
										<IconButton onClick={ev => down(i)}>
											<i className="fas fa-angle-down" />
										</IconButton>
									</Tooltip>
								</Typography>
								<Typography>
									<Tooltip title="Move to Bottom">
										<IconButton onClick={ev => bottom(i)}>
											<i className="fas fa-angle-double-down" />
										</IconButton>
									</Tooltip>
								</Typography>
							</React.Fragment>
						}
					</Box>
				</Paper>
			)}
		</Box>
	);
}

// Valid props
Record.propTypes = {
	locales: PropTypes.array.isRequired,
	value: PropTypes.object.isRequired
}

/**
 * Admin Users Employees Page
 *
 * Handles all the employee management
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-11-21
 */

// NPM modules
import Tree from 'format-oc/Tree';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Local components
import Employee from 'components/pages/admin/composites/Employee';

// Shared components
import { Results, Search } from 'shared/components/Format';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic
import Events from 'shared/generic/events';
import { afindi, clone } from 'shared/generic/tools';

// Load the employee definition
import EmployeeDef from 'definitions/employee';
let EmployeeCustom = clone(EmployeeDef);
EmployeeCustom.__react__ = {
	search: ['_id', 'fname', 'lname'],
	results: ['_id', '_created', 'fname', 'lname']
}
EmployeeCustom._id.__react__ = {title: 'ID'}
EmployeeCustom._created.__react__ = {title: 'Created'}
EmployeeCustom.fname.__react__ = {title: 'First Name'}
EmployeeCustom.lname.__react__ = {title: 'Last Name'}

// Create the employee tree
const EmployeeTree = new Tree(EmployeeCustom);

/**
 * Employees
 *
 * Handles searching and managing employee users
 *
 * @name Employees
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Employees(props) {

	// State
	let [info, infoSet] = useState({});
	let [records, recordsSet] = useState(false);

	// Load effect
	useEffect(() => {

		// Fetch the info
		infoFetch();

		// Track removals
		Events.subscribe('admin_user_search_remove', removeRecord);

		// Stop tracking removals
		return () => {
			Events.unsubscribe('admin_user_search_remove', removeRecord);
		}

	// eslint-disable-next-line
	}, []);

	// Fetch all info
	function infoFetch() {
		Rest.read('main', 'info', {}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {
				infoSet(res.data);
			}
		});
	}

	// Remove a record
	function removeRecord(_id) {
		recordsSet(values => {
			let i = afindi(values, '_id', _id);
			if(i > -1) {
				values.splice(i, 1);
				return clone(values);
			}
			return values;
		});
	}

	// Render
	return (
		<Box className="admin_user_employees padding">
			<Search
				hash="employees"
				label="above"
				name="employees"
				noun="employee/search"
				service="admin"
				success={results => recordsSet(results)}
				tree={EmployeeTree}
			/>
			{records !== false && (
				records.length === 0 ?
					<Typography>No results.</Typography> :
					<Results
						actions={[
							{tooltip: "View Employee", icon: 'fas fa-search', component: Employee, props: {
								locales: props.locales,
								info: info
							}}
						]}
						data={records}
						noun=""
						order="asc"
						orderBy="_created"
						remove={false}
						service=""
						tree={EmployeeTree}
						update={false}
					/>
			)}
		</Box>
	);
}

// Valid props
Employees.propTypes = {
	locales: PropTypes.array.isRequired,
	mobile: PropTypes.bool.isRequired
}

/**
 * Postings
 *
 * Handles get/set of professions
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-14
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';

// Data modules
import locales from 'data/locales';
import translate from 'data/translate';

/**
 * Postings
 *
 * Displays professions and allows for editing them
 *
 * @name Postings
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Postings(props) {

	// State
	let [info, infoSet] = useState(false);
	let [results, resultsSet] = useState([]);

	// Refs
	let refDistance = useRef();
	let refPostal = useRef();
	let refProfession = useRef();
	let refType = useRef();

	// Load effect
	useEffect(() => {

		// Fetch the info
		Rest.read('main', 'info', {}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {
				infoSet(res.data);
			}
		});

	}, []);

	// Fetch all professions
	function search() {

		// If there's no postal code
		if(refPostal.current.value.trim() === '') {
			Events.trigger('error', 'Please enter a postal code!');
			return;
		}

		// Generate the data
		let oData = {
			postal_code: refPostal.current.value.trim(),
			distance: parseInt(refDistance.current.value)
		}

		// If we have a profession
		if(refProfession.current.value !== '') {
			oData.profession = refProfession.current.value;
		}

		// If we have a type
		if(refType.current.value !== '') {
			oData.type = refType.current.value;
		}

		// Send the request to the server
		Rest.read('admin', 'postings/search', oData).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {
				resultsSet(res.data);
			}
		});
	}

	// Results render
	let Results = null;

	// If we're still loading the results
	if(results === null) {
		Results = <Typography>Loading...</Typography>
	} else if(results.length === 0) {
		Results = <Typography>Nothing returned</Typography>
	} else {
		Results = results.map(o =>
			<Paper key={o._id} className="padding">
				<Typography><strong>{o.employer.name} - {info.professions[o.profession]['en-CA']}</strong></Typography>
				<Typography>{o.skills && o.skills.map((_id, i) =>
						info.skills[_id]['en-CA'] + (i + 1 !== o.skills.length ? ', ' : '')
				)}</Typography>
				<Typography>{o.description}</Typography>
				<Typography>{o.salary_amount} / {o.salary_type}</Typography>
				<Typography>
					{translate('common', 'times_' + o.times.type, 'en-CA')}
					&nbsp;-&nbsp;
					{o.times.type === 'gig' ?
						o.times.shifts.map(o => o.starts).join(' | ') :
						o.times.starts
					}
				</Typography>
				<Typography>{o.filled ? 'Filled' : 'Open'}</Typography>
				<Typography>Interested: {o.potential_matches}</Typography>
				<Typography>Matches: {o.matches}</Typography>
				<Typography><a className="blue" href={`https://${window.location.host}/posting/${o._id}`} target="_blank" rel="noreferrer">https://{window.location.host}/posting/{o._id}</a></Typography>
			</Paper>
		);
	}

	// Render
	return (
		<Box className="postings padding">
			<Box className="header">
				<Typography className="title">Postings</Typography>
			</Box>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={4} md={3} className="field">
					<Typography>Profession</Typography>
					<Select
						labelId="employeeProfessionOne"
						native
						inputRef={refProfession}
						variant="outlined"
					>
						<option value=''></option>
						{info && locales.sortObject(info.professions)['en-CA'].map(o =>
							<option key={o._id} value={o._id}>{o.text}</option>
						)}
					</Select>
				</Grid>
				<Grid item xs={12} sm={2} className="field">
					<Typography>Type</Typography>
					<Select
						native
						inputRef={refType}
						variant="outlined"
					>
						<option value="">{translate('common', 'all', 'en-CA')}</option>
						<option value="seasonal">{translate('common', 'times_seasonal', 'en-CA')}</option>
						<option value="part">{translate('common', 'times_part', 'en-CA')}</option>
						<option value="full">{translate('common', 'times_full', 'en-CA')}</option>
						<option value="gig">{translate('common', 'times_gig', 'en-CA')}</option>
					</Select>
				</Grid>
				<Grid item xs={12} sm={2} className="field">
					<Typography>Postal Code</Typography>
					<TextField
						name="postal_code"
						inputRef={refPostal}
						type="text"
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={12} sm={2} className="field">
					<Typography>Radius</Typography>
					<TextField
						defaultValue={5}
						InputProps={{inputProps: {min: 5, max: 50}}}
						inputRef={refDistance}
						name="postal_code"
						type="number"
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={12} sm={2} className="field">
					<Typography>&nbsp;</Typography>
					<Button color="primary" onClick={search} variant="contained">Search</Button>
				</Grid>
			</Grid>
			<br />
			{Results}
		</Box>
	);
}

// Valid props
Postings.propTypes = {
	locales: PropTypes.array.isRequired,
	mobile: PropTypes.bool.isRequired
}
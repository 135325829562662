/**
 * Experience
 *
 * Handles entering previous job experience
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-11-13
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared generic modules
import { iso } from 'shared/generic/dates';
import { clone, uuidv4 } from 'shared/generic/tools';

// Composite components
import Address from 'components/composites/Address';

// Data modules
import translate from 'data/translate';

/**
 * Job
 *
 * Displays a form to add or edit a single job
 *
 * @name Job
 * @access private
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
function Job(props) {

	// Capture changes
	function change(ev) {
		let oValues = clone(props.value);
		oValues[ev.target.name] = ev.target.value.trim() === '' ? null : ev.target.value;
		props.onChange(oValues);
	}

	// Capture address changes
	function changeAddress(value) {
		let oValues = clone(props.value);
		oValues.address = value;
		props.onChange(oValues);
	}

	// Capture "still employed" change
	function changeStillEmployed(ev) {

		// Clone the existing values
		let oValues = clone(props.value);

		// If the element is now checked
		if(ev.target.checked) {
			delete oValues.till;
		} else {
			oValues.till = iso(new Date(), false);
		}

		// Let the parent know
		props.onChange(oValues);
	}

	// Render the component
	return (
		<Box className="experience">
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6} className="field">
					<Typography>{translate('common', 'establishment', props.locale)}</Typography>
					<TextField
						error={props.errors.establishment ? true : false}
						helperText={props.errors.establishment ? translate('errors', props.errors.establishment, props.locale) : ''}
						name="establishment"
						onChange={change}
						type="text"
						value={props.value.establishment || ''}
						variant={props.variant}
					/>
				</Grid>
				<Grid item xs={12} sm={6} className="field">
					<Typography>{translate('common', 'profession', props.locale)}</Typography>
					<FormControl
						error={props.errors.profession ? true : false}
						variant={props.variant}
					>
						<Select
							name="profession"
							native
							onChange={change}
							value={props.value.profession || ''}
						>
							{props.value.profession}
							{props.professions[props.locale].map(o =>
								<option key={o._id} value={o._id}>{o.text}</option>
							)}
						</Select>
						{props.errors.profession &&
							<FormHelperText>{translate('errors', props.errors.profession, props.locale)}</FormHelperText>
						}
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<Address
						errors={props.errors.address || {}}
						fields={['city', 'division', 'country']}
						locale={props.locale}
						onChange={address => changeAddress(address)}
						value={props.value.address}
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={12} sm={6} className="field">
					<Typography>{translate('common', 'from', props.locale)}</Typography>
					<TextField
						error={props.errors.from ? true : false}
						helperText={props.errors.from ? translate('errors', props.errors.from, props.locale) : ''}
						name="from"
						onChange={change}
						type="date"
						value={props.value.from || ''}
						variant={props.variant}
					/>
				</Grid>
				<Grid item xs={12} md={6} className="field">
					<Typography>{translate('common', 'to', props.locale)}</Typography>
					{props.value.hasOwnProperty('till') &&
						<TextField
							error={props.errors.till ? true : false}
							helperText={props.errors.till ? translate('errors', props.errors.till, props.locale) : ''}
							name="till"
							onChange={change}
							type="date"
							value={props.value.till || ''}
							variant={props.variant}
						/>
					}
					&nbsp;&nbsp;
					<FormControlLabel
						control={
							<Switch
								checked={!props.value.hasOwnProperty('till')}
								color="primary"
								onChange={changeStillEmployed}
							/>
						}
						label={translate('common', 'still_employed', props.locale)}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}

// Valid props
Job.propTypes = {
	errors: PropTypes.object,
	locale: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	professions: PropTypes.object.isRequired,
	value: PropTypes.object,
	variant: PropTypes.string
}

// Default props
Job.defaultProps = {
	errors: {},
	value: {},
	variant: 'standard'
}

/**
 * Experience
 *
 * Displays the user's experiences
 *
 * @name Experience
 * @access private
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Experience(props) {

	// State
	let [uuid, uuidSet] = useState([]);

	// Load effect
	useEffect(() => {
		uuidSet(props.value.map(o => uuidv4()));
	// eslint-disable-next-line
	}, []);

	// Capture changes
	function change(i, value) {
		let lValues = clone(props.value);
		lValues[i] = value;
		props.onChange(lValues);
	}

	// Add new experience
	function add() {

		// Add new element
		let lValues = clone(props.value);
		lValues.push({
			establishment: '',
			profession: props.professions[props.locale][0]['_id'],
			address: {
				city: '',
				division: 'QC',
				country: 'CA'
			},
			from: iso(new Date(), false)
		});
		props.onChange(lValues);

		// Update UUIDs
		uuidSet(val => {
			val.unshift(uuidv4());
			return clone(val);
		});
	}

	// Remove an existing experience
	function remove(i) {

		// Remove the element
		let lValues = clone(props.value);
		lValues.splice(i,1);
		props.onChange(lValues);

		// Update UUIDs
		uuidSet(val => {
			val.splice(i,1);
			return clone(val);
		});
	}

	// Render
	return (
		<React.Fragment>
			{uuid.length > 0 && props.value.map((o,i) =>
				<React.Fragment key={uuid[i]}>
					<Box className="flexColumns">
						<Box className="flexGrow">
							<Job
								errors={props.errors[i]}
								locale={props.locale}
								onChange={value => change(i, value)}
								professions={props.professions}
								value={o}
								variant={props.variant}
							/>
						</Box>
						<Box className="flexStatic padding">
							<i className="fas fa-trash-alt" onClick={ev => remove(i)} />
						</Box>
					</Box>
					{i < (props.value.length-1) &&
						<Box style={{padding: '5px 0'}}>
							<hr className="blue small" />
						</Box>
					}
				</React.Fragment>
			)}
			<Box className="flexColumns padding">
				<Box className="flexGrow">&nbsp;</Box>
				<Box className="flexStatic">
					<i className="fas fa-plus" onClick={add} />
				</Box>
			</Box>
		</React.Fragment>
	);
}

// Valid props
Experience.propTypes = {
	errors: PropTypes.array,
	locale: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	professions: PropTypes.object.isRequired,
	value: PropTypes.array,
	variant: PropTypes.string
}

// Default props
Experience.defaultProps = {
	errors: [],
	value: [],
	variant: 'standard'
}

/**
 * Messaging
 *
 * Switch for employee pages
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-19
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Local components
import Conversation from './Conversation';
import List from './List';

/**
 * Messaging
 *
 * Handles employee messaging
 *
 * @name Messaging
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Messaging(props) {

	// Render
	return (
		<Switch>
			<Route exact path="/employee/messaging">
				<List {...props} />
			</Route>
			<Route exact path="/employee/messaging/:_id">
				<Conversation {...props} />
			</Route>
		</Switch>
	);
}

// Valid props
Messaging.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}

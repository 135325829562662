/**
 * Main
 *
 * Used for gathering email addresses
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-05-25
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Site components
import StandardLinks from 'components/composites/StandardLinks';

// Generic communication modules
import Rest from 'shared/communication/rest';

// Data modules
import translate from 'data/translate';

// Images
import ssvp600 from 'images/ssvp-600.svg';

// Video links
const VIDEO = {
	"en-CA": "https://www.youtube.com/embed/n_yWp_B7CIE",
	"fr-CA": "https://www.youtube.com/embed/0O9wHeybP44"
}

/**
 * Main
 *
 * Handles main page to collect emails
 *
 * @name Main
 * @access public
 * @params Object props Attributes sent to the component
 * @return React.Component
 */
export default function Main(props) {

	// State
	let [employeeOpen, employeeOpenSet] = useState(false);
	let [employerOpen, employerOpenSet] = useState(false);
	let [faq, faqSet] = useState({
		employee: [],
		employer: []
	});

	// Load effect
	useEffect(() => {
		Rest.read('main', 'faq', {}).done(res => {
			faqSet(res.data);
		});
	// eslint-disable-next-line
	}, []);

	// Render
	return (
		<div className="beta">
			<main className="page-content">
				<div className="container md">
					<div className="row justify-content-center">
						<div className="col-10 center">
							<img src={ssvp600} className="beta-logo" alt="ServiceSVP - Swipe Match Staff" />
							<br />
							<h1>{translate('beta', 'slogan_bottom', props.locale)}</h1>
							<Link to="/signup">
								<button className="btn" type="button" id="button-addon2">{translate('main', 'signup', props.locale)}</button>
							</Link>
							<br />
							<br />
						</div>
					</div>
					<div className="faq">
						<div className="faq_title" onClick={ev => employeeOpenSet(val => !val)}>
							<p>{translate('beta', 'staff_title', props.locale).toUpperCase()} FAQ <i className={'fas fa-caret-' + (employeeOpen ? 'up' : 'down') } /></p>
						</div>
						{employeeOpen &&
							<div className="faq_content">
								{faq.employee.map(o =>
									<React.Fragment>
										<div className="faq_question">{o.question[props.locale]}</div>
										<div className="faq_answer">{o.answer[props.locale] && o.answer[props.locale].length > 0 && o.answer[props.locale].split('\n').map((s,i) => <p key={i}>{s}</p>)}</div>
									</React.Fragment>
								)}
							</div>
						}
					</div>
					<div className="faq">
						<div className="faq_title" onClick={ev => employerOpenSet(val => !val)}>
							<p>{translate('beta', 'establishments_title', props.locale).toUpperCase()} FAQ <i className={'fas fa-caret-' + (employerOpen ? 'up' : 'down') } /></p>
						</div>
						{employerOpen &&
							<div className="faq_content">
								{faq.employer.map(o =>
									<React.Fragment>
										<div className="faq_question">{o.question[props.locale]}</div>
										<div className="faq_answer">{o.answer[props.locale] && o.answer[props.locale].length > 0 && o.answer[props.locale].split('\n').map((s,i) => <p key={i}>{s}</p>)}</div>
									</React.Fragment>
								)}
							</div>
						}
					</div>
					<div id="video">
						<iframe className="main_video" title="youtube" src={VIDEO[props.locale]} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
					</div>
					<footer className="page-footer">
						<StandardLinks locale={props.locale} />
					</footer>
				</div>
			</main>
		</div>
	);
}

// Valid props
Main.propTypes = {
	locale: PropTypes.string.isRequired
}

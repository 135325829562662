/**
 * Matches
 *
 * Displays the list of full matches for the given posting
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-04
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

// Composite components
import ConversationItem from 'components/composites/ConversationItem';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';

// Data modules
import Messaging from 'data/messaging';
import translate from 'data/translate';

/**
 * Matches
 *
 * Handles main employer page
 *
 * @name Matches
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Matches(props) {

	// State
	let [convos, convosSet] = useState([]);
	let [list, listSet] = useState([]);
	let [matches, matchesSet] = useState([]);

	// Hooks
	let { _id } = useParams();

	// Load effect
	useEffect(() => {

		// Fetch the matches
		matchesFetch();

		// Subscribe to conversations updates
		convosSet(
			Messaging.subscribe('conversations', convosSet)
		);
		return () => {
			Messaging.unsubscribe('conversations', convosSet);
		}

	// eslint-disable-next-line
	}, [_id]);

	// Any time the matches or conversations change
	useEffect(() => {

		console.log(convos);
		console.log(matches);

		// Create a new list
		let lList = [];

		// Go through every conversation
		for(let o of convos) {

			// If the convo is in the list of matches
			if(matches.indexOf(o.employee) > -1) {
				lList.push(o);
			} else {
				console.log('not in');
			}
		}

		// Set the new list
		listSet(lList);

	}, [convos, matches])

	// Fetch the matches for the posting
	function matchesFetch() {

		// Request from the server
		Rest.read('main', 'posting/matches', {
			_id: _id
		}).done(res => {
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}
			if(res.data) {
				matchesSet(res.data);
			}
		});
	}

	// Render
	return (
		<Box className="conversation flexRows">
			<Box className="conversation_display flexColumns">
				<Box className="flexGrow">
					<Typography>{translate('common', 'matches', props.locale)}</Typography>
				</Box>
			</Box>
			<Box className="flexGrow">
				<List className="conversations" disablePadding>
					<Divider />
					{list.map(o =>
						<ConversationItem
							key={o._id}
							locale={props.locale}
							mobile={props.mobile}
							owner="r"
							value={o}
						/>
					)}
				</List>
			</Box>
		</Box>
	);
}

// Valid props
Matches.propTypes = {
	locale: PropTypes.string.isRequired,
	info: PropTypes.object.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}

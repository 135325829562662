/**
 * Posting Form
 *
 * The form to add/edit data
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2022-03-12
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Material UI
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Composite components
import Address from 'components/composites/Address';

// Local components
import Times from './Times';

// Shared generic modules
import { clone } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

/**
 * Posting Form
 *
 * The actual re-usable form for create/copy/edit
 *
 * @name PostingForm
 * @access public
 * @param Object props Attributes sent to the component
 * @returns React.Component
 */
export default function PostingForm(props) {

	// State
	let [skills, skillsSet] = useState(false);

	// Skills specifically changed
	function skillsChanged(ev) {

		// If we have 3
		if(ev.target.value.length === 3) {
			skillsSet(false);
		}

		// Send to the default fieldChanged function
		props.fieldChanged('skills', (ev.target.value.length > 3 ?
			ev.target.value.slice(0,3) :
			ev.target.value
		));
	}

	// Times changed
	function timesChanged(val) {

		// If it was a gig and now it's not
		if(props.value.times.type === 'gig' && val.type !== 'gig') {

			// Remove the address
			props.fieldChanged('address', null);
		}

		// Send the updated times
		props.fieldChanged('times', val);
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={6} className="field">
				<Typography>{translate('common', 'profession', props.locale)}</Typography>
				<FormControl
					error={props.errors.profession ? true : false}
					variant="outlined"
				>
					<Select
						native
						onChange={ev => props.fieldChanged('profession', ev.target.value)}
						value={props.value.profession}
						inputProps={{
							id: 'postingProfession'
						}}
					>
						<option value=""></option>
						{props.setup.professions && props.setup.professions[props.locale].map(o =>
							<option key={o._id} value={o._id}>{o.text}</option>
						)}
					</Select>
					{props.errors.profession &&
						<FormHelperText>{translate('errors', props.errors.profession, props.locale)}</FormHelperText>
					}
				</FormControl>
			</Grid>
			<Grid item xs={6} sm={3} className="field">
				<Typography>{translate('common', 'salary_amount', props.locale)}</Typography>
				<TextField
					error={props.errors.salary_amount ? true : false}
					helperText={props.errors.salary_amount ? translate('errors', props.errors.salary_amount, props.locale) : ''}
					inputProps={{
						min: 1,
						step: 'any'
					}}
					onChange={ev => props.fieldChanged('salary_amount', ev.target.value)}
					type="number"
					value={props.value.salary_amount}
					variant="outlined"
					InputProps={{
						startAdornment: <InputAdornment position="start">$</InputAdornment>,
					}}
				/>
			</Grid>
			<Grid item xs={6} sm={3} className="field">
				<Typography>{translate('common', 'tip_average', props.locale)}</Typography>
				<TextField
					error={props.errors.tip_average ? true : false}
					helperText={props.errors.tip_average ? translate('errors', props.errors.tip_average, props.locale) : ''}
					inputProps={{
						min: 1,
						step: 'any'
					}}
					onChange={ev => props.fieldChanged('tip_average', ev.target.value)}
					type="number"
					value={props.value.tip_average || ''}
					variant="outlined"
					InputProps={{
						startAdornment: <InputAdornment position="start">$</InputAdornment>,
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6} className="field">
				<Typography>{translate('employer', 'posting_skills', props.locale)}</Typography>
				<FormControl
					error={props.errors.skills ? true : false}
					variant="outlined"
				>
					<Select
						labelId="postingSkillsControl"
						multiple
						open={skills}
						value={props.value.skills}
						onChange={skillsChanged}
						onClose={() => skillsSet(false)}
						onOpen={() => skillsSet(true)}
						input={<Input id="employeeSkillsInput" />}
						renderValue={selected => (
							<div>
								{selected.map(_id =>
									<Chip key={_id} label={props.info.skills[_id][props.locale]} />
								)}
							</div>
						)}
					>
						{props.setup.skills && props.setup.skills[props.locale].map(o =>
							<MenuItem key={o._id} value={o._id}>{o.text}</MenuItem>
						)}
					</Select>
					{props.errors.skills &&
						<FormHelperText>{translate('errors', props.errors.skills, props.locale)}</FormHelperText>
					}
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<Times
					errors={props.errors.times || {}}
					locale={props.locale}
					mode={props.mode}
					onChange={timesChanged}
					value={props.value.times}
					variant="outlined"
				/>
			</Grid>
			{props.value.times.type === 'gig' &&
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Switch
								checked={!Boolean(props.value.address)}
								color="primary"
								onChange={ev => props.fieldChanged('address', ev.target.checked ? null : clone(props.employer.address))}
							/>
						}
						label={translate('employer', 'posting_address_option', props.locale)}
					/>
					{props.value.address &&
						<Address
							errors={props.errors.address || {}}
							fields={['line1', 'line2', 'city', 'postal_code']}
							locale={props.locale}
							onChange={val => props.fieldChanged('address', val)}
							value={props.value.address}
							variant="outlined"
						/>
					}
				</Grid>
			}
			<Grid item xs={12} className="field">
				<Typography>{translate('common', 'description', props.locale)}</Typography>
				<TextField
					error={props.errors.description ? true : false}
					helperText={props.errors.description ? translate('errors', 'posting_description_' + props.errors.description, props.locale) : ''}
					multiline
					onChange={ev => props.fieldChanged('description', ev.target.value)}
					type="text"
					value={props.value.description || ''}
					variant="outlined"
					inputProps={{
						maxLength: 300
					}}
				/>
			</Grid>
		</Grid>
	)
}

// Valid props
PostingForm.propTypes = {
	employer: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
	errors: PropTypes.object,
	fieldChanged: PropTypes.func.isRequired,
	info: PropTypes.object.isRequired,
	locale: PropTypes.string.isRequired,
	mode: PropTypes.oneOf(['create', 'edit']),
	setup: PropTypes.object.isRequired,
	value: PropTypes.object
}

// Default props
PostingForm.defaultProps = {
	errors: {},
	value: {}
}
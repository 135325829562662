/**
 * Standard Links
 *
 * Handles pages and social links
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-11-20
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Data modules
import translate from 'data/translate';

/**
 * StandardLinks
 *
 * Handles displaying socials and stanard pages
 *
 * @name StandardLinks
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function StandardLinks(props) {

	// Render
	return (
		<Box id="footer" className="center padding">
			<Typography>2021 Service SVP Inc.</Typography>
			<Typography>
				<a href="mailto:support@servicesvp.com">support@ServiceSVP.com</a>&nbsp;
				<span className="blue">|</span>&nbsp;
				<Link to="/privacy-policy">{translate('common', 'privacy_policy', props.locale)}</Link>&nbsp;
				<span className="blue">|</span>&nbsp;
				<Link to="/terms-and-conditions">{translate('common', 'terms_and_conditions', props.locale)}</Link>
			</Typography>
			<Typography className="footer_icons">
				<a href="https://www.facebook.com/Service-SVP-103696858725532" target="_blank" rel="noreferrer"><i className="fab fa-facebook blue" /></a>&nbsp;
				<a href="https://www.instagram.com/servicesvp/" target="_blank" rel="noreferrer"><i className="fab fa-instagram blue" /></a>&nbsp;
				<a href="https://twitter.com/service_svp" target="_blank" rel="noreferrer"><i className="fab fa-twitter blue" /></a>&nbsp;
				<a href="https://www.youtube.com/channel/UCxUxbo8cy-n3bUbNPMvz-aw" target="_blank" rel="noreferrer"><i className="fab fa-youtube blue" /></a>&nbsp;
			</Typography>
		</Box>
	)
}

// Valid props
StandardLinks.propTypes = {
	locale: PropTypes.string.isRequired
}

/**
 * Outside Click
 *
 * Triggers on mouse clicks outside of the given ref
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-15
 */

// NPM modules
import { useEffect } from 'react';

/**
 * Use Outside Click
 *
 * @name useOutsideClick
 * @access public
 * @param Ref ref The ref to track
 */
export function useOutsideClick(ref, callback) {

	// Create a new effect
	useEffect(() => {

		function click(ev) {
			if (ref.current && !ref.current.contains(ev.target)) {
				callback();
			}
		}

		// Bind/unbind the event listener
		document.addEventListener('mousedown', click);
		return () => {
			document.removeEventListener('mousedown', click);
		};

	}, [ref, callback]);
}

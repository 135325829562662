/**
 * Locales
 *
 * Keeps track of the locales available
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-17
 */

// Shared communications modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { clone } from 'shared/generic/tools';

// Global variables
let _callbacks = [];
let _locales = null;
let _running = false;

/**
 * Fetch
 *
 * Gets all the locales from the server
 *
 * @name fetch
 * @access private
 * @return void
 */
function fetch() {

	// If we're not already running
	if(!_running) {
		_running = true;

		// Fetch the data from the server
		Rest.read('main', 'locales', {}, {session: false}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {

				// Store the data
				_locales = res.data;

				// Trigger all callbacks
				notify();
			}

			// Finish running
			_running = false;
		});
	}
}

/**
 * Get
 *
 * Returns the current list of locales
 *
 * @name get
 * @access public
 * @returns list
 */
export function get() {

	// If we have the data
	if(_locales !== null) {
		return clone(_locales);
	}

	// Return an empty list for now
	return [];
}

/**
 * Notify
 *
 * Calls all the callbacks with the current data
 *
 * @name notify
 * @access private
 * @return void
 */
function notify() {

	// Clone the current data
	let lLocales = clone(_locales);

	// Pass the clone to everyone tracking
	for(let f of _callbacks) {
		f(lLocales);
	}
}

/**
 * Sort Array
 *
 * Splits the records so they are stored by locale and then orders them
 * alphabetically
 *
 * @name sortArray
 * @access public
 * @param Array records The records to re-order
 * @param String id Optional key for ID value
 * @param String locales Optional key for locale values
 * @return Object
 */
function sortArray(records, id='_id', locales='locales') {

	// Init the return
	let oRet = {};

	// Go through each record
	for(let oRecord of records) {

		// Go through each locale
		for(let sLocale in oRecord[locales]) {

			// If we don't have the locale
			if(!(sLocale in oRet)) {
				oRet[sLocale] = [];
			}

			// Add the ID and text to the list
			oRet[sLocale].push({
				[id]: oRecord[id],
				text: oRecord[locales][sLocale]
			});
		}
	}

	// Go through each locale
	for(let sLocale in oRet) {

		// Sort it alphabetically
		oRet[sLocale].sort((a, b) => {
			if(a.text.normalize('NFD') === b.text.normalize('NFD')) return 0;
			else return (a.text.normalize('NFD') < b.text.normalize('NFD')) ? -1 : 1;
		});
	}

	// Return the new structure
	return oRet;
}

/**
 * Sort Object
 *
 * Splits the records so they are stored by locale and then orders them
 * alphabetically
 *
 * @name sortObject
 * @access public
 * @param Object records The records to re-order
 * @return Object
 */
function sortObject(records) {

	// Init the return
	let oRet = {};

	// Go through each record
	for(let sID in records) {

		// Go through each locale
		for(let sLocale in records[sID]) {

			// If we don't have the locale
			if(!(sLocale in oRet)) {
				oRet[sLocale] = [];
			}

			// Add the ID and text to the list
			oRet[sLocale].push({
				_id: sID,
				text: records[sID][sLocale]
			});
		}
	}

	// Go through each locale
	for(let sLocale in oRet) {

		// Sort it alphabetically
		oRet[sLocale].sort((a, b) => {
			if(a.text.normalize('NFD') === b.text.normalize('NFD')) return 0;
			else return (a.text.normalize('NFD') < b.text.normalize('NFD')) ? -1 : 1;
		});
	}

	// Return the new structure
	return oRet;
}

/**
 * Subscribe
 *
 * Subscribes to locale changes and returns the current data
 *
 * @name subscribe
 * @access public
 * @param Function callback The callback to register for future updates
 * @return Array
 */
function subscribe(callback) {

	// Add the callback to the list
	_callbacks.push(callback);

	// If we have the data
	if(_locales !== null) {
		return clone(_locales);
	}

	// Fetch the locales
	fetch();

	// Return an empty list for now
	return [];
}

/**
 * Ubsubscribe
 *
 * Removes a callback from the list of who gets notified on changes
 *
 * @name ubsubscribe
 * @access public
 * @param Function callback The callback to remove
 * @return void
 */
function unsubscribe(callback) {
	let i = _callbacks.indexOf(callback);
	if(i > -1) {
		_callbacks.splice(i, 1);
	}
}

// Default export
const locales = {
	get: get,
	sortArray: sortArray,
	sortObject: sortObject,
	subscribe: subscribe,
	unsubscribe: unsubscribe
}
export default locales;

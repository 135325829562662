/**
 * Messaging
 *
 * Keeps track of messaging
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-19
 */

// Shared communication modules
import Rest from 'shared/communication/rest';
import TwoWay from 'shared/communication/twoway';

// Shared generic modules
import Events from 'shared/generic/events';
import PageVisibility from 'shared/generic/pageVisibility';
import { afindi, clone, merge } from 'shared/generic/tools';

// Global variables
let _callbacks = {};
let _prev = null;
let _state = null;

/**
 * Calculate Unread
 *
 * Calculates the total unread conversations based on the owner type and
 * notifies anyone tracking the value
 *
 * @name _calculateUnread
 * @access private
 * @returns void
 */
function _calculateUnread() {

	// Calculate the unread count
	let iCount = 0;
	for(let o of _state.conversations) {
		if(o[`${_state.root}_info`].unread) {
			iCount += 1;
		}
	}

	// If the value changed
	if(iCount !== _state.unread) {

		// Update it
		_state.unread = iCount;

		// If there's anyone subscribed
		if('unread' in _callbacks && _callbacks['unread'].length) {

			// Go through each unread callback and send the new data
			for(let f of _callbacks['unread']) {
				f(_state.unread);
			}
		}
	}
}

/**
 * Increment Unread
 *
 * Increases the unread count of a single conversation by one
 *
 * @name _incrementUnread
 * @access private
 * @param String _id The ID of the conversation
 * @param Number _updated The new _updated timestamp
 * @returns void
 */
function _incrementUnread(_id, _updated) {

	// If we have no state, just do nothing
	if(_state === null) {
		return;
	}

	// Find the conversation
	let i = afindi(_state.conversations, '_id', _id);

	// If it's found
	if(i > -1) {

		// Generate the info key
		let sKey = `${_state.root}_info`;

		// Generate the message
		let oMsg = {
			conversation: _id,
			name: 'info',
			data: {
				_updated: _updated,
				[sKey]: {
					hidden: false,
					unread: _state.conversations[i][sKey].unread + 1
				}
			}
		}

		// Marge the changes to the convo
		merge(_state.conversations[i], oMsg.data);

		// Notify
		_notifyConvo(oMsg);
		_notifyConvos();

		// Calculate unread
		_calculateUnread();
	}
}

/**
 * Message
 *
 * Called when new messages show up from the websocket
 *
 * @name _message
 * @access private
 * @param Object msg The message received
 * @returns void
 */
function _message(msg) {

	// Switch based on the message type
	switch(msg.name) {

		// If we got a new conversation
		case 'conversation':

			// Add it to the top of the list and notify conversations and unread
			_state.conversations.unshift(msg.data);
			_notifyConvos();
			_calculateUnread();
			break;

		// If we got an info update
		case 'info':

			// Update
			update(msg);
			break;

		// If we got a new message
		case 'message':

			// Increment the unread count
			_incrementUnread(msg.conversation, msg.data.created);

			// Notify anyone who cares about the convo
			_notifyConvo(msg);
			break;

		// Unknown message type
		default:
			Events.trigger('error', 'Unknown websocket message: ' + JSON.stringify(msg));
			break;
	}
}

/**
 * Notify Conversation
 *
 * Notifies anyone interested in changes about a single conversation
 *
 * @name _notifyConvo
 * @access private
 * @param Object msg The message
 * @returns void
 */
function _notifyConvo(msg) {

	// If there's anyone subscribed
	if(msg.conversation in _callbacks && _callbacks[msg.conversation].length) {

		// Go through each callback and send the data
		for(let f of _callbacks[msg.conversation]) {
			f(msg);
		}
	}
}

/**
 * Notify Conversations
 *
 * Notifies anyone interested about conversation changes
 *
 * @name _notifyConvos
 * @access private
 * @returns void
 */
function _notifyConvos() {

	// If there's anyone subscribed
	if('conversations' in _callbacks && _callbacks['conversations'].length) {

		// Clone the convos
		let oConvos = clone(_state.conversations);

		// Go through each callback and send the data
		for(let f of _callbacks['conversations']) {
			f(oConvos);
		}
	}
}

/**
 * Block
 *
 * Marks a conversation as blocked
 *
 * @name block
 * @access public
 * @param String _id The ID of the conversation
 * @param Function success Optional, a callback to run after successful block
 * @returns void
 */
export function block(_id, success=null) {

	// Send the message to the server
	Rest.update('main', `${_state.root}/conversation/info`, {
		_id: _id,
		blocked: true
	}).done(res => {
		if(res.error && !res._handled) {
			Events.trigger('error', res.error);
		}
		if(res.data) {
			update({
				name: 'info',
				conversation: _id,
				data: {
					[`${_state.root}_info`]: {
						blocked: true
					}
				}
			});

			// If we have a callback
			if(success) {
				success();
			}
		}
	});
}

/**
 * Hide
 *
 * Marks a conversation as hidden
 *
 * @name hide
 * @access public
 * @param String _id The ID of the conversation
 * @param Function success Optional, a callback to run after successful hide
 * @returns void
 */
export function hide(_id, success=null) {

	// Send the message to the server
	Rest.update('main', `${_state.root}/conversation/info`, {
		_id: _id,
		hidden: true
	}).done(res => {
		if(res.error && !res._handled) {
			Events.trigger('error', res.error);
		}
		if(res.data) {
			update({
				name: 'info',
				conversation: _id,
				data: {
					[`${_state.root}_info`]: {
						hidden: true
					}
				}
			});

			// If we have a callback
			if(success) {
				success();
			}
		}
	});
}

/**
 * Read
 *
 * Lets the rest service know the conversation was read
 *
 * @name read
 * @access public
 * @param String _id The ID of the conversation
 * @returns void
 */
export function read(_id) {

	// Send the message to the server
	Rest.update('main', `${_state.root}/conversation/info`, {
		_id: _id,
		unread: 0
	}).done(res => {
		if(res.error && !res._handled) {
			Events.trigger('error', res.error);
		}
		if(res.data) {
			update({
				name: 'info',
				conversation: _id,
				data: {
					[`${_state.root}_info`]: {
						unread: 0
					}
				}
			});
		}
	});
}

/**
 * Rename
 *
 * Renames a conversation
 *
 * @name rename
 * @access public
 * @param String _id The ID of the conversation
 * @param String name The new name of the conversation
 * @param Function success Optional, a callback to run after successful rename
 * @returns void
 */
export function rename(_id, name, success=null) {

	// Send the message to the server
	Rest.update('main', `${_state.root}/conversation/info`, {
		_id: _id,
		display_name: name
	}).done(res => {
		if(res.error && !res._handled) {
			Events.trigger('error', res.error);
		}
		if(res.data) {
			update({
				name: 'info',
				conversation: _id,
				data: {
					[`${_state.root}_info`]: {
						display_name: name
					}
				}
			});

			// If we have a callback
			if(success) {
				success();
			}
		}
	});
}

/**
 * Send
 *
 * Send a new message on a conversation
 *
 * @name send
 * @access public
 * @param String _id The ID of the conversation
 * @param String content The content of the message
 * @param Function success Optional, a callback to run after successful send
 * @returns void
 */
export function send(_id, content, success=null) {

	// Send the content to the rest service
	Rest.create('main', `${_state.root}/message`, {
		_id: _id,
		content: content
	}).done(res => {

		// If there's an error
		if(res.error && !res._handled) {
			Events.trigger('error', res.error);
		}

		// If we were successful
		if(res.data) {

			// Update the _updated field
			update({
				name: 'info',
				conversation: _id,
				data: {
					_updated: Date.now()/1000
				}
			});

			// If we have a callback
			if(success) {
				success();
			}
		}
	});
}

/**
 * Start
 *
 * Start tracking the ID via the websocket
 *
 * @name start
 * @access public
 * @param String _id The ID to track
 * @param String owner One of 'e' or 'r' for employee or employer
 * @returns void
 */
export function start(_id, owner) {

	// Store the old state
	let oOldState = _state || null;

	// Store the new state
	_state = {
		_id: _id,
		owner: owner,
		root: owner === 'e' ? 'employee' : 'employer'
	}

	// Start tracking the state
	TwoWay.track('messaging', _id, _message);

	// Fetch the conversations
	Rest.read('main', `${_state.root}/conversations`, (_state.owner === 'e') ? {} : {
		employer: _state._id
	}).done(res => {

		// If there's an error
		if(res.error && !res._handled) {
			Events.trigger('error', res.error);
		}

		// If there's data
		if(res.data) {

			// Store the data
			_state['conversations'] = res.data;

			// Notify anyone of conversation changes
			_notifyConvos();

			// Calculate the unread count
			_calculateUnread();
		}
	});

	// If we already had a state
	if(oOldState) {

		// Stop tracking the old state
		TwoWay.untrack('messaging', oOldState._id, _message);
	}
}

/**
 * Stop
 *
 * Stop tracking the ID via the websocket
 *
 * @name stop
 * @access public
 * @returns void
 */
export function stop() {

	// If we have no state, just do nothing
	if(_state === null) {
		return;
	}

	// Stop tracking the old state
	TwoWay.untrack('messaging', _state._id, _message);

	// Clear the state and conversations
	_state = null;
}

/**
 * Subscribe
 *
 * Subscribes to count changes and returns the current data
 *
 * @name subscribe
 * @access public
 * @param String name The name to subscribe to
 * @param Function callback The callback to register for future updates
 * @return void
 */
export function subscribe(name, callback) {

	// If the name doesn't exist
	if(!(name in _callbacks)) {
		_callbacks[name] = [];
	}

	// Add the callback to the list
	_callbacks[name].push(callback);

	// If the name is conversations, return what we have
	if(name === 'conversations') {
		return (_state && _state.conversations) || [];
	}

	// If the name is unread, return what we have
	if(name === 'unread') {
		return (_state && _state.unread) || 0;
	}
}

/**
 * Unblock
 *
 * Removes blocking from a conversation
 *
 * @name unblock
 * @access public
 * @param String _id The ID of the conversation
 * @param Function success Optional, a callback to run after successful unblock
 * @returns void
 */
export function unblock(_id, success=null) {

	// Send the message to the server
	Rest.update('main', `${_state.root}/conversation/info`, {
		_id: _id,
		blocked: false
	}).done(res => {
		if(res.error && !res._handled) {
			Events.trigger('error', res.error);
		}
		if(res.data) {
			update({
				name: 'info',
				conversation: _id,
				data: {
					[`${_state.root}_info`]: {
						blocked: false
					}
				}
			});

			// If we have a callback
			if(success) {
				success();
			}
		}
	});
}

/**
 * Ubsubscribe
 *
 * Removes a callback from the list of who gets notified on changes
 *
 * @name ubsubscribe
 * @access public
 * @param String name The name to unsubscribe from
 * @param Function callback The callback to remove
 * @return void
 */
export function unsubscribe(name, callback) {

	// If the name doesn't exist
	if(!(name in _callbacks)) {
		return;
	}

	// Get the index of the callback
	let i = _callbacks[name].indexOf(callback);

	// If it exists, cut it out
	if(i > -1) {
		_callbacks[name].splice(i, 1);

		// If there's nothing left
		if(_callbacks[name].length === 0) {
			delete _callbacks[name];
		}
	}
}

/**
 * Update
 *
 * Updates info on a conversation
 *
 * @name update
 * @access public
 * @param Object msg The message with the update info
 * @returns void
 */
export function update(msg) {

	// If we have no state, just do nothing
	if(_state === null) {
		return;
	}

	// Find the conversation
	let i = afindi(_state.conversations, '_id', msg.conversation);

	// If it's found
	if(i > -1) {

		// Marge the changes to the convo
		merge(_state.conversations[i], msg.data);

		// Notify
		_notifyConvo(msg);
		_notifyConvos();

		// Calculate unread
		_calculateUnread();
	}
}

// Track page visibility
PageVisibility.subscribe((property, state) => {

	// If we have become visible
	if(state === 'visible') {

		// If we have a previous messaging state
		if(_prev !== null) {

			// Start the process
			start(_prev._id, _prev.owner);

			// Clear the previous state
			_prev = null;
		}
	}

	// Else if we have become hidden
	else if(state === 'hidden') {

		// Store the state for when we come back
		_prev = _state;

		// Stop the process
		stop();
	}
});

// Default export
const Messaging = {
	block: block,
	hide: hide,
	read: read,
	rename: rename,
	send: send,
	start: start,
	stop: stop,
	subscribe: subscribe,
	unblock: unblock,
	unsubscribe: unsubscribe,
	update: update
}
export default Messaging;

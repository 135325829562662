/**
 * Create By Locale
 *
 * Handles creating a new record that stores strings by locale
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-16
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import { clone, ucfirst } from 'shared/generic/tools';

/**
 * Create By Locale
 *
 * Displays a form to add a new string, one for each active locale
 *
 * @name CreateByLocale
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function CreateByLocale(props) {

	// State
	let [errors, errorsSet] = useState(false);
	let [data, dataSet] = useState({
		active: false,
		locales: {}
	});

	// Active changed
	function activeChanged(checked) {
		dataSet(data => {
			data.active = checked;
			return clone(data);
		});
	}

	// Locale changed
	function localeChanged(locale, value) {
		dataSet(data => {
			data.locales[locale] = value;
			return clone(data);
		});
	}

	// Called to create the record
	function submit() {

		// Reset errors
		errorsSet(false);

		// Send the data to the server
		Rest.create('admin', props.noun, data).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 1001) {
					errorsSet(res.error.msg.map(l => l[0] + ' is ' + l[1]))
				} else {
					errorsSet([JSON.stringify(res.error)]);
				}
			}

			// If we're successful
			if(res.data) {

				// Let the parent know
				props.onSuccess(res.data);
			}
		});
	}

	// Render
	return (
		<Paper className="create">
			<Typography className="title">Create New {ucfirst(props.noun)}</Typography>
			{errors &&
				<Box className="errors">{errors.map(s => <Typography>{s}</Typography>)}</Box>
			}
			<Grid container spacing={2}>
				{props.locales.map(o =>
					<Grid item xs={12} sm={6} md={4} lg={3} key={o._id}>
						<Typography>{o.name}</Typography>
						<TextField
							onChange={ev => localeChanged(o._id, ev.target.value)}
							type="text"
							value={data.locales[o._id] || ''}
							variant="outlined"
						/>
					</Grid>
				)}
				<Grid item xs={12} sm={6} md={4} lg={3}>
					<FormControlLabel
						control={<Checkbox
									checked={data.active}
									color="primary"
									onChange={ev => activeChanged(ev.target.checked)}
								/>}
						label="Active"
					/>
				</Grid>
			</Grid>
			<Box className="actions">
				<Button color="secondary" onClick={props.onCancel} variant="contained">Cancel</Button>
				<Button color="primary" onClick={submit} variant="contained">Create</Button>
			</Box>
		</Paper>
	);
}

// Valid props
CreateByLocale.propTypes = {
	locales: PropTypes.array.isRequired,
	noun: PropTypes.string.isRequired,
	onCancel: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired
}

/**
 * Signups: Signups
 *
 * Handles signup stats page
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-11
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

/**
 * Signups
 *
 * Displays a button to allow clearing all existing matches
 *
 * @name Signups
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Signups(props) {

	// State
	let [stats, statsSet] = useState(false);

	// Load effect
	useEffect(() => {
		Rest.read('admin', 'stats').done(res => {
			statsSet(res.data);
		});
	}, []);

	// Render
	return (
		<Box className="stats padding">
			<Box className="header flexColumns">
				<Typography className="title flexGrow">Signup Stats</Typography>
			</Box>
			{stats === false ?
				<Typography>Loading...</Typography>
			:
				<Box>
					<Typography>Signups: {stats.signups}</Typography>
					<Typography>Incomplete: {stats.incomplete}</Typography>
					<Typography>Employees: {stats.employees}</Typography>
					<Typography>Establishments: {stats.employers}</Typography>
					<Typography>Open Postings: {stats.postings}</Typography>
				</Box>
			}
		</Box>
	);

}

// Valid props
Signups.propTypes = {
	mobile: PropTypes.bool.isRequired
}


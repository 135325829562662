/**
 * Match
 *
 * Displays the possible matches for the employee based on the distance
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-27
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

// Composite components
import Popup from 'components/composites/Popup';
import PostingView from 'components/composites/employer/posting/View';
import SliderLabel from 'components/composites/SliderLabel';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { clone, min, parseQuery, safeLocalStorage } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

/**
 * Match
 *
 * Handles main employee page
 *
 * @name Match
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Match(props) {

	// State
	let [distance, distanceSet] = useState(parseInt(safeLocalStorage('employeeMatchDistance', '10')));
	let [first, firstSet] = useState(true);
	let [last, lastSet] = useState(null);
	let [matches, matchesSet] = useState([]);
	let [message, messageSet] = useState(false);
	let [options, optionsSet] = useState(false);
	let [type, typeSet] = useState(safeLocalStorage('employeeMatchType', ''));

	// Distance effect
	useEffect(() => {

		// Init the public ID
		let sPublic = null;

		// If we haven't check for a public ID yet (first load)
		if(first) {

			// Get the query string if there is one
			let oQuery = parseQuery(window.location.search.substring(1));

			// If we got a public ID
			if(oQuery.id) {
				sPublic = oQuery.id;
			}

			// Don't run this again
			firstSet(false);
		}

		// Fetch the matches
		matchesFetch(sPublic);

	// eslint-disable-next-line
	}, [distance, type]);

	// Fetch matches
	function matchesFetch(public_id) {

		// Store the new distance and type
		localStorage.setItem('employeeMatchDistance', distance.toString());
		localStorage.setItem('employeeMatchType', type);

		// Init the data with the distance
		let oData = {distance: distance}

		// If we got a specific filter type
		if(type !== '') {
			oData.type = type;
		}

		// If we got a public posting ID passed
		if(public_id) {
			oData['public_id'] = public_id;
		}

		// Get the matches from the server
		Rest.read('main', 'employee/search/new', oData).done(res => {
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			} else if(res.data) {

				// Go through each posting
				for(let o of res.data) {

					// Add the background image
					o.background = (o.employer.photos && o.employer.photos.length) ?
					o.employer.photos[0].url :
					process.env.REACT_APP_NO_PHOTO_URL;

					// Set the share
					let sDate = o.times.type === 'gig' ?
								min(o.times.shifts.map(o => o.starts)).substr(0,16):
								o.times.starts;
					o.share = {
						url: `https://${window.location.host}/posting/${o._id}`,
						text: `${props.info.professions[o.profession][props.locale]}, ${o.salary_amount}/${translate('common', 'salary_type_' + o.salary_type, props.locale)}, ${sDate}`
					}
				}

				// Set the matches
				matchesSet(res.data);
			}
		});
	}

	// Remove the latest posting so we can load the next
	function next() {
		let lMatches = clone(matches);
		lastSet(lMatches.shift());
		matchesSet(lMatches);
	}

	// Reject the posting
	function swipe(type) {

		// If we got a left swipe
		if(type === 'left') {

			// Send the choice to the server
			Rest.create('main', 'employee/match', {
				choice: 2,
				posting_id: matches[0]._id
			}).done(res => {
				if(res.error && !res._handled) {
					Events.trigger('error', res.error);
				} else if(res.data) {

					// Show reject message
					messageSet(translate('common', 'match_reject', props.locale, {name: matches[0].employer.name}));

					// Load the next job posting
					next();
				}
			});
		}

		// Else if we got a right swipe
		else if(type === 'right') {

			// Send the choice to the server
			Rest.create('main', 'employee/match', {
				choice: 1,
				posting_id: matches[0]._id
			}).done(res => {
				if(res.error && !res._handled) {
					Events.trigger('error', res.error);
				} else if(res.data) {

					// Fetch the accept message
					let sMsg = translate('common', 'match_accept', props.locale, {name: matches[0].employer.name});

					// Load the next job posting
					next();

					// If the employer also matched
					if(res.data.posting_choice === 1) {
						sMsg += '\n' + translate('common', 'new_match', props.locale);
					}

					// Set the message popup
					messageSet(sMsg);
				}
			});
		}
	}

	// Undo the last action
	function undo() {

		// Let the server know to remove the last match change
		Rest.delete('main', 'employee/match', {
			posting_id: last._id
		}).done(res=> {
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			} else if(res.data) {

				// Add back the last match
				let lMatches = clone(matches);
				lMatches.unshift(last);
				matchesSet(lMatches);

				// Remove last and message popup
				lastSet(null);
				messageSet(false);
			}
		});
	}

	// Render
	return (
		<Box className="singlePage flexRows">
			<Box className="options_button" onClick={ev => optionsSet(true)}>
				<i className="fas fa-filter" />
			</Box>
			<Drawer anchor="top" open={options} onClose={() => optionsSet(false)}>
				<Grid container className="options_content">
					<Grid item xs={6} className="options_content_type">
						<Box className="field">
							<Select
								native
								onChange={ev => typeSet(ev.target.value)}
								placeholder={translate('common', 'times', props.locale)}
								value={type}
								variant="outlined"
							>
								<option value="">{translate('common', 'all', props.locale)}</option>
								<option value="seasonal">{translate('common', 'times_seasonal', props.locale)}</option>
								<option value="part">{translate('common', 'times_part', props.locale)}</option>
								<option value="full">{translate('common', 'times_full', props.locale)}</option>
								<option value="gig">{translate('common', 'times_gig', props.locale)}</option>
							</Select>
						</Box>
					</Grid>
					<Grid item xs={6} className="options_content_slider">
						<Slider
							aria-labelledby="distance-slider"
							getAriaValueText={value => value + 'km'}
							marks={[
								{value: 1, label: '1'},
								{value: 5, label: '5'},
								{value: 10, label: '10'},
								{value: 20, label: '20'},
								{value: 30, label: '30'},
								{value: 40, label: '40'},
								{value: 50, label: '50'}
							]}
							max={50}
							min={1}
							onChange={(ev, val) => distanceSet(val)}
							step={1}
							value={distance}
							valueLabelDisplay="auto"
							ValueLabelComponent={SliderLabel}
						/>
					</Grid>
				</Grid>
			</Drawer>
			<Box className="flexGrow">
				<Box className="container md center padding">
					<Typography><br /><br /></Typography>
					<Typography>{translate('employee', 'no_possible_matches', props.locale)}</Typography>
					<Typography>{translate('employee', 'change_distance', props.locale)}</Typography>
				</Box>
				{matches.length > 1 &&
					<PostingView
						key={matches[1]._id}
						locale={props.locale}
						info={props.info}
						mobile={props.mobile}
						value={matches[1]}
					/>
				}
				{matches.length > 0 &&
					<PostingView
						key={matches[0]._id}
						locale={props.locale}
						info={props.info}
						mobile={props.mobile}
						share={matches[0].share}
						swipe={swipe}
						value={matches[0]}
					/>
				}
			</Box>
			{message &&
				<Popup
					action={<Button color="secondary" size="small" onClick={undo}>UNDO</Button>}
					content={message}
					onClose={() => messageSet(false)}
				/>
			}
		</Box>
	);
}

// Valid props
Match.propTypes = {
	locale: PropTypes.string.isRequired,
	info: PropTypes.object.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}

/**
 * Times
 *
 * For creating or editing the posting time type
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-06-06
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

// Local components
import Full from './Full';
import Gig from './Gig';
import Part from './Part';
import Seasonal from './Seasonal';

// Shared generic modules
import { clone, uuidv4 } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

// Constants
const CHILDREN = {
	full: Full,
	gig: Gig,
	part: Part,
	seasonal: Seasonal
}

/**
 * Times
 *
 * Handles times field
 *
 * @name Times
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Times(props) {

	// Called when any field is changed
	function typeChange(ev) {

		// Init the new value
		let oValue = {type: ev.target.value};

		// Switch based on the new type
		switch(oValue.type) {

			// If we are now full time
			case 'full':

				// If we were a gig
				if(props.value.type === 'gig') {
					oValue.starts = props.value.shifts[0].starts.substr(0, 10);
					oValue.hours = {min: 30};
				} else {
					oValue.starts = props.value.starts;
					oValue.hours = {
						min: props.value.hours.min < 30 ? 30 : props.value.hours.min
					};
					if(props.value.hours.max !== undefined) {
						oValue.hours.max = props.value.hours.max < 30 ? 30 : props.value.hours.max;
					}
				}
				break;

			// If we are now a gig
			case 'gig':
				oValue.shifts = [{key: uuidv4(), starts: props.value.starts + ' 12:00:00', hours: {min: 8}}];
				break;

			// If we are now part time
			case 'part':

				// If we were a gig
				if(props.value.type === 'gig') {
					oValue.starts = props.value.shifts[0].starts.substr(0, 10);
					oValue.hours = {min: 20};
				} else {
					oValue.starts = props.value.starts;
					oValue.hours = {
						min: props.value.hours.min > 29 ? 29 : props.value.hours.min
					};
					if(props.value.hours.max !== undefined) {
						oValue.hours.max = props.value.hours.max > 29 ? 29 : props.value.hours.max;
					}
				}
				break;

			// If we are now seasonal
			case 'seasonal':

				// If we were a gig
				if(props.value.type === 'gig') {
					oValue.starts = props.value.shifts[0].starts.substr(0, 10);
					oValue.ends = oValue.starts;
					oValue.hours = {min: 20};
				} else {
					oValue.starts = props.value.starts;
					oValue.ends = props.value.starts;
					oValue.hours = {min: props.value.hours.min};
					if(props.value.hours.max !== undefined) {
						oValue.hours.max = props.value.hours.max;
					}
				}
				break;

			// no default
		}

		// Let the parent know
		props.onChange(oValue);
	}

	// Child
	let Child = CHILDREN[props.value.type];

	// Render
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={6} className="field">
				<Typography>{translate('common', 'times', props.locale)}</Typography>
				<FormControl
					error={props.errors.type ? true : false}
					variant={props.variant}
				>
					<Select
						native
						onChange={typeChange}
						value={props.value.type}
						inputProps={{
							id: 'postingTimes'
						}}
					>
						{props.mode === 'create' &&
							<option value=""></option>
						}
						<option value="seasonal">{translate('common', 'times_seasonal', props.locale)}</option>
						<option value="part">{translate('common', 'times_part', props.locale)}</option>
						<option value="full">{translate('common', 'times_full', props.locale)}</option>
						<option value="gig">{translate('common', 'times_gig', props.locale)}</option>
					</Select>
					{props.errors.type &&
						<FormHelperText>{translate('errors', props.errors.type, props.locale)}</FormHelperText>
					}
				</FormControl>
			</Grid>
			<Grid item xs={12} sm={6} className="field">
				<Child
					errors={props.errors.shifts || {}}
					locale={props.locale}
					onChange={val => props.onChange(clone(val))}
					value={props.value}
					variant={props.variant}
				/>
			</Grid>
		</Grid>
	);
}

// Valid props
Times.propTypes = {
	errors: PropTypes.object.isRequired,
	locale: PropTypes.string.isRequired,
	mode: PropTypes.oneOf(['create', 'edit']),
	onChange: PropTypes.func.isRequired,
	value: PropTypes.object.isRequired,
	variant: PropTypes.string
}

// Default props
Times.defaultProps = {
	variant: 'standard'
}
/**
 * Conversations
 *
 * Handles displaying a list of conversations
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-21
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

// Composite components
import ConversationItem from 'components/composites/ConversationItem';

// Data modules
import translate from 'data/translate';

// Constants
const PATHS = {
	e: {
		root: 'employee',
		info: 'employee_info',
		oinfo: 'employer_info'
	},
	r: {
		root: 'employer',
		info: 'employer_info',
		oinfo: 'employee_info'
	}
}

/**
 * Conversations
 *
 * Displays conversations in a list
 *
 * @name Conversations
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Conversations(props) {

	// State
	let [blocked, blockedSet] = useState(false);
	let [hidden, hiddenSet] = useState(false);
	let [list, listSet] = useState([]);
	let [menuAnchor, menuAnchorSet] = useState(null);

	// Hidden/List effect
	useEffect(() => {

		// If we have values
		if(props.value) {

			// If we are showing hidden and blocked
			if(hidden && blocked) {
				listSet(props.value);
			}

			// Else, if we are filtering out one or the other
			else {
				let lList = [];
				for(let o of props.value) {
					if((!o[PATHS[props.owner].info].hidden || hidden) && (!o[PATHS[props.owner].info].blocked || blocked)) {
						lList.push(o);
					}
				}
				listSet(lList);
			}
		}

	}, [blocked, hidden, props.owner, props.value]);

	// Render the component
	return (
		<Box className="conversation flexRows">
			<Box className="conversation_display flexColumns">
				<Box className="flexGrow">
					<Typography>{translate('messages', 'conversations', props.locale)}</Typography>
				</Box>
				<Box className="flexStatic">
					<i aria-controls="convo-menu" aria-haspopup="true" className="menu fas fa-ellipsis-v" onClick={ev => menuAnchorSet(ev.target)} />
					<Menu
						id="convo-menu"
						anchorEl={menuAnchor}
						keepMounted
						open={Boolean(menuAnchor)}
						onClose={ev => menuAnchorSet(null)}
					>
						<MenuItem className="conversations_menu_item flexColumns" onClick={() => blockedSet(val => !val)}>
							<Typography className="flexGrow">{translate('messages', 'blocked', props.locale)}</Typography>
							{blocked &&
								<i className="fas fa-check" />
							}
						</MenuItem>
						<MenuItem className="conversations_menu_item flexColumns" onClick={() => hiddenSet(val => !val)}>
							<Typography className="flexGrow">{translate('messages', 'hidden', props.locale)}</Typography>
							{hidden &&
								<i className="fas fa-check" />
							}
						</MenuItem>
					</Menu>
				</Box>
			</Box>
			<Box className="flexGrow">
				<List className="conversations" disablePadding>
					<Divider />
					{list.map(o =>
						<ConversationItem
							key={o._id}
							locale={props.locale}
							mobile={props.mobile}
							owner={props.owner}
							value={o}
						/>
					)}
				</List>
			</Box>
		</Box>
	);
}

// Valid props
Conversations.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	owner: PropTypes.oneOf(['e', 'r']).isRequired,
	value: PropTypes.array.isRequired
}

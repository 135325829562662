/**
 * Messaging: List
 *
 * Displays the list of conversations
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-19
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Composite components
import Conversations from 'components/composites/Conversations'

// Data modules
import Messaging from 'data/messaging';
import translate from 'data/translate';

/**
 * List
 *
 * Handles employee conversations
 *
 * @name List
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function List(props) {

	// State
	let [convos, convosSet] = useState(null);

	// Load effect
	useEffect(() => {
		convosSet(
			Messaging.subscribe('conversations', convosSet)
		);
		return () => {
			Messaging.unsubscribe('conversations', convosSet);
		}
	}, [props.user]);

	// If we are still loading convos
	if(convos === null) {
		return (
			<Box className="padding">
				<Typography>{translate('common', 'loading', props.locale)}</Typography>
			</Box>
		);
	}

	// If there are no conversations yet
	if(convos.length === 0) {
		return (
			<Box className="padding">
				<Typography>{translate('common', 'no_convos', props.locale)}</Typography>
			</Box>
		);
	}

	// Render
	return (
		<Conversations
			owner="e"
			value={convos}
			{...props}
		/>
	);
}

// Valid props
List.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}

/**
 * Rest Init
 *
 * Initialises Rest module and adds callbacks
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-04-02
 */

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';

// Init the rest services
Rest.init(process.env.REACT_APP_REST_DOMAIN, {

	after: (method, url, data, opts) => {
		Events.trigger('loader_hide');
	},

	before: (method, url, data, opts) => {
		Events.trigger('loader_show');
	},

	cookie: window.location.hostname,

	error: xhr => {

		// If we got a 401, let everyone know we signed out
		if(xhr.status === 401) {
			Events.trigger('signedOut');
		} else {
			Events.trigger('error',
				'Unable to connect to ' + process.env.REACT_APP_REST_DOMAIN +
				': ' + xhr.statusText +
				' (' + xhr.status + ')');
		}
	},

	success: res => {

		// If we got an error
		if(res.error) {

			// What error is it?
			switch(res.error.code) {

				// No internet
				case -1:

					// Notify the user
					Events.trigger('error', 'Tu es hors ligne / You are offline');
					res._handled = true;
					break;

				// No Session
				case 102:

					// Trigger signout
					Events.trigger("signout");
					res._handled = true;
					break;

				case 207:

					// Notify the user
					Events.trigger('error', 'La demande de ' + res.error.msg + ' a échoué. Le webmestre a été prévenu.\nRequest to ' + res.error.msg + ' failed. Webmaster has been notified.');
					res._handled = true;
					break;

				// no default
			}
		}
	}
});

// Hide the loader
//LoaderHide();

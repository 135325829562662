/**
 * How To
 *
 * Displays the how to image
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-11-19
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// Data modules
import translate from 'data/translate';

/**
 * HowTo
 *
 * Handles displaying the how to info graphic
 *
 * @name HowTo
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function HowTo(props) {

	// Hooks
	let history = useHistory();

	// Render
	return (
		<Box className="flexRows" style={{alignItems: 'center'}}>
			<Box className="center flexGrow">
				<img src={`/images/How-To-${props.locale}.png`} alt="how-to-graphic" style={{display: 'block', height: '100%', width: 'auto'}} />
			</Box>
			<Box className="center padding flexStatic">
				<Button color="primary" variant="contained" onClick={ev => history.push('/')}>{translate('common', 'continue', props.locale)}</Button>
			</Box>
		</Box>
	)
}

HowTo.propTypes = {
	locale: PropTypes.string.isRequired
}
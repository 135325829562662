/**
 * Admin Users Employers Employer component
 *
 * Handles displaying the specific user info for the employee/employer
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-11-23
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Material UI
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

// Local components
import Postings from './Postings';
import User from './User';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { nicePhone } from 'shared/generic/tools';

/**
 * Employer
 *
 * Handles displaying employer specific info
 *
 * @name Employer
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Employer(props) {

	// State
	let [confirm, confirmSet] = useState(false);
	let [tab, tabSet] = useState(0);

	// Approve
	function approve() {

		// Mark the employer as approved
		Rest.update('admin', 'employer/approve', {
			employer_id: props.value._id
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {

				// Refresh the page
				Events.trigger('admin_user_search_remove');
			}
		});
	}

	// Remove the employer account
	function remove() {

		// Remove the employer record
		Rest.delete('admin', 'employer', {
			_id: props.value._id
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {

				// Trigger removing the row
				Events.trigger('admin_user_search_remove', props.value._id);
			}
		});
	}

	// Render
	return (
		<Box>
			<AppBar position="static" color="default">
				<Tabs
					indicatorColor="primary"
					onChange={(ev, tab) => tabSet(tab)}
					value={tab}
					variant="fullWidth"
				>
					<Tab label="Employer Account" />
					<Tab label="Job Postings" />
					<Tab label="User Account" />
				</Tabs>
			</AppBar>
			<Box>
				{tab === 0 &&
					<Box className="padding">
						<Typography variant="h3">{props.value.name}</Typography>
						<Typography><strong>{props.info['establishmentTypes'][props.value.type]['en-CA']}</strong></Typography>
						<br />
						{props.value.bio && props.value.bio.length > 0 && props.value.bio.split('\n').map((s,i) => <Typography key={i}>{s}</Typography>)}
						<br />
						<Typography>{props.value.address.line1}{props.value.address.line2 && (' ,' + props.value.address.line2)}</Typography>
						<Typography>{props.value.address.city}, {props.value.address.division}</Typography>
						<Typography>{props.value.address.country}, {props.value.address.postal_code}</Typography>
						<br />
						<Typography>{nicePhone(props.value.phone)}</Typography>
						<br />
						{(props.value.photos && props.value.photos.length > 0) &&
							<a href={props.value.photos[0].url} target="_blank" rel="noreferrer">
								<img src={props.value.photos[0].url} alt="employer_photo" />
							</a>
						}
						<br />
						<Box className="actions">
							{props.approve &&
								<Button variant="contained" onClick={approve}>Approve</Button>
							}
							<Button className="red" variant="contained" onClick={() => confirmSet(true)}>Delete</Button>
						</Box>
						{confirm &&
							<Dialog
								onClose={() => confirmSet(false)}
								open={true}
							>
								<DialogTitle>Confirm Delete of Employer</DialogTitle>
								<DialogContent>
									<Typography>Please confirm you want to delete the Employer account for <strong>{props.value.name}</strong>.</Typography>
									<Typography>This will not delete the primary users account, only the Employer data associated with it. If the user is an enterprise user with multiple employer locations none of them will be deleted.</Typography>
								</DialogContent>
								<DialogActions>
									<Button className="red" variant="contained" onClick={remove}>Delete</Button>
								</DialogActions>
							</Dialog>
						}
					</Box>
				}
				{tab === 1 &&
					<Postings
						employer={props.value._id}
						info={props.info}
					/>
				}
				{tab === 2 &&
					<User
						_id={props.value.user}
						locales={props.locales}
					/>
				}
			</Box>
		</Box>
	);
}

// Valid props
Employer.propTypes = {
	approve: PropTypes.bool,
	info: PropTypes.object.isRequired,
	locales: PropTypes.array.isRequired,
	value: PropTypes.object.isRequired
}

// Default props
Employer.defaultProps = {
	approve: false
}
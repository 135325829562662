/**
 * Times: Seasonal
 *
 * For creating or editing values associated with a seasonal posting
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2022-01-31
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Local components
import Hours from './Hours';

// Shared generic modules
import { increment, iso } from 'shared/generic/dates';
import { clone } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

/**
 * Seasonal
 *
 * Handles the data for a full-time posting
 *
 * @name Seasonal
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Seasonal(props) {

	// Called when any date changes
	function change(field, val) {

		// Modify the index
		props.value[field] = val;

		// Tell the parent
		props.onChange(clone(props.value));
	}

	//
	function checkLimits(ev) {

		// Get the name and value
		let sName = ev.target.name;
		let sDate = ev.target.value;

		// If we were on starts
		if(sName === 'starts') {

			// If the date is after the end date, set it to one less than end
			if(sDate > props.value.ends) {
				sDate = iso(increment(-1, props.value.ends, false), false, false);
			}
		}

		// Else, if it's ends
		else {

			// If the date is before the start date, set it to one more than start
			if(sDate < props.value.starts) {
				sDate = iso(increment(1, props.value.starts, false), false, false);
			}
		}

		// Set the field and tell the parent
		props.value[sName] = sDate;
		props.onChange(clone(props.value));
	}

	// Render
	return (
		<React.Fragment>
			<Box className="flexColumns">
				<Box className="field flexGrow">
					<Typography>{translate('common', 'starts', props.locale)}</Typography>
					<TextField
						error={props.errors.starts ? true : false}
						helperText={props.errors.starts ? translate('errors', props.errors.starts, props.locale) : ''}
						inputProps={{min: iso(new Date(), false)}}
						name="starts"
						onBlur={checkLimits}
						onChange={ev => change('starts', ev.target.value)}
						type="date"
						value={props.value.starts || ''}
						variant={props.variant}
					/>
				</Box>
				<Box className="field flexGrow">
					<Typography>{translate('common', 'ends', props.locale)}</Typography>
					<TextField
						error={props.errors.ends ? true : false}
						helperText={props.errors.ends ? translate('errors', props.errors.ends, props.locale) : ''}
						inputProps={{min: iso(new Date(), false)}}
						name="ends"
						onBlur={checkLimits}
						onChange={ev => change('ends', ev.target.value)}
						type="date"
						value={props.value.ends || ''}
						variant={props.variant}
					/>
				</Box>
			</Box>
			<Hours
				errors={(props.errors && props.errors.hours) || {}}
				locale={props.locale}
				onChange={val => change('hours', val)}
				title={translate('common', 'weekly_hours', props.locale)}
				value={props.value.hours || 0}
				variant={props.variant}
			/>
		</React.Fragment>
	);
}

// Valid props
Seasonal.propTypes = {
	errors: PropTypes.object.isRequired,
	locale: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.object.isRequired,
	variant: PropTypes.string
}

// Default props
Seasonal.defaultProps = {
	variant: 'standard'
}
/**
 * Address
 *
 * Handles entering address information
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-19
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared generic modules
import { clone, omap } from 'shared/generic/tools';

// Shared data
import Countries from 'shared/data/countries';

// Data modules
import translate from 'data/translate';

/**
 * Address
 *
 * Displays a form to add or edit an address
 *
 * @name Address
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Address(props) {

	// Capture changes
	function change(ev) {
		let oValues = clone(props.value);
		if(ev.target.value.trim() === '') {
			delete oValues[ev.target.name];
		} else {
			oValues[ev.target.name] = ev.target.value;
		}
		props.onChange(oValues);
	}

	// Capture country change
	function changeCountry(ev) {

		// Clone the current values
		let oValues = clone(props.value);

		// Set the country
		oValues['country'] = ev.target.value;

		// If the country is one with divisions, set to the first one
		if(Countries[oValues['country']].divisions) {
			oValues['division'] = Countries[oValues['country']].divisions[Object.keys(Countries[oValues['country']].divisions)[0]]
		}
		// Else, remove the divisions value completely
		else {
			delete oValues['division'];
		}

		// Pass to the parent
		props.onChange(oValues);
	}

	// Capture postal code change
	function changePostalCode(ev) {
		ev.target.value = ev.target.value.toUpperCase();
		change(ev);
	}

	// Render the component
	return (
		<Box className="address">
			<Grid container spacing={2}>
				{(props.fields.length === 0 || props.fields.includes('line1')) &&
					<Grid item xs={12} sm={6} className="field">
						<Typography>{translate('common', 'address1', props.locale)}</Typography>
						<TextField
							error={props.errors.line1 ? true : false}
							helperText={props.errors.line1 ? translate('errors', props.errors.line1, props.locale) : ''}
							name="line1"
							onChange={change}
							type="text"
							value={props.value.line1 || ''}
							variant={props.variant}
						/>
					</Grid>
				}
				{(props.fields.length === 0 || props.fields.includes('line2')) &&
					<Grid item xs={12} sm={6} className="field">
						<Typography>{translate('common', 'address2', props.locale)}</Typography>
						<TextField
							error={props.errors.line2 ? true : false}
							helperText={props.errors.line2 ? translate('errors', props.errors.line2, props.locale) : ''}
							name="line2"
							onChange={change}
							type="text"
							value={props.value.line2 || ''}
							variant={props.variant}
						/>
					</Grid>
				}
				{(props.fields.length === 0 || props.fields.includes('city')) &&
					<Grid item xs={12} sm={6} className="field">
						<Typography>{translate('common', 'city', props.locale)}</Typography>
						<TextField
							error={props.errors.city ? true : false}
							helperText={props.errors.city ? translate('errors', props.errors.city, props.locale) : ''}
							name="city"
							onChange={change}
							type="text"
							value={props.value.city || ''}
							variant={props.variant}
						/>
					</Grid>
				}
				{(props.fields.length === 0 || props.fields.includes('division')) &&
					Countries[props.value.country].divisions !== null &&
					<Grid item xs={12} sm={6} className="field">
						<Typography>{translate('common', 'division', props.locale)}</Typography>
						<FormControl
							error={props.errors.division ? true : false}
							variant={props.variant}
						>
							<Select
								name="division"
								native
								onChange={change}
								value={props.value.division}
							>
								{omap(Countries[props.value.country].divisions, (name,code) =>
									<option key={code} value={code}>{name}</option>
								)}
							</Select>
							{props.errors.division &&
								<FormHelperText>{translate('errors', props.errors.division, props.locale)}</FormHelperText>
							}
						</FormControl>
					</Grid>
				}
				{(props.fields.length === 0 || props.fields.includes('country')) &&
					<Grid item xs={12} sm={6} className="field">
						<Typography>{translate('common', 'country', props.locale)}</Typography>
						<FormControl
							error={props.errors.country ? true : false}
							variant={props.variant}
						>
							<Select
								name="country"
								native
								onChange={changeCountry}
								value={props.value.country}
							>
								{omap(Countries, (o,k) =>
									<option key={k} value={k}>{o.name}</option>
								)}
							</Select>
							{props.errors.country &&
								<FormHelperText>{translate('errors', props.errors.country, props.locale)}</FormHelperText>
							}
						</FormControl>
					</Grid>
				}
				{(props.fields.length === 0 || props.fields.includes('postal_code')) &&
					<Grid item xs={12} sm={6} className="field">
						<Typography>{translate('common', 'postal_code', props.locale)}</Typography>
						<TextField
							error={props.errors.postal_code ? true : false}
							helperText={props.errors.postal_code ? translate('errors', props.errors.postal_code, props.locale) : ''}
							name="postal_code"
							onChange={changePostalCode}
							type="text"
							value={props.value.postal_code || ''}
							variant={props.variant}
						/>
					</Grid>
				}
			</Grid>
		</Box>
	);
}

// Valid props
Address.propTypes = {
	errors: PropTypes.object,
	fields: PropTypes.array,
	locale: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.object,
	variant: PropTypes.string
}

// Default props
Address.defaultProps = {
	errors: {},
	fields: [],
	value: {},
	variant: 'standard'
}

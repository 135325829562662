/**
 * MenuDrawer
 *
 * Handles displaying the menu items
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-05-21
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

// Material UI Icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { afindi, clone, safeLocalStorageJSON } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

/**
 * MenuDrawer
 *
 * Handles the form to sign into the site
 *
 * @name login
 * @access private
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function MenuDrawer(props) {

	// State
	let [employerEl, employerElSet] = useState(null);
	let [employerName, employerNameSet] = useState('');
	let [localeEl, localeElSet] = useState(null);
	let [subs, subsSet] = useState(safeLocalStorageJSON('submenu', {}))

	// Hooks
	let location = useLocation();

	// Load effect
	useEffect(() => {
		if(props.user.employer_current) {
			employerNameSet(val => {
				// Find the employer in the list
				let iIndex = afindi(props.user.employer, '_id', props.user.employer_current);

				// If we find one
				if(iIndex > -1) {
					return props.user.employer[iIndex].name;
				} else {
					return '';
				}
			});
		} else {
			employerNameSet('');
		}
	// eslint-disable-next-line
	}, [props.user.employer_current]);

	// Sign Out
	function signOut() {

		// Let the server know
		Rest.create('main', 'signout', {}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {

				// Reset the session
				Rest.session(null);

				// Trigger the signedOut event
				Events.trigger('signedOut');

				// Close the menu
				props.onClose();
			}
		});
	}

	// Toggles sub-menus and stores the state in local storage
	function subMenuToggle(name) {

		// Clone the current subs
		let oSubs = clone(subs);

		// If the name exists, delete it
		if(oSubs[name]) {
			delete oSubs[name];
		}
		// Else, add it
		else {
			oSubs[name] = true;
		}

		// Store the value in storage
		localStorage.setItem('submenu', JSON.stringify(oSubs));

		// Update the state
		subsSet(oSubs);
	}

	// Render
	return (
		<Drawer
			anchor="right"
			id="menu"
			open={true}
			onClose={ev => props.onClose()}
			variant="temporary"
		>
			<Box className="menu_content">
				<List className="top">
					<Link to={props.root} onClick={() => props.onClose()}>
						<ListItem button selected={['/', '/employee', '/employer'].indexOf(location.pathname) > -1}>
							<ListItemIcon><i className="fas fa-concierge-bell" /></ListItemIcon>
							<ListItemText primary={translate('common', 'home', props.locale)} />
						</ListItem>
					</Link>
					<Divider />
					<Link to="/account" onClick={() => props.onClose()}>
						<ListItem button selected={location.pathname === "/account"}>
							<ListItemIcon><i className="fas fa-envelope" /></ListItemIcon>
							<ListItemText primary={translate('common', 'account', props.locale)} />
						</ListItem>
					</Link>
					<Divider />
					{(props.user.employee || props.user.employer) &&
						<React.Fragment>
							<Link to={props.root + '/profile'} onClick={() => props.onClose()}>
								<ListItem button selected={location.pathname === (props.root + '/profile')}>
									<ListItemIcon><i className="fas fa-user-circle" /></ListItemIcon>
									<ListItemText primary={translate('common', 'profile', props.locale)} />
								</ListItem>
							</Link>
							{props.user.enterprise &&
								<ListItem button selected={location.pathname === (props.root + '/profile')}>
									<ListItemIcon></ListItemIcon>
									<ListItemText primary={
										<Box className="flexColumns">
											<Box
												aria-controls="menu-employer"
												aria-haspopup="true"
												className="enterprise flexGrow flexColumns"
												onClick={ev => employerElSet(ev.currentTarget)}
											>
												<Box className="flexGrow">
													{employerName}
												</Box>
												<Box className="flexStatic" style={{paddingRight: '15px'}}>
													<i className="fa-solid fa-caret-down" />
													&nbsp;
												</Box>
											</Box>
											<Box className="flexStatic">
												<Link to="/employer/create" onClick={() => props.onClose()}>
													<i className="fas fa-plus" />
												</Link>
											</Box>
											<Menu
												id="menu-employer"
												anchorEl={employerEl}
												keepMounted
												open={Boolean(employerEl)}
												onClose={ev => employerElSet(null)}
											>
												{props.user && props.user.employer.map(o =>
													<MenuItem
														key={o._id}
														onClick={ev => {
															Events.trigger('employer_current', o._id);
															employerElSet(null);
															props.onClose();
														}}
													>
														{o.name}
													</MenuItem>
												)}
											</Menu>
										</Box>
									} />
								</ListItem>
							}
							<Divider />
						</React.Fragment>
					}
					{props.user.admin &&
						<React.Fragment>
							<Link to="/admin/postings" onClick={() => props.onClose()}>
								<ListItem button selected={location.pathname === '/admin/postings'}>
									<ListItemIcon><i className="fas fa-signs-post" /></ListItemIcon>
									<ListItemText primary="Postings" />
								</ListItem>
							</Link>
							<ListItem button key="Users" onClick={ev => subMenuToggle('users')}>
								<ListItemIcon><i className="fas fa-users" /></ListItemIcon>
								<ListItemText primary="Users" />
								{subs.users ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse in={subs.users || false} timeout="auto" unmountOnExit>
								<List component="div" className="submenu">
									<Link to="/admin/awaiting_approval" onClick={() => props.onClose()}>
										<ListItem button selected={location.pathname === '/admin/awaiting_approval'}>
											<ListItemIcon><i className="fas fa-user" /></ListItemIcon>
											<ListItemText primary="Awaiting Approval" />
										</ListItem>
									</Link>
									<Link to="/admin/incomplete_signups" onClick={() => props.onClose()}>
										<ListItem button selected={location.pathname === '/admin/incomplete_signups'}>
											<ListItemIcon><i className="fas fa-user" /></ListItemIcon>
											<ListItemText primary="Incomplete Signups" />
										</ListItem>
									</Link>
									<Link to="/admin/users" onClick={() => props.onClose()}>
										<ListItem button selected={location.pathname === '/admin/users'}>
											<ListItemIcon><i className="fas fa-search" /></ListItemIcon>
											<ListItemText primary="Search" />
										</ListItem>
									</Link>
								</List>
							</Collapse>
							<ListItem button key="Options" onClick={ev => subMenuToggle('options')}>
								<ListItemIcon><i className="fas fa-database" /></ListItemIcon>
								<ListItemText primary="Options" />
								{subs.options ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse in={subs.options || false} timeout="auto" unmountOnExit>
								<List component="div" className="submenu">
									<Link to="/admin/locales" onClick={() => props.onClose()}>
										<ListItem button selected={location.pathname === '/admin/locales'}>
											<ListItemIcon><i className="fas fa-language" /></ListItemIcon>
											<ListItemText primary="Locales" />
										</ListItem>
									</Link>
									<Link to="/admin/languages" onClick={() => props.onClose()}>
										<ListItem button selected={location.pathname === '/admin/languages'}>
											<ListItemIcon><i className="fas fa-language" /></ListItemIcon>
											<ListItemText primary="Languages" />
										</ListItem>
									</Link>
									<Link to="/admin/professions" onClick={() => props.onClose()}>
										<ListItem button selected={location.pathname === '/admin/professions'}>
											<ListItemIcon><i className="fas fa-user-tie" /></ListItemIcon>
											<ListItemText primary="Professions" />
										</ListItem>
									</Link>
									<Link to="/admin/skills" onClick={() => props.onClose()}>
										<ListItem button selected={location.pathname === '/admin/skills'}>
											<ListItemIcon><i className="fas fa-utensils" /></ListItemIcon>
											<ListItemText primary="Skills" />
										</ListItem>
									</Link>
									<Link to="/admin/establishment_types" onClick={() => props.onClose()}>
										<ListItem button selected={location.pathname === '/admin/establishment_types'}>
											<ListItemIcon><i className="fas fa-store" /></ListItemIcon>
											<ListItemText primary="Estab. Types" />
										</ListItem>
									</Link>
								</List>
							</Collapse>
							<Link to="/admin/templates" onClick={() => props.onClose()}>
								<ListItem button selected={location.pathname === '/admin/templates'}>
									<ListItemIcon><i className="fas fa-file-alt" /></ListItemIcon>
									<ListItemText primary="Templates" />
								</ListItem>
							</Link>
							<Link to="/admin/faqs" onClick={() => props.onClose()}>
								<ListItem button selected={location.pathname === '/admin/faqs'}>
									<ListItemIcon><i className="fas fa-question" /></ListItemIcon>
									<ListItemText primary="FAQs" />
								</ListItem>
							</Link>
							<Link to="/admin/send" onClick={() => props.onClose()}>
								<ListItem button selected={location.pathname === '/admin/send'}>
									<ListItemIcon><i className="fas fa-paper-plane" /></ListItemIcon>
									<ListItemText primary="Send Message(s)" />
								</ListItem>
							</Link>
							{process.env.REACT_APP_CLEAR_MATCHES === 'true' &&
								<Link to="/admin/clear" onClick={() => props.onClose()}>
									<ListItem button selected={location.pathname === '/admin/clear'}>
										<ListItemIcon><i className="fas fa-exclamation-triangle" /></ListItemIcon>
										<ListItemText primary="Clear" />
									</ListItem>
								</Link>
							}
						</React.Fragment>
					}
					<ListItem button>
						<ListItemIcon><i className="fas fa-language" /></ListItemIcon>
						<ListItemText primary={
							<React.Fragment>
								<Box
									aria-controls="menu-locale"
									aria-haspopup="true"
									className="locale"
									onClick={ev => localeElSet(ev.currentTarget)}
								>
									{props.loc}
								</Box>
								<Menu
									id="menu-locale"
									anchorEl={localeEl}
									keepMounted
									open={Boolean(localeEl)}
									onClose={ev => localeElSet(null)}
								>
									{props.locs.map(o =>
										<MenuItem
											key={o._id}
											onClick={ev => {
												Events.trigger('locale', o._id);
												localeElSet(null);
											}}
										>
											{o.name}
										</MenuItem>
									)}
								</Menu>
							</React.Fragment>
						} />
					</ListItem>
					<Divider />
					<ListItem button onClick={signOut}>
						<ListItemIcon><i className="fas fa-sign-out-alt" /></ListItemIcon>
						<ListItemText primary={translate('common', 'signout', props.locale)} />
					</ListItem>
					<Divider />
				</List>
				<List className="bottom">
					<Typography><a href="mailto:support@servicesvp.com">support@ServiceSVP.com</a></Typography>
					<hr />
					<Typography><Link to="/privacy-policy" onClick={() => props.onClose()}>{translate('common', 'privacy_policy', props.locale)}</Link></Typography>
					<hr />
					<Typography><Link to="/terms-and-conditions" onClick={() => props.onClose()}>{translate('common', 'terms_and_conditions', props.locale)}</Link></Typography>
					<hr />
					<Typography>Version {process.env.REACT_APP_VERSION}</Typography>
					<br />
					<Typography>
						<a href="https://www.facebook.com/Service-SVP-103696858725532" target="_blank" rel="noreferrer"><i className="fab fa-facebook blue" /></a>&nbsp;
						<a href="https://www.instagram.com/servicesvp/" target="_blank" rel="noreferrer"><i className="fab fa-instagram blue" /></a>&nbsp;
						<a href="https://twitter.com/service_svp" target="_blank" rel="noreferrer"><i className="fab fa-twitter blue" /></a>&nbsp;
						<a href="https://www.youtube.com/channel/UCxUxbo8cy-n3bUbNPMvz-aw" target="_blank" rel="noreferrer"><i className="fab fa-youtube blue" /></a>
					</Typography>
				</List>
				<Box className="enterprise flexGrow">
				</Box>
			</Box>
		</Drawer>
	);
}

// Valid props
MenuDrawer.propTypes = {
	loc: PropTypes.string.isRequired,
	locale: PropTypes.string.isRequired,
	locs: PropTypes.array.isRequired,
	onClose: PropTypes.func.isRequired,
	root: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired
}

/**
 * FAQs
 *
 * Handles setting the FAQ questions and answers
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-15
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Local components
import Record from './Record';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { ucfirst } from 'shared/generic/tools';

/**
 * FAQs
 *
 * Handles displaying questions/answers and editing them
 *
 * @name FAQs
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function FAQs(props) {

	// State
	let [results, resultsSet] = useState([]);
	let [locs, locsSet] = useState([]);

	// Load Effect
	useEffect(() => {
		fetch();
	}, []);

	// Locales effect
	useEffect(() => {
		let lLocales = [];
		for(let o of props.locales) {
			if(o.active) {
				lLocales.push(o);
			}
		}
		locsSet(lLocales);
	}, [props.locales])

	// Fetch all FAQs
	function fetch() {
		Rest.read('admin', 'faq', {}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {
				resultsSet(res.data);
			}
		});
	}

	// Results render
	let Results = null;

	// If we're still loading the results
	if(results === null) {
		Results = <Typography>Loading...</Typography>
	} else if(results.length === 0) {
		Results = <Typography>Nothing returned</Typography>
	} else {
		Results = results.map(o =>
			<Accordion key={o._id}>
				<AccordionSummary
					expandIcon={<i className="fas fa-chevron-down" />}
				>
					<Typography><strong>{ucfirst(o._id)}</strong></Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Record
						locales={locs}
						value={o}
					/>
				</AccordionDetails>
			</Accordion>
		);
	}

	// Render
	return (
		<Box className="faqs padding">
			<Box className="header flexColumns">
				<Typography className="title flexGrow">FAQs</Typography>
			</Box>
			{Results}
		</Box>
	);
}

// Valid props
FAQs.propTypes = {
	locales: PropTypes.array.isRequired,
	mobile: PropTypes.bool.isRequired
}

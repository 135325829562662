/**
 * Verify
 *
 * Displays the verification page
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-07
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';

// Data modules
import translate from 'data/translate';

/**
 * Verify
 *
 * Handles verification page
 *
 * @name Verify
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Verify(props) {

	// State
	let [msg, msgSet] = useState({
		type: '',
		content: ''
	});

	// Hooks
	const history = useHistory();
	const location = useLocation();

	// Fetch info effect
	useEffect(() => {

		// Split the location pathname
		let lLocation = location.pathname.split('/');

		// If we didn't get enough info
		if(lLocation.length < 4) {
			history.push('/');
			return;
		}

		// Locale
		let sLocale = lLocation[2];

		// Set initial message
		msgSet({
			type: '',
			content: translate('verify', 'loading', sLocale)
		});

		// Send it to the service
		Rest.update('main', 'verify', {
			key: lLocation[lLocation.length === 4 ? 3 : 4]
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 2101) {
					msgSet({
						type: 'error',
						content: translate('verify', 'key_invalid', sLocale)
					});
				} else {
					Events.trigger('error', res.error);
				}
			}

			// On success, redirect to homepage
			if(res.data) {
				msgSet({
					type: 'success',
					content: translate('verify', 'success', sLocale)
				});

				// Notify site
				props.onVerified();

				setTimeout(() => {
					history.push('/')
				}, 5000);
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // React to user changes

	// Render
	return (
		<Box id="verify" className="singlePage padding">
			<Box className={msg.type}><Typography>{msg.content}</Typography></Box>
		</Box>
	);
}

Verify.propTypes = {
	onVerified: PropTypes.func.isRequired,
	user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired
}

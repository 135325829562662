/**
 * Photo Upload
 *
 * Handles uploading a single photo
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-04-09
 */

// NPM modules
import ImageUploading from 'react-images-uploading';
import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Data modules
import translate from 'data/translate';

/**
 * Photo Upload
 *
 * Handles photo uploading / displaying
 *
 * @name PhotoUpload
 * @access public
 * @param Object props Attributes sent to the component
 * @returns React.Component
 */
export default function PhotoUpload(props) {

	// Called when the photo changes
	function change(list) {

		// Split the URL
		let lData = list[0].url.split(';');

		// Store the data structure
		let oPhoto = {
			data: lData[1].substr(7),
			name: list[0].file.name.replace(/ /g, '_'),
			type: list[0].file.type,
			url: list[0].url
		};

		// Let the parent known
		props.onChange([oPhoto]);
	}

	// Called when there's an error uploading a file
	function error(errors, files) {

		// Init the error return
		let lError = null;

		// If the file type is invalid
		if(errors.acceptType) {
			lError = ['invalid mime', files[0].file.type];
		}

		// Else if the file size is too big
		else if(errors.maxFileSize) {
			lError = ['photo size'];
		}

		// Else we got an unknown error
		else {
			lError = ['unknown'];
			console.log(errors, files);
		}

		// Let the parent known
		props.onError(lError);
	}

	// Called when photo is removed
	function remove() {

		// Let the parent known
		props.onChange(null);
	}

	// Render
	return (
		<ImageUploading
			acceptType={['jpg', 'jpeg', 'png']}
			dataURLKey="url"
			maxFileSize={10485760}
			maxNumber={1}
			onChange={change}
			onError={error}
			value={props.value}
		>
			{({
				imageList,
				onImageUpload,
				dragProps,
		}) => (
				<Box>
					{imageList.length === 0 ?
						<Box className="uploadText link" onClick={onImageUpload} {...dragProps}>
							<Typography className="link">
								{translate('common', 'upload', props.locale)}
							</Typography>
						</Box>
					:
						imageList.map(o =>
							<Box key={o.name} className="existingPhoto" style={{backgroundImage: `url(${o.url})`}}>
								<i className="fas fa-times-circle border" onClick={remove} />
							</Box>
						)
					}
				</Box>
			)}
		</ImageUploading>
	);
}

// Valid props
PhotoUpload.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired,
	value: PropTypes.arrayOf(PropTypes.shape({
		data: PropTypes.string,
		name: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired
	}))
}

/**
 * Slider Label
 *
 * Handles displaying the label in a slider
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-27
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';

// Material UI Modules
import Tooltip from '@material-ui/core/Tooltip';

/**
 * Slider Label
 *
 * Shows a tooltip below the slider position
 *
 * @name SliderLabel
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function SliderLabel(props) {

	// Render
	return (
		<Tooltip
			arrow
			enterTouchDelay={0}
			open={props.open}
			placement="bottom"
			title={props.value + 'km'}
		>
			{props.children}
		</Tooltip>
	);
}

// Valid props
SliderLabel.propTypes = {
	children: PropTypes.any.isRequired,
	open: PropTypes.bool.isRequired,
	value: PropTypes.number.isRequired
}

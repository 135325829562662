/**
 * Posting Edit
 *
 * Displays the form to edit an existing posting
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-20
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Composite components
import PostingView from 'components/composites/employer/posting/View';

// Local components
import PostingForm from './Form';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { afindo, clone, uuidv4 } from 'shared/generic/tools';

// Data modules
import locales from 'data/locales';
import translate from 'data/translate';

/**
 * Posting Edit
 *
 * Handles editing an existing posting
 *
 * @name PostingEdit
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function PostingEdit(props) {

	// State
	let [data, dataSet] = useState(false);
	let [errors, errorsSet] = useState({});
	let [preview, previewSet] = useState(false);
	let [setup, setupSet] = useState({});

	// Hooks
	let { _id } = useParams();

	// Load effect
	useEffect(() => {

		// Fetch only the active info
		Rest.read('main', 'setup', {}).done(res => {

			// Init the new structure
			let oSetup = {};

			// Go through each type and sort alphabeticall
			for(let s of ['skills', 'professions']) {
				oSetup[s] = locales.sortObject(res.data[s]);
			}

			// Set the setup
			setupSet(oSetup);
		});
	}, []);

	// Load effect
	useEffect(() => {
		if(props.employer && props.employer.postings) {
			let o = afindo(props.employer.postings, '_id', _id);
			if(o) {
				let oData = clone(o);
				if(!(oData.skills)) {
					oData.skills = [];
				}
				if(oData.times.type === 'gig') {
					for(let oS of oData.times.shifts) {
						oS.key = uuidv4();
					}
				}
				dataSet(oData);
			} else {
				props.onCancel();
			}
		} else {
			dataSet(false);
		}
	// eslint-disable-next-line
	}, [props.employer, props.employer.postings, _id]);

	// Field changed
	function fieldChanged(name, value) {
		dataSet(data => {
			if(typeof value === 'string') {
				value = value.trim() === '' ? null : value;
			}
			data[name] = value;
			return clone(data);
		});
	}

	// Show the preview
	function previewShow() {

		// Get the URLs from the server
		Rest.read('main', 'employer/photos', {
			_id: props.employer._id
		}).done(res => {
			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If we're successful
			if(res.data) {

				// Clone the data
				let oPreview = clone(data);

				// Add the employer info
				oPreview['employer'] = props.employer;

				// Add the background
				oPreview.background = (res.data.photos && res.data.photos.length) ?
					res.data.photos[0].url :
					process.env.REACT_APP_NO_PHOTO_URL;

				// Set the preview
				previewSet(oPreview);
			}
		});
	}

	// Update the existing record
	function update() {

		// Clone the data and remove the fields that aren't sent
		let oData = clone(data);
		delete oData.matches;
		delete oData.potential_matches;

		// If we have a gig
		if(oData.times.type === 'gig') {
			for(let o of oData.times.shifts) {
				delete o.key;
			}
		}

		// Send the data to the server
		Rest.update('main', 'posting', oData).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 1001) {
					errorsSet(Rest.toTree(res.error.msg));
				} else if(res.error.code === 2004) {
					Events.trigger('error', translate('errors', 'duplicate posting', props.locale, {
						profession: props.info.professions[res.error.msg[0]][props.locale],
						times: res.error.msg[1],
						salary: res.error.msg[2]
					}));
				} else {
					Events.trigger('error', res.error);
				}
			}

			// If we're successful
			if(res.data) {

				// Let the parent know
				props.onSuccess(res.data);
			}
		});
	}

	// Render
	return (
		<Box className="singlePage padding">
			<Box className="flexRows">
				<Box className="padding flexGrow" style={{overflowX: 'hidden'}}>
					<Typography variant="h2">{translate('employer', 'posting_edit', props.locale)}</Typography>
					{data !== false &&
						<PostingForm
							employer={props.employer}
							errors={errors}
							fieldChanged={fieldChanged}
							info={props.info}
							locale={props.locale}
							mode="edit"
							setup={setup}
							value={data}
						/>
					}
				</Box>
				<Box className="flexColumns">
					<Box className="flexStatic">
						<Button variant="contained" onClick={previewShow}>{translate('common', 'preview', props.locale)}</Button>
					</Box>
					<Box className="actions flexGrow">
						<Button variant="contained" color="secondary" onClick={props.onCancel}>{translate('common', 'cancel', props.locale)}</Button>
						<Button variant="contained" color="primary" onClick={update}>{translate('common', 'submit', props.locale)}</Button>
					</Box>
				</Box>
			</Box>
			{preview &&
				<PostingView
					close={() => previewSet(false)}
					locale={props.locale}
					info={props.info}
					mobile={props.mobile}
					value={preview}
				/>
			}
		</Box>
	);
}

// Valid props
PostingEdit.propTypes = {
	employer: PropTypes.oneOfType([PropTypes.object,PropTypes.bool]).isRequired,
	locale: PropTypes.string.isRequired,
	info: PropTypes.object.isRequired,
	mobile: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired
}

/**
 * Stopped
 *
 * Displays a message to users who are using a phone number that has been
 * STOPped in twilio
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2022-01-26
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import { nicePhone } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

/**
 * Stopped
 *
 * Displays the STOPped message
 *
 * @name Stopped
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Stopped(props, action=null) {

	// State
	let [hide, hideSet] = useState(false);

	// Check verification
	function checkStopped() {

		// Hide for now
		hideSet(true);

		// Check if stopped is still set
		Rest.read('main', 'stopped').done(res => {
			if(res.data) {
				hideSet(false);
			} else {
				props.onNotStopped();
			}
		});
	}

	// If we're hiding
	if(hide) {
		return '';
	}

	// Render
	return (
		<Box id="not_verified" className="flexColumns">
			<Box className="flexGrow">
				<Typography>
					{translate('errors', 'comm_stopped', props.locale, {
						numbers: props.twilioNumbers.map(s => nicePhone(s)).join(', ')
					})}&nbsp;
					<Link to="/account">{translate('common', 'change_comm_pref', props.locale)}</Link>
				</Typography>
			</Box>
			<Box className="flexStatic">
				<i className="fas fa-sync-alt" onClick={checkStopped} />
			</Box>
		</Box>
	);
}

Stopped.propTypes = {
	locale: PropTypes.string.isRequired,
	onNotStopped: PropTypes.func.isRequired,
	twilioNumbers: PropTypes.object.isRequired
}


/**
 * Not Verified
 *
 * Displays a message to users who have not verified their email
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-05
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';

// Data modules
import translate from 'data/translate';

/**
 * NotVerified
 *
 * Displays the not verified message
 *
 * @name NotVerified
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function NotVerified(props, action=null) {

	// State
	let [hide, hideSet] = useState(false);
	let [sent, sentSet] = useState(false);

	// Check verification
	function checkVerification() {

		// Hide for now
		hideSet(true);

		// Check if verification is set
		Rest.read('main', 'verified').done(res => {
			if(res.data) {
				props.onVerified();
			} else {
				hideSet(false);
				sentSet(false);
			}
		});
	}

	// Send verification
	function sendVerification() {

		// Request the email
		Rest.update('main', 'verify/send', {
			url: 'https://' + window.location.host + '/verify/{locale}/{key}'
		}).done(res => {
			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 2107) {
					props.onVerified();
				} else {
					Events.trigger('error', res.error);
				}
			}

			// If there's data
			if(res.data) {
				sentSet(true);
			}
		});
	}

	// If we're hiding
	if(hide) {
		return '';
	}

	// Render
	return (
		<Box id="not_verified" className="flexColumns">
			<Box className="flexGrow">
				{sent ?
					<Typography>{translate('common', 'sent', props.locale)}</Typography> :
					<Typography>
						{translate('errors', 'not_verified', props.locale)}&nbsp;
						<span className="link" onClick={sendVerification}>{translate('common', 'resend_email', props.locale)}</span>&nbsp;/&nbsp;
						<Link to="/account">{translate('common', 'change_email', props.locale)}</Link>
					</Typography>
				}
			</Box>
			<Box className="flexStatic">
				<i className="fas fa-sync-alt" onClick={checkVerification} />
			</Box>
		</Box>
	);
}

NotVerified.propTypes = {
	locale: PropTypes.string.isRequired,
	onVerified: PropTypes.func.isRequired
}


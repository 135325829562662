/**
 * Hours Display
 *
 * Handles displaying the fixed or flexible hours associated with a job posting
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2022-02-01
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';

// Data modules
import translate from 'data/translate';

/**
 * HoursDisplay
 *
 * Displays the hours based on the values
 *
 * @name HoursDisplay
 * @access public
 * @param Object props Attributes sent to the component
 * @returns React.Component
 */
export default function HoursDisplay(props) {

	// If there's no hours
	if(props.value.min === 0) {
		return '';
	}

	// If there's no max
	if(props.value.max === undefined) {
		return <span>
					<span className="blue"> | </span>
					{props.value.min}&nbsp;
					{translate('common', 'hours', props.locale)}
				</span>
	} else {
		return <span>
					<span className="blue"> | </span>
					{translate('common', 'flexible', props.locale)}
					<span className="blue"> Min </span>
					{props.value.min}{translate('common', 'hour_shorthand', props.locale)}
					<span className="blue"> Max </span>
					{props.value.max}{translate('common', 'hour_shorthand', props.locale)}
				</span>
	}
}

// Valid props
HoursDisplay.propTypes = {
	locale: PropTypes.string.isRequired
}
/**
 * FAQ Record Entry
 *
 * Handles a single FAQ record entry
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-15
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared generic modules
import { clone } from 'shared/generic/tools';

/**
 * Entry
 *
 * Handles displaying questions/answers and editing them
 *
 * @name Entry
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Entry(props) {

	// Called when any value changes
	function change(type, id, text) {
		props.value[type][id] = text;
		props.onUpdated(clone(props.value));
	}

	// Render
	return (
		<Box className="faqs_record_entry">
			<Box className="flexColumns">
				<Box className="flexStatic">
					<Typography>Question</Typography>
				</Box>
				<Box className="flexGrow">
					{props.locales.map(o =>
						<Box key={o._id} className="field">
							<Typography>{o.name}</Typography>
							<TextField
								onChange={ev => change('question', o._id, ev.target.value)}
								type="text"
								value={props.value.question[o._id]}
								variant="outlined"
							/>
						</Box>
					)}
				</Box>
			</Box>
			<Box className="flexColumns">
				<Box className="flexStatic">
					<Typography>Answer</Typography>
				</Box>
				<Box className="flexGrow">
					{props.locales.map(o =>
						<Box key={o._id} className="field">
							<Typography>{o.name}</Typography>
							<TextField
								onChange={ev => change('answer', o._id, ev.target.value)}
								multiline
								type="text"
								value={props.value.answer[o._id]}
								variant="outlined"
							/>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
}

// Valid props
Entry.propTypes = {
	locales: PropTypes.array.isRequired,
	onUpdated: PropTypes.func.isRequired,
	value: PropTypes.object.isRequired
}

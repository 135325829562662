/**
 * Employee
 *
 * Switch for employee pages
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-19
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';

// Local components
import Match from './Match';
import Messaging from './Messaging';
import Profile from './Profile';

/**
 * Employee
 *
 * Handles employee pages
 *
 * @name Employee
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Employee(props) {

	// State
	let [info, infoSet] = useState(false);

	// Load effect
	useEffect(() => {
		Rest.read('main', 'info', {}).done(res => {
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}
			if(res.data) {
				infoSet(res.data);
			}
		});
	}, []);

	// If there's no info yet
	if(!info) {
		return <div />
	}

	// Render
	return (
		<Switch>
			<Route exact path="/employee">
				<Match
					locale={props.locale}
					info={info}
					mobile={props.mobile}
					user={props.user}
				/>
			</Route>
			<Route path="/employee/messaging">
				<Messaging
					info={info}
					locale={props.locale}
					mobile={props.mobile}
					user={props.user}
				/>
			</Route>
			<Route exact path="/employee/profile">
				<Profile
					info={info}
					locale={props.locale}
					mobile={props.mobile}
					user={props.user}
				/>
			</Route>
		</Switch>
	);
}

// Valid props
Employee.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}

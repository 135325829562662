/**
 * Header
 *
 * Handles header of all pages
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-14
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

// Data modules
import Locales from 'data/locales';
import translate from 'data/translate';

// Shared generic modules
import Events from 'shared/generic/events';
import { afindo } from 'shared/generic/tools';

// Data modules
import Messaging from 'data/messaging';

// Local Components
import ForgotPass from './ForgotPass';
import MenuDrawer from './MenuDrawer';
import SignIn from './SignIn';

// images
import imgSvpBell from 'images/ssvp-bell.svg';
import imgLoader from 'images/ajax-loader.gif';

/**
 * Header
 *
 * Handles switching between admin functions
 *
 * @name Header
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Header(props) {

	// State
	let [loader, loaderSet] = useState(0);
	let [loc, locSet] = useState('');
	let [localeAnchor, localeAnchorSet] = useState(null);
	let [locs, locsSet] = useState([]);
	let [menu, menuSet] = useState(false);
	let [popup, popupSet] = useState(false);
	let [unread, unreadSet] = useState(0);

	// Load effect
	useEffect(() => {
		Events.subscribe('loader_hide', loaderHide);
		Events.subscribe('loader_show', loaderShow);
		locsSet(
			Locales.subscribe(locsSet)
		);
		unreadSet(
			Messaging.subscribe('unread', unreadSet)
		)
		return () => {
			Events.unsubscribe('loader_hide', loaderHide);
			Events.unsubscribe('loader_show', loaderShow);
			Locales.unsubscribe(locsSet);
			Messaging.unsubscribe('unread', unreadSet);
		}
	}, []);

	// Locale effect
	useEffect(() => {
		let o = afindo(locs, '_id', props.locale);
		if(o) {
			locSet(o.name);
		}
	}, [props.locale, locs]);

	// Hide loader
	function loaderHide() {
		loaderSet(val => {
			return val -= 1;
		});
	}

	// Show loader
	function loaderShow() {
		loaderSet(val => {
			return val += 1;
		});
	}

	// Get root url
	let sRoot = '/';
	if(props.user) {
		if(props.user.employee) {
			sRoot = '/employee';
		} else if(props.user.employer) {
			sRoot = '/employer';
		}
	}

	// Render
	return (
		<React.Fragment>
			<Box id="header" className="flexStatic flexColumns">
				<Box className="flexGrow">
					<Typography className="title">
						<Link to={sRoot} className="site-title">
							{loader > 0 ?
								<img className="titleIcon" src={imgLoader} alt="Loading..." style={{height: '23.5px', width: '32px'}} /> :
								<img className="titleIcon" src={imgSvpBell} alt="ServiceSVP" />
							}
							<span className="titleLabel">ServiceSVP</span>
						</Link>
					</Typography>
				</Box>
				{props.user ?
					<Box className="icons flexStatic">
						<Link to={sRoot + '/messaging'}>
							<i className="fas fa-comment-dots" title={translate('common', 'messaging', props.locale)}>
								{unread > 0 && <span className="count">{unread}</span>}
							</i>
						</Link>
						<i className="fas fa-bars" onClick={() => menuSet(true)} />
					</Box>
				:
					<React.Fragment>
						<Box className="locale flexStatic">
							<Button aria-controls="header-locale" aria-haspopup="true" onClick={ev => localeAnchorSet(ev.target)}>
								{loc}
							</Button>
							<Menu
								id="header-locale"
								anchorEl={localeAnchor}
								keepMounted
								open={Boolean(localeAnchor)}
								onClose={ev => localeAnchorSet(null)}
							>
								{locs.map(o =>
									<MenuItem
										key={o._id}
										onClick={ev => {
											Events.trigger('locale', o._id);
											localeAnchorSet(null);
										}}
									>
										{o.name}
									</MenuItem>
								)}
							</Menu>
						</Box>
						<Box className="icons flexStatic">
							<i className="fas fa-user-circle" title={translate('common', 'signin', props.locale)} onClick={ev => popupSet('signin')} />
						</Box>
					</React.Fragment>
				}
			</Box>
			{(props.user && menu) &&
				<MenuDrawer
					loc={loc}
					locale={props.locale}
					locs={locs}
					onClose={() => menuSet(false)}
					root={sRoot}
					user={props.user}
				/>
			}
			{popup === 'forgot' &&
				<ForgotPass
					locale={props.locale}
					onClose={() => popupSet(false)}
				/>
			}
			{popup === 'signin' &&
				<SignIn
					locale={props.locale}
					onForgot={() => popupSet('forgot')}
					onClose={() => popupSet(false)}
				/>
			}
		</React.Fragment>
	)
}

// Valid props
Header.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired
}

/**
 * Admin Stats Page
 *
 * Handles all the stats management
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2022-02-22
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Material UI
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

// Local modules
import Signups from './Signups';
import Matches from './Matches';

// Tabs
const _TABS = [
	'Signups',
	'Matches'
]

/**
 * Stats
 *
 * Handles switching between user types
 *
 * @name Stats
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Stats(props) {

	// State
	let [tab, tabSet] = useState(0);

	// Render
	return (
		<Box className="admin_users">
			<AppBar position="static" color="default">
				<Tabs
					indicatorColor="primary"
					onChange={(ev, tab) => tabSet(tab)}
					value={tab}
					variant="fullWidth"
				>
					{_TABS.map((label,i) =>
						<Tab key={i} label={label} />
					)}
				</Tabs>
			</AppBar>
			<Box>
				{_TABS[tab] === 'Signups' &&
					<Signups
						mobile={props.mobile}
					/>
				}
				{_TABS[tab] === 'Matches' &&
					<Matches
						mobile={props.mobile}
					/>
				}
			</Box>
		</Box>
	);
}

// Valid props
Stats.propTypes = {
	mobile: PropTypes.bool.isRequired
}

/**
 * Forgot
 *
 * Displays the password forgot change password page
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-05-22
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';

// Data modules
import translate from 'data/translate';

/**
 * Forgot
 *
 * Handles changing the password page
 *
 * @name Forgot
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Forgot(props) {

	// State
	let [confirm, confirmSet] = useState('');
	let [error, errorSet] = useState(false);
	let [info, infoSet] = useState(false);
	let [passwd, passwdSet] = useState('');
	let [success, successSet] = useState(false);

	// Hooks
	const history = useHistory();
	const location = useLocation();

	// Load effect
	useEffect(() => {

		// Split the location pathname
		let lLocation = location.pathname.split('/');

		// If we didn't get enough info
		if(lLocation.length < 5) {
			history.push('/');
			return;
		}

		// Set the locale
		setTimeout(() => {
			Events.trigger('locale', lLocation[2]);
		}, 100);

		// Set the info
		infoSet({
			email: lLocation[3],
			key: lLocation[4]
		})

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Called to change password
	function submit() {

		// Reset error message
		errorSet(false);

		// If the passwords do not match
		if(confirm !== passwd) {
			errorSet(translate('errors', 'mismatch', props.locale));
			return;
		}

		// Make the request to the server
		Rest.update('main', 'passwd_forgot', {
			key: info.key,
			email: info.email,
			passwd: passwd
		}, {session:false}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 2101) {
					errorSet(translate('password_forgot', 'key_invalid', props.locale));
				} else if(res.error.code === 2102) {
					errorSet(translate('errors', 'password_strength', props.locale));
				} else {
					errorSet(translate('common', 'unknown', props.locale));
				}
			}

			// If we got data
			if(res.data) {
				successSet(true);
			}
		});
	}

	// Render
	return (
		<Box id="forgot" className="padding singlePage">
			<Typography variant="h2">{translate('common', 'change_passwd', props.locale)}</Typography>
			{success ?
				<Typography>{translate('password_forgot', 'change_success', props.locale)}</Typography>
			:
				<React.Fragment>
					{error &&
						<Typography className="error">{error}</Typography>
					}
					<Grid container spacing={2}>
						<Grid item xs={12} className="field">
							<Typography>{translate('common', 'password', props.locale)}</Typography>
							<TextField
								placeholder={translate('common', 'password', props.locale)}
								onChange={ev => passwdSet(ev.target.value)}
								type="password"
								value={passwd}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} className="field">
							<Typography>{translate('common', 'confirm_password', props.locale)}</Typography>
							<TextField
								onChange={ev => confirmSet(ev.target.value)}
								placeholder={translate('common', 'confirm_password', props.locale)}
								type="password"
								value={confirm}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} className="actions">
							<Button
								className="white flexStatic"
								disabled={passwd.trim() === '' || confirm.trim() === ''}
								onClick={submit}
								variant="contained"
							>
								{translate('common', 'change_passwd', props.locale)}
							</Button>
						</Grid>
					</Grid>
				</React.Fragment>
			}
		</Box>
	);
}

// Valid props
Forgot.propTypes = {
	locale: PropTypes.string.isRequired
}
/**
 * Posting Create
 *
 * Displays the form to create a new posting
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-20
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Composite components
import PostingView from 'components/composites/employer/posting/View';

// Local components
import PostingForm from './Form';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import { iso } from 'shared/generic/dates';
import Events from 'shared/generic/events';
import { clone } from 'shared/generic/tools';

// Data modules
import locales from 'data/locales';
import translate from 'data/translate';

/**
 * Posting Create
 *
 * Handles creating a new posting
 *
 * @name PostingCreate
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function PostingCreate(props) {

	// State
	let [data, dataSet] = useState({
		salary_type: 'hourly',
		salary_amount: '12.50',
		skills: [],
		times: {
			type: 'full',
			starts: iso(new Date(), false, false),
			hours: {
				min: 40
			}
		}
	});
	let [errors, errorsSet] = useState({});
	let [preview, previewSet] = useState(false);
	let [setup, setupSet] = useState({});

	// Refs
	let refSubmit = useRef();

	// Load effect
	useEffect(() => {

		// Fetch only the active info
		Rest.read('main', 'setup', {}).done(res => {

			// Init the new structure
			let oSetup = {};

			// Go through each type and sort alphabeticall
			for(let s of ['skills', 'professions']) {
				oSetup[s] = locales.sortObject(res.data[s]);
			}

			// Set the setup
			setupSet(oSetup);
		});
	}, []);

	// Create the new record
	function create() {

		// Disable the submit button
		if(refSubmit.current) {
			refSubmit.current.setAttribute('disabled', 'disabled');
		}

		// Add the employer ID
		data['employer'] = props.employer._id;

		// Clone the data
		let oData = clone(data);

		// If we have a gig
		if(oData.times.type === 'gig') {
			for(let o of oData.times.shifts) {
				delete o.key;
			}
		}

		// Send the data to the server
		Rest.create('main', 'posting', oData).done(res => {

			// Re-enable the submit
			if(refSubmit.current) {
				refSubmit.current.removeAttribute('disabled');
			}

			// Re-enable the submit
			if(refSubmit.current) {
				console.log('re-enable');
				refSubmit.current.removeAttribute('disabled');
			}

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 1001) {
					errorsSet(Rest.toTree(res.error.msg));
				} else if(res.error.code === 2004) {
					Events.trigger('error', translate('errors', 'duplicate posting', props.locale, {
						profession: props.info.professions[res.error.msg[0]][props.locale],
						times: res.error.msg[1],
						salary: res.error.msg[2]
					}));
				} else {
					Events.trigger('error', res.error);
				}
			}

			// If we're successful
			if(res.data) {

				// Let the parent know
				props.onSuccess(res.data);
			}
		});
	}

	// Field changed
	function fieldChanged(name, value) {
		dataSet(data => {
			if(typeof value === 'string') {
				value = value.trim() === '' ? null : value;
			}
			if(value !== null) {
				data[name] = value;
			} else {
				delete data[name];
			}

			return clone(data);
		});
	}

	// Show the preview
	function previewShow() {

		// Clone the data
		let oPreview = clone(data);

		// Add the employer info
		oPreview['employer'] = props.employer;

		// Add the background
		oPreview.background = (props.employer.photos && props.employer.photos.length) ?
			props.employer.photos[0].url :
			process.env.REACT_APP_NO_PHOTO_URL;

		// Set the preview
		previewSet(oPreview);
	}

	// Render
	return (
		<Box className="singlePage padding">
			<Box className="flexRows">
				<Box className="padding flexGrow" style={{overflowX: 'hidden'}}>
					<Typography variant="h2">{translate('employer', 'posting_add', props.locale)}</Typography>
					<PostingForm
						employer={props.employer}
						errors={errors}
						fieldChanged={fieldChanged}
						info={props.info}
						locale={props.locale}
						mode="create"
						setup={setup}
						value={data}
					/>
				</Box>
				<Box className="flexColumns">
					<Box className="flexStatic">
						<Button variant="contained" onClick={previewShow}>{translate('common', 'preview', props.locale)}</Button>
					</Box>
					<Box className="actions flexGrow">
						<Button variant="contained" color="secondary" onClick={props.onCancel}>{translate('common', 'cancel', props.locale)}</Button>
						<Button variant="contained" color="primary" onClick={create} ref={refSubmit}>{translate('common', 'submit', props.locale)}</Button>
					</Box>
				</Box>
			</Box>
			{preview &&
				<PostingView
					close={() => previewSet(false)}
					locale={props.locale}
					info={props.info}
					mobile={props.mobile}
					value={preview}
				/>
			}
		</Box>
	);
}

// Valid props
PostingCreate.propTypes = {
	copy: PropTypes.object,
	employer: PropTypes.oneOfType([PropTypes.object,PropTypes.bool]).isRequired,
	locale: PropTypes.string.isRequired,
	info: PropTypes.object.isRequired,
	mobile: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired
}

// Default props
PostingCreate.defaultProps = {
	copy: null
}
/**
 * Sign In
 *
 * Handles signing into the app
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-14
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';

// Shared Hooks
import { useOutsideClick } from 'shared/hooks/outsideClick';

// Data modules
import translate from 'data/translate';

/**
 * Sign In
 *
 * Handles the form to sign into the site
 *
 * @name login
 * @access private
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function SignIn(props) {

	// State
	let [email, emailSet] = useState('');
	let [error, errorSet] = useState(false);
	let [passwd, passwdSet] = useState('');

	// Refs
	let refSelf = useRef();

	// Hooks
	useOutsideClick(refSelf, () => props.onClose());

	// Traps "Enter"
	function keyPress(ev) {
		if(ev.key === 'Enter') {
			signIn();
		}
	}

	// Sign In
	function signIn() {

		// Reset error message
		errorSet(false);

		// Make the request to the server
		Rest.create('main', 'signin', {
			email: email.trim(),
			passwd: passwd.trim()
		}, {session: false}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 2100) {
					errorSet(translate('signin', 'invalid', props.locale));
				} else {
					errorSet(translate('common', 'unknown', props.locale));
				}
			}

			// If we're successful
			if(res.data) {

				// Set the session with the service
				Rest.session(res.data.session);

				// Call the sign in event
				Events.trigger('signedIn', res.data.user);

				// Hide the form
				props.onClose()
			}
		});
	}

	// Render
	return (
		<Box id="signin" ref={refSelf}>
			{error &&
				<Typography className="error">{error}</Typography>
			}
			<Box className="field">
				<Typography>{translate('common', 'email', props.locale)}</Typography>
				<TextField
					onChange={ev => emailSet(ev.target.value)}
					onKeyPress={keyPress}
					type="text"
					value={email}
					variant="outlined"
				/>
			</Box>
			<Box className="field">
				<Typography>{translate('common', 'password', props.locale)}</Typography>
				<TextField
					onChange={ev => passwdSet(ev.target.value)}
					onKeyPress={keyPress}
					type="password"
					value={passwd}
					variant="outlined"
				/>
			</Box>
			<Box className="field flexColumns">
				<Typography
					className="center link flexGrow"
					onClick={props.onForgot}
					style={{lineHeight: '36px'}}
				>
					{translate('common', 'forgotpass', props.locale)}
				</Typography>
				<Button
					className="flexStatic"
					disabled={email.trim() === '' || passwd.trim() === ''}
					onClick={signIn}
					variant="contained"
				>
					{translate('common', 'signin', props.locale)}
				</Button>
			</Box>
		</Box>
	);
}

// Valid props
SignIn.propTypes = {
	locale: PropTypes.string.isRequired,
	onForgot: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
}

/**
 * Posting
 *
 * Handles the public page of an employer's posting
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2022-01-06
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Composite components
import PostingView from 'components/composites/employer/posting/View';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { min } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

/**
 * Posting
 *
 * Displays the posting information
 *
 * @name Posting
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Posting(props) {

	// State
	let [info, infoSet] = useState(null);
	let [posting, postingSet] = useState(null);
	let [mode, modeSet] = useState('');

	// Hooks
	let { _id } = useParams();
	let history = useHistory();

	// Load / ID effect
	useEffect(() => {

		// Show loading
		postingSet(null);

		// Fetch the posting info
		Rest.read('main', 'posting/public', {
			_id: _id
		}).done(res => {
			if(res.error && !res._handled) {
				if(res.error.code === 2003) {
					postingSet(false);
					setTimeout(() => {
						history.push('/');
					}, 3000);
				} else {
					Events.trigger('error', res.error);
				}
			}
			if(res.data) {

				// Store the psuedo info
				infoSet(res.data.info);

				// Add the background image
				res.data.posting.background = (res.data.posting.employer.photos && res.data.posting.employer.photos.length) ?
					res.data.posting.employer.photos[0].url :
					process.env.REACT_APP_NO_PHOTO_URL;

				// Store the posting
				postingSet(res.data.posting);

				// Store the mode
				modeSet(res.data.state);
			}
		});

	// eslint-disable-next-line
	}, [_id, props.user]);

	// If the posting doesn't exist
	if(posting === false) {
		return (
			<Box className="padding">
				<Typography>{translate('errors', '2003', props.locale, {
					name: translate('employer', 'posting', props.locale)
				})}</Typography>
			</Box>
		);
	}

	// If the posting is null
	if(posting === null || info === null) {
		return (
			<Box className="padding">
				<Typography>{translate('common', 'loading', props.locale)}...</Typography>
			</Box>
		);
	}

	// Share variables
	let sDate = posting.times.type === 'gig' ?
				min(posting.times.shifts.map(o => o.starts)).substr(0,16):
				posting.times.starts;
	let oShare = {
		url: `https://${window.location.host}/posting/${posting._id}`,
		text: `${info.professions[posting.profession][props.locale]}, ${posting.salary_amount}/${translate('common', 'salary_type_' + posting.salary_type, props.locale)}, ${sDate}`
	}

	// Generate the call to action button based on the mode
	let oAction = {};
	switch(mode) {
		case 'employer':
			oAction.url = '/employer';
			oAction.text = translate('posting_public', 'home', props.locale);
			break;

		case 'invalid':
		case 'blocked':
			oAction.url = '/employee/profile';
			oAction.text = translate('posting_public', 'profile', props.locale);
			break;

		case 'no_user':
			oAction.url = '/signup';
			oAction.text = translate('posting_public', 'signup', props.locale);
			break;

		case 'valid':
			oAction.url = `/employee?id=${_id}`;
			oAction.text = translate('posting_public', 'match', props.locale);
			break;

		// no default
	}

	// Render
	return (
		<Box className="singlePage flexRows">
			<PostingView
				locale={props.locale}
				info={info}
				mobile={props.mobile}
				public={oAction}
				share={oShare}
				value={posting}
			/>
		</Box>
	);
}

// Valid props
Posting.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
}
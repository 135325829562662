/**
 * Continue
 *
 * Handles a person setting up their account after sign up
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-17
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Page components
import EmployerCreate from 'components/pages/employer/Create.js'

// Locale components
import Employee from './Employee';

// Data modules
import translate from 'data/translate';

/**
 * Continue
 *
 * Handles homepage
 *
 * @name Continue
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Continue(props) {

	// State
	let [state, stateSet] = useState('');

	// Render
	return (
		<React.Fragment>
			{state === '' &&
				<Box id="continue">
					<Grid container spacing={0}>
						<Grid item xs={12} sm={6}>
							<Box className="which">
								<Typography variant="h2">{translate('common', 'employee', props.locale)}</Typography>
								<Typography>{translate('continue', 'employee_text', props.locale)}</Typography>
								<Button onClick={ev => stateSet('employee')} variant="contained">{translate('continue', 'continue', props.locale)}</Button>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box className="which">
								<Typography variant="h2">{translate('common', 'employer', props.locale)}</Typography>
								<Typography>{translate('continue', 'employer_text', props.locale)}</Typography>
								<Button onClick={ev => stateSet('employer')} variant="contained">{translate('continue', 'continue', props.locale)}</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>
			}
			{state === 'employee' &&
				<Employee {...props} />
			}
			{state === 'employer' &&
				<EmployerCreate first={true} {...props} />
			}
		</React.Fragment>
	);
}

// Valid props
Continue.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}

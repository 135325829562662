/**
 * Unsubscribe
 *
 * Displays the unsubscribe page
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2022-01-15
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { parseQuery } from 'shared/generic/tools';

// Data modules
import translate from 'data/translate';

/**
 * Unsubscribe
 *
 * Handles unsubscribing
 *
 * @name Unsubscribe
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Unsubscribe(props) {

	// State
	let [msg, msgSet] = useState({
		type: '',
		content: ''
	});

	// Hooks
	const history = useHistory();

	// Fetch info effect
	useEffect(() => {

		// Get the query string
		let oQuery = parseQuery(window.location.search.substring(1));

		// If we didn't get enough info
		if(!oQuery.e && !oQuery.n) {
			history.push('/');
			return;
		}

		// Set initial message
		msgSet({
			type: '',
			content: translate('unsubscribe', 'loading', props.locale)
		});

		// Did we get an email, or a phone number
		let oData = {};
		if(oQuery.e) {
			oData.email = oQuery.e;
		} else {
			oData.number = oQuery.n;
		}

		// Send it to the service
		Rest.update('main', 'unsubscribe', oData, {session: false}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 2003) {
					msgSet({
						type: 'error',
						content: translate('unsubscribe', 'key_invalid', props.locale)
					});
				} else {
					Events.trigger('error', res.error);
				}
			}

			// On success, redirect to homepage
			if('data' in res) {
				msgSet({
					type: 'success',
					content: translate('unsubscribe', 'success', props.locale)
				});

				setTimeout(() => {
					history.push('/')
				}, 5000);
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // React to user changes

	// Render
	return (
		<Box id="unsubscribe" className="singlePage padding">
			<Box className={msg.type}><Typography>{msg.content}</Typography></Box>
		</Box>
	);
}

Unsubscribe.propTypes = {
	locale: PropTypes.string.isRequired
}

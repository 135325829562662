/**
 * Sign Up
 *
 * Displays the sign up page
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-17
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Composite components
import Communication from 'components/composites/Communication';

// Date modules
import Locales from 'data/locales';
import translate from 'data/translate';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { clone } from 'shared/generic/tools';

/**
 * SignUp
 *
 * Handles homepage
 *
 * @name SignUp
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function SignUp(props) {

	// State
	let [data, dataSet] = useState({
		email: '',
		passwd: '',
		communication: {
			choice: 'email'
		},
		url: 'https://' + window.location.host + '/verify/{locale}/{key}'
	});
	let [errors, errorsSet] = useState(false);
	let [locs, locsSet] = useState([]);

	// Refs
	let refConfirm = useRef();

	// Load effect
	useEffect(() => {
		locsSet(
			Locales.subscribe(locsSet)
		);
		return () => {
			Locales.unsubscribe(locsSet);
		}
	// es-lint
	}, []);

	// Called when a field changes
	function fieldChanged(name, value) {
		dataSet(data => {
			data[name] = typeof value === 'string' ? value.trim() : value;
			return clone(data);
		});
	}

	// Called to sign up the user
	function signUp() {

		// If the passwords don't match
		if(data.passwd !== refConfirm.current.value) {
			errorsSet({confirm_password: 'mismatch'});
			return;
		}

		// Reset errors
		errorsSet(false);

		// Add the locale
		data.locale = props.locale;

		// Send the data to the server
		Rest.create('main', 'signup', data).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 1001) {
					let oErrors = Rest.toTree(res.error.msg);
					if(oErrors.communication === 'no valid option') {
						oErrors.communication = {number: 'invalid'};
					}
					errorsSet(oErrors);
				} else if(res.error.code === 2004) {
					errorsSet({email: 'email_used'});
				} else if(res.error.code === 2102) {
					errorsSet({passwd: 'password_strength'});
				} else {
					Events.trigger('error', res.error);
				}
			} else if(res.data) {

				// Set the session with the service
				Rest.session(res.data.session);

				// Call the sign in event
				Events.trigger('signedIn', res.data.user);
			}
		});
	}

	// Render
	return (
		<Box id="signup">
			<Typography variant="h1">{translate('home', 'title', props.locale)}</Typography>
			<Typography>{translate('home', 'primary', props.locale)}</Typography>
			<br />
			<Typography variant="h2">{translate('signup', 'title', props.locale)}</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box className="field">
						<Typography>{translate('common', 'language', props.locale)}</Typography>
						{locs.length > 0 &&
							<Select
								native
								onChange={ev => {
									Events.trigger('locale', ev.target.value)
								}}
								value={props.locale}
								variant="outlined"
							>
								{locs.map(o =>
									<option key={o._id} value={o._id}>{o.name}</option>
								)}
							</Select>
						}
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box className="field">
						<Typography>E-mail</Typography>
						<TextField
							error={errors.email ? true : false}
							helperText={errors.email ? translate('errors', errors.email, props.locale) : ''}
							onChange={ev => fieldChanged('email', ev.target.value)}
							type="text"
							value={data.email}
							variant="outlined"
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					<Box className="field">
						<Typography>{translate('common', 'password', props.locale)}</Typography>
						<TextField
							error={errors.passwd ? true : false}
							helperText={errors.passwd ? translate('errors', errors.passwd, props.locale) : ''}
							onChange={ev => fieldChanged('passwd', ev.target.value)}
							type="password"
							value={data.passwd}
							variant="outlined"
						/>
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					<Box className="field">
						<Typography>{translate('common', 'confirm_password', props.locale)}</Typography>
						<TextField
							error={errors.confirm_password ? true : false}
							helperText={errors.confirm_password ? translate('errors', errors.confirm_password, props.locale) : ''}
							inputRef={refConfirm}
							type="password"
							variant="outlined"
						/>
					</Box>
				</Grid>
				<Communication
					errors={errors.communication || {}}
					locale={props.locale}
					onChange={val => fieldChanged('communication', val)}
					value={data.communication}
					variant="outlined"
				/>
			</Grid>
			<Box className="actions">
				<Button
					onClick={signUp}
					variant="contained"
				>
					{translate('signup', 'title', props.locale)}
				</Button>
			</Box>
		</Box>
	);
}

// Valid props
SignUp.propTypes = {
	locale: PropTypes.string.isRequired
}

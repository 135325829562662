/**
 * Clear
 *
 * Handles clear matches/conversations page
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-12-01
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

/**
 * Clear
 *
 * Displays a button to allow clearing all existing matches
 *
 * @name Clear
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Clear(props) {

	// State
	let [cleared, clearedSet] = useState(false);

	// Clear matches
	function clear() {
		Rest.update('admin', 'clear').done(res => {
			clearedSet(res.data);
		});
	}

	// Render
	return (
		<Box className="padding">
			<Typography>Click the below button to clear ALL current conversations/matches in the system. If you are somehow seeing this in production DO NOT CLICK IT!!!!</Typography>
			<Button variant="contained" onClick={clear}>Clear</Button>
			{cleared !== false &&
				<React.Fragment>
					<Typography>{cleared.conversations} conversations cleared</Typography>
					<Typography>{cleared.matches} matches cleared</Typography>
				</React.Fragment>
			}
		</Box>
	);

}

// Valid props
Clear.propTypes = {
	mobile: PropTypes.bool.isRequired
}


/**
 * Employer Posting View
 *
 * Displays the view of posting information that potential employees see, as
 * well as the preview employers see of their own posting
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-11-25
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Hidden from '@material-ui/core/Hidden';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';

// Composite components
import HoursDisplay from 'components/composites/HoursDisplay';
import Share from 'components/composites/Share';

// Shared generic modules
import { nice } from 'shared/generic/dates';
import { isTouch } from 'shared/generic/touch';

// Data modules
import translate from 'data/translate';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="top" ref={ref} {...props} />;
});

/**
 * Details
 *
 * Shared details section between desktop and mobile. Avoids constantly fixing
 * code twice, or worse, forgetting to fix one of two.
 *
 * @name Details
 * @access private
 * @param Object props Attributes sent to the component
 * @returns React.Component
 */
function Details(props) {

	// Render
	return (
		<React.Fragment>
			<Typography className="name">{props.value.employer.name.toUpperCase()}</Typography>
			<Typography className="type">
				{props.info.establishmentTypes[props.value.employer.type][props.locale]}
				&nbsp;&nbsp;
				<span className="salary blue_oval">
					${props.value.salary_amount}/{translate('common', 'salary_type_' + props.value.salary_type, props.locale)}
					{props.value.tip_average &&
						<span> + &#x2248;${props.value.tip_average}/{translate('common', 'salary_type_hourly', props.locale)}</span>
					}
				</span>
			</Typography>
			{props.value.times.type !== '' &&
				<Typography className="profession">
					{translate('common', 'times_' + props.value.times.type, props.locale)}
					<span className="blue">&nbsp; | &nbsp;</span>
					{props.value.profession && props.info.professions[props.value.profession][props.locale]}
				</Typography>
			}
			{props.value.skills && props.value.skills.length > 0 &&
				<Typography className="skills">
					{props.value.skills.map(s =>
						<span key={s} className="skill">{props.info.skills[s][props.locale]}</span>
					)}
				</Typography>
			}
			{props.value.times.type !== '' &&
				<React.Fragment>
					{props.value.times.type === 'gig' ?
						<Box className="gig_dates">
							{props.value.times.shifts.map((o,i) =>
								<Typography key={i} className="date">
									{o.starts.substr(0,10)}
									<span className="blue"> @ </span>
									{o.starts.substr(11,5)}
									<HoursDisplay locale={props.locale} value={o.hours} />
								</Typography>
							)}
						</Box>
					:
						<Typography className="starts">
							<span className="blue bold">{translate('common', 'starts', props.locale)} </span>
							{nice(props.value.times.starts, props.locale, (props.mobile ? 'short' : 'long'), false, false)}
							<HoursDisplay locale={props.locale} value={props.value.times.hours} />
							{props.value.times.ends &&
								<Typography className="starts">
									<span className="blue bold">{translate('common', 'ends', props.locale)} </span>
									{nice(props.value.times.ends, props.locale, (props.mobile ? 'short' : 'long'), false, false)}
								</Typography>
							}
						</Typography>
					}
				</React.Fragment>
			}
			{props.value.employer.bio && props.value.employer.bio.length > 0 && props.value.employer.bio.split('\n').map((s,i) => <Typography key={i}>{s}</Typography>)}
			{props.value.description && props.value.description.length > 0 && props.value.description.split('\n').map((s,i) => <Typography key={i}>{s}</Typography>)}
		</React.Fragment>
	);
}

// Valid props
Details.propTypes = {
	info: PropTypes.object.isRequired,
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	value: PropTypes.object.isRequired
}

/**
 * PostingView
 *
 * Displays employee info
 *
 * @name PostingView
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function PostingView(props) {

	// State
	let [share, shareSet] = useState(false);
	let [offset, offsetSet] = useState({});

	// Hooks
	let history = useHistory();

	// Hooks
	let swipeHandlers = useSwipeable({
		onSwipedLeft: swipeLeft,
		onSwipedRight: swipeRight,
		onSwiping: swiping,
		preventDefaultTouchmoveEvent: true
	});

	// If we have no swipe
	if(!props.swipe) {
		swipeHandlers = {}
	}

	// Called on a swipe left, or a thumbs down
	function swipeLeft() {
		props.swipe('left');
	}

	// Called on a swipe right, or a thumbs up
	function swipeRight() {
		props.swipe('right');
	}

	// Called during swiping
	function swiping(ev) {
		offsetSet({
			borderLeft: '1px solid white',
			borderRight: '1px solid white',
			left: ev.deltaX - 1,
			right: (ev.deltaX - (ev.deltaX * 2)) + 1
		});
	}

	// Render
	return (
		<Box id="view" className="flexRows" style={offset}>
			{(props.close && props.close !== true) &&
				<Box className="close">
					<Button variant="contained" onClick={props.close}>{translate('common', 'preview_close', props.locale)}</Button>
				</Box>
			}
			<Hidden only={['md', 'lg', 'xl']}>
				<Box {...swipeHandlers} className="image" style={{backgroundImage: `url(${props.value.background})`}}>
					<Box className="gradient flexRows">
						{(!isTouch() && props.swipe) &&
							<Box className="clickContainer">
								<Box className="clickLeft">
									<i className="fas fa-thumbs-down"  onClick={swipeLeft} />
								</Box>
								<Box className="clickRight">
									<i className="fas fa-thumbs-up"  onClick={swipeRight} />
								</Box>
							</Box>
						}
						<Box className="flexGrow">
							{props.public ? (
								<Box className="public">
									<Box style={{display: 'table-cell', fontSize: '2rem', fontWeight: 'bolder', verticalAlign: 'middle'}}>
										<Button
											onClick={ev => history.push(props.public.url)}
											size="large"
											variant="contained"
										>
											{props.public.text}
										</Button>
									</Box>
								</Box>
							) : (
								<span>&nbsp;</span>
							)}
						</Box>
						<Box className="details flexStatic">
							<Details
								info={props.info}
								locale={props.locale}
								mobile={props.mobile}
								value={props.value}
							/>
						</Box>
					</Box>
				</Box>
				{props.share &&
					<Box className="share">
						<i className="fas fa-share-alt" title={translate('common', 'share', props.locale)} onClick={() => shareSet(true)} />
					</Box>
				}
			</Hidden>
			<Hidden only={['xs', 'sm']}>
				<Box className="container md relative flexGrow">
					{(!isTouch() && props.swipe) &&
						<Box className="clickContainer">
							<Box className="clickLeft">
								<i className="fas fa-thumbs-down"  onClick={swipeLeft} />
							</Box>
							<Box className="clickRight">
								<i className="fas fa-thumbs-up"  onClick={swipeRight} />
							</Box>
						</Box>
					}
					<Box {...swipeHandlers} className="flexColumns" style={{height: '100%'}}>
						<Box className="details flexGrow flexRows">
							<Box className="flexStatic">
								<Details
									info={props.info}
									locale={props.locale}
									mobile={props.mobile}
									value={props.value}
								/>
							</Box>
							{props.public &&
								<Box className="flexGrow">
									<Box className="public">
										<Box style={{display: 'table-cell', fontSize: '2rem', fontWeight: 'bolder', verticalAlign: 'middle'}}>
											<Button
												onClick={ev => history.push(props.public.url)}
												size="large"
												variant="contained"
											>
												{props.public.text}
											</Button>
										</Box>
									</Box>
								</Box>
							}
						</Box>
						<Box className="image flexGrow" style={{backgroundImage: `url(${props.value.background})`}}>
							&nbsp;
						</Box>
					</Box>
					{props.share &&
						<Box className="share">
							<i className="fas fa-share-alt" title={translate('common', 'share', props.locale)} onClick={() => shareSet(true)} />
						</Box>
					}
				</Box>
			</Hidden>
			{share &&
				<Dialog
					className="employer_posting_share"
					onClose={() => shareSet(false)}
					open={true}
					TransitionComponent={Transition}
				>
					<DialogContent className="center">
						<Share
							locale={props.locale}
							text={props.share.text}
							url={props.share.url}
						/>
					</DialogContent>
				</Dialog>
			}
		</Box>
	);
}

// Valid props
PostingView.propTypes = {
	close: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	locale: PropTypes.string.isRequired,
	info: PropTypes.object.isRequired,
	mobile: PropTypes.bool.isRequired,
	public: PropTypes.object,
	share: PropTypes.object,
	swipe: PropTypes.func,
	value: PropTypes.object.isRequired
}

// Default props
PostingView.defaultProps = {
	close: false
}
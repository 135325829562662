/**
 * Offline
 *
 * Handles displaying an error message about user being offline
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-05-18
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// Data modules
import translate from 'data/translate';

/**
 * Offline
 *
 * Handles switching between admin functions
 *
 * @name Offline
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Offline(props) {

	// Render
	return (
		<Box id="offline" className="flexStatic">
			<Typography>{translate('errors', 'offline', props.locale)}</Typography>
		</Box>
	);
}

// Valid props
Offline.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired
}


/**
 * Admin Users Employees Employee component
 *
 * Handles displaying the specific user info for the employee/employer
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-11-23
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Material UI
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

// Local components
import User from './User';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic
import Events from 'shared/generic/events';
import { nicePhone } from 'shared/generic/tools';

/**
 * Employee
 *
 * Handles displaying employee specific info
 *
 * @name Employee
 * @access public
 * @param Object props Attributes passed to the component
 * @return React.Component
 */
export default function Employee(props) {

	// State
	let [confirm, confirmSet] = useState(false);
	let [tab, tabSet] = useState(0);

	// Remove the employer account
	function remove() {

		// Remove the employer record
		Rest.delete('admin', 'employee', {
			_id: props.value._id
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If there's data
			if(res.data) {

				// Trigger removing the row
				Events.trigger('admin_user_search_remove', props.value._id);
			}
		});
	}

	// Render
	return (
		<Box>
			<AppBar position="static" color="default">
				<Tabs
					indicatorColor="primary"
					onChange={(ev, tab) => tabSet(tab)}
					value={tab}
					variant="fullWidth"
				>
					<Tab label="Employee Account" />
					<Tab label="User Account" />
				</Tabs>
			</AppBar>
			<Box>
				{tab === 0 &&
					<Box className="padding">
						<Typography variant="h3">{props.value.fname} {props.value.lname}</Typography>
						<Typography><strong>
							{props.info.professions[props.value.professions.one]['en-CA']}
							{props.value.professions.two && (', ' + props.info.professions[props.value.professions.two]['en-CA'])}
							{props.value.professions.three && (', ' + props.info.professions[props.value.professions.three]['en-CA'])}
						</strong></Typography>
						<Typography>{props.value.skills.map((_id, i) =>
							props.info.skills[_id]['en-CA'] + (i + 1 !== props.value.skills.length ? ', ' : '')
						)}</Typography>
						<br />
						{props.value.bio && props.value.bio.length > 0 && props.value.bio.split('\n').map((s,i) => <Typography key={i}>{s}</Typography>)}
						<br />
						<Typography>{props.value.languages.map((_id, i) =>
							props.info.languages[_id]['en-CA'] + (i + 1 !== props.value.languages.length ? ', ' : '')
						)}</Typography>
						<Typography>{props.value.address.postal_code}</Typography>
						<Typography>{nicePhone(props.value.phone)}</Typography>
						<Typography>Vaccinated: {(props.value.covidVaccination && props.value.covidVaccination) ? 'Yes':'No'}</Typography>
						<br />
						{props.value.experience.map(exp =>
							<React.Fragment>
								<Typography><strong>{exp.establishment}</strong></Typography>
								<Typography>{props.info.professions[exp.profession]['en-CA']}</Typography>
								<Typography>{exp.address.city}, {exp.address.division}, {exp.address.country}</Typography>
								<Typography>{exp.from} - {exp.till ? exp.till : 'current'}</Typography>
								<br />
							</React.Fragment>
						)}
						<br />
						{(props.value.photos && props.value.photos.length > 0) &&
							<a href={props.value.photos[0].url} target="_blank" rel="noreferrer">
								<img src={props.value.photos[0].url} alt="employer_photo" />
							</a>
						}
						<br />
						<Box className="actions">
							<Button className="red" variant="contained" onClick={() => confirmSet(true)}>Delete</Button>
						</Box>
						{confirm &&
							<Dialog
								onClose={() => confirmSet(false)}
								open={true}
							>
								<DialogTitle>Confirm Delete of Employer</DialogTitle>
								<DialogContent>
									<Typography>Please confirm you want to delete the Employee account for <strong>{props.value.fname}, {props.value.lname}</strong>.</Typography>
									<Typography>This will not delete the primary user account, only the Employee data associated with it.</Typography>
								</DialogContent>
								<DialogActions>
									<Button className="red" variant="contained" onClick={remove}>Delete</Button>
								</DialogActions>
							</Dialog>
						}
					</Box>
				}
				{tab === 1 &&
					<User _id={props.value._id} locales={props.locales} />
				}
			</Box>
		</Box>
	);
}

// Valid props
Employee.propTypes = {
	info: PropTypes.object.isRequired,
	locales: PropTypes.array.isRequired,
	value: PropTypes.object.isRequired
}

/**
 * Root
 *
 * Displays the homepage
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-07
 */

// NPM modules
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Material UI
//import Box from '@material-ui/core/Box';
//import Container from '@material-ui/core/Container';

// Composite components
import Continue from './continue';
import Main from './Main';
import SignUp from './SignUp';

/**
 * Root
 *
 * Handles homepage
 *
 * @name Root
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Root(props) {

	// Hooks
	let history = useHistory();
	let location = useLocation();

	// Inner render
	let Inner = null

	// If we have no user, show the signup
	if(props.user === false) {
		if(location.pathname === '/signup') {
			Inner = <SignUp locale={props.locale} />
		} else {
			Inner = <Main locale={props.locale} />
		}
	} else {

		// If the user is an admin
		if(props.user.admin) {
			history.push('/admin')
			return '';
		}
		// Else, if the user is employee only
		else if(props.user.employee && !props.user.employer) {
			history.push('/employee');
			return '';
		}
		// Else, if the user is employer only
		else if(!props.user.employee && props.user.employer) {
			history.push('/employer');
			return '';
		}
		// Else, show the signup continuation
		else {
			Inner = (
				<Continue {...props} />
			);
		}
	}

	// Render
	return Inner
}

// Valid props
Root.propTypes = {
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired
}

/**
 * Result By Locale
 *
 * Handles displaying a single result of a record with data stored by multiple
 * locales
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-16
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import { clone } from 'shared/generic/tools';

/**
 * Result By Locale
 *
 * Displays a form to add a new string, one for each active locale
 *
 * @name ResultByLocale
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function ResultByLocale(props) {

	// State
	let [errors, errorsSet] = useState(false);
	let [edit, editSet] = useState(false);

	// Active changed
	function activeChanged(checked) {
		editSet(edit => {
			edit.active = checked;
			return clone(edit);
		});
	}

	// Locale changed
	function localeChanged(locale, value) {
		editSet(edit => {
			edit.locales[locale] = value;
			return clone(edit);
		});
	}

	// Called to create the record
	function update() {

		// Reset errors
		errorsSet(false);

		// Send the data to the server
		Rest.update('admin', props.noun, edit).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 1001) {
					errorsSet(res.error.msg.map(l => l[0] + ' is ' + l[1]))
				} else {
					errorsSet([JSON.stringify(res.error)]);
				}
			}

			// If we're successful
			if(res.data) {

				// Let the parent know
				props.onUpdated(edit);

				// Leave edit mode
				editSet(false);
			}
		});
	}

	// Render
	return (
		<Paper className="result">
			{errors &&
				<Box className="errors">{errors.map(s => <Typography>{s}</Typography>)}</Box>
			}
			<Box className="flexColumns">
				<Grid container spacing={2} className="flexGrow">
					{props.locales.map(o =>
						<Grid item xs={12} sm={6} md={4} lg={3} key={o._id}>
							{edit ?
								<React.Fragment>
									<Typography>{o.name}</Typography>
									<TextField
										onChange={ev => localeChanged(o._id, ev.target.value)}
										type="text"
										value={edit.locales[o._id] || ''}
										variant="outlined"
									/>
								</React.Fragment>
							:
								<Typography><strong>{o.name}</strong> {props.result.locales[o._id] || ''}</Typography>
							}
						</Grid>
					)}
					<Grid item xs={12} sm={6} md={4} lg={3}>
						{edit ?
							<FormControlLabel
								control={<Checkbox
											color="primary"
											checked={edit.active}
											onChange={ev => activeChanged(ev.target.checked)}
										/>}
								label="Active"
							/>
						:
							props.result.active ? 'Active' : 'Disabled'
						}
					</Grid>
				</Grid>
				<Box className="flexStatic">
					{edit ?
						<i className="fas fa-save" onClick={update} />
					:
						<i className="fas fa-edit" onClick={ev => editSet(clone(props.result))} />
					}
				</Box>
			</Box>
		</Paper>
	);
}

// Valid props
ResultByLocale.propTypes = {
	locales: PropTypes.array.isRequired,
	noun: PropTypes.string.isRequired,
	onUpdated: PropTypes.func.isRequired,
	result: PropTypes.object.isRequired
}

/**
 * Locales
 *
 * Handles get/set of locales
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-14
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import { clone } from 'shared/generic/tools';

/**
 * Create
 *
 * Handles creating a new locale
 *
 * @name Create
 * @access private
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
function Create(props) {

	// State
	let [errors, errorsSet] = useState(false);

	// Refs
	let refID = useRef();
	let refName = useRef();
	let refActive = useRef();

	// Called to create the locale
	function submit() {

		// Reset errors
		errorsSet(false);

		// Store the data
		let oData = {
			_id: refID.current.value,
			name: refName.current.value,
			active: refActive.current.checked
		};

		// Send the data to the server
		Rest.create('admin', 'locale', oData).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 1001) {
					errorsSet(res.error.msg.map(l => l[0] + ' is ' + l[1]))
				} else {
					errorsSet([JSON.stringify(res.error)]);
				}
			}

			// If we're successful
			if(res.data) {

				// Let the parent know
				props.onSuccess(res.data);
			}
		});
	}

	// Render
	return (
		<Paper className="create">
			<Typography className="title">Create New Locale</Typography>
			{errors &&
				<Box className="errors">{errors.map(s => <Typography>{s}</Typography>)}</Box>
			}
			<Grid container spacing={2}>
				<Grid item xs={12} sm={5} md={3}>
					<Typography>Identifier</Typography>
					<TextField
						inputRef={refID}
						type="text"
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={12} sm={5} md={3}>
					<Typography>Name</Typography>
					<TextField
						inputRef={refName}
						type="text"
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={12} sm={2} md={1}>
					<FormControlLabel
						control={<Checkbox
									color="primary"
									inputRef={refActive}
								/>}
						label="Active"
					/>
				</Grid>
			</Grid>
			<Box className="actions">
				<Button color="secondary" onClick={props.onCancel} variant="contained">Cancel</Button>
				<Button color="primary" onClick={submit} variant="contained">Create</Button>
			</Box>
		</Paper>
	);
}

// Valid props
Create.propTypes = {
	onCancel: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired
}

/**
 * Locale
 *
 * Handles displaying / editing a single locale
 *
 * @name Locale
 * @access private
 * @param Object props Attributes sent to the component
 */
function Locale(props) {

	// State
	let [errors, errorsSet] = useState(false);
	let [edit, editSet] = useState(false);

	// Active changed
	function fieldChanged(name, value) {
		editSet(edit => {
			edit[name] = value;
			return clone(edit);
		});
	}

	// Update the record
	function update() {

		// Reset errors
		errorsSet(false);

		// Send the data to the server
		Rest.update('admin', 'locale', edit).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 1001) {
					errorsSet(res.error.msg.map(l => l[0] + ' is ' + l[1]))
				} else {
					errorsSet([JSON.stringify(res.error)]);
				}
			}

			// If we're successful
			if(res.data) {

				// Let the parent know
				props.onUpdated(edit);

				// Leave edit mode
				editSet(false);
			}
		});
	}

	// Render
	return (
		<Paper className="result">
			{errors &&
				<Box className="errors">{errors.map(s => <Typography>{s}</Typography>)}</Box>
			}
			<Box className="flexColumns">
				<Grid container spacing={2} className="flexGrow">
					<Grid item xs={12} sm={5} md={3}>
						{props.result._id}
					</Grid>
					<Grid item xs={12} sm={5} md={3}>
						{edit ?
							<React.Fragment>
								<Typography>Name</Typography>
								<TextField
									onChange={ev => fieldChanged('name', ev.target.value)}
									type="text"
									value={edit.name}
									variant="outlined"
								/>
							</React.Fragment>
						:
							props.result.name
						}
					</Grid>
					<Grid item xs={12} sm={2} md={1}>
						{edit ?
							<FormControlLabel
								control={<Checkbox
											color="primary"
											checked={edit.active}
											onChange={ev => fieldChanged('active', ev.target.checked)}
										/>}
								label="Active"
							/>
						:
							props.result.active ? 'Active' : 'Disabled'
						}
					</Grid>
				</Grid>
				<Box className="flexStatic">
					{edit ?
						<i className="fas fa-save" onClick={update} />
					:
						<i className="fas fa-edit" onClick={ev => editSet(clone(props.result))} />
					}
				</Box>
			</Box>
		</Paper>
	);
}

// Valid props
Locale.propTypes = {
	onUpdated: PropTypes.func.isRequired,
	result: PropTypes.object.isRequired
}

/**
 * Locales
 *
 * Handles switching between admin functions
 *
 * @name Locales
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Locales(props) {

	// State
	let [create, createSet] = useState(false);

	// Render
	return (
		<Box className="locales padding">
			<Box className="header flexColumns">
				<Typography className="title flexGrow">Locales</Typography>
				<Box className="flexStatic">
					<Tooltip title="Create New Locale">
						<IconButton onClick={ev => createSet(b => !b)}>
							<i className="fas fa-plus-circle"/>
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
			{create &&
				<Create
					onCancel={() => createSet(false)}
					onSuccess={locale => {
						props.onLocaleAdded(locale);
						createSet(false);
					}}
				/>
			}
			{props.locales.length === 0 ?
				<Typography>Nothing returned</Typography>
			:
				props.locales.map(o =>
					<Locale
						key={o._id}
						onUpdated={props.onLocaleUpdated}
						result={o}
					/>
				)
			}
		</Box>
	);
}

// Valid props
Locales.propTypes = {
	locales: PropTypes.array.isRequired,
	onLocaleAdded: PropTypes.func.isRequired,
	onLocaleUpdated: PropTypes.func.isRequired
}

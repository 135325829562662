/**
 * Employer
 *
 * Switch for employer pages
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-19
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';

// Material UI
import Box from '@material-ui/core/Box';

// Locale components
import EmployerCreate from './Create.js'
import Main from './Main';
import Messaging from './Messaging';
import PostingCreate from './posting/Create';
import PostingCopy from './posting/Copy';
import PostingEdit from './posting/Edit';
import PostingMatch from './posting/Match';
import PostingMatches from './posting/Matches';
import Profile from './Profile';

// Shared communication modules
import Rest from 'shared/communication/rest';
import TwoWay from 'shared/communication/twoway';

// Shared generic modules
import Events from 'shared/generic/events';
import PageVisibility from 'shared/generic/pageVisibility';
import { afindi, clone } from 'shared/generic/tools';

/**
 * Employer
 *
 * Handles employer pages
 *
 * @name Employer
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Employer(props) {

	// State
	let [employer, employerSet] = useState(false);
	let [info, infoSet] = useState(false);

	// Hooks
	let history = useHistory();

	// Refs
	let refEmployer = useRef(null);

	// Load effect
	useEffect(() => {
		infoFetch();
	// eslint-disable-next-line
	}, []);

	// Employer effect
	useEffect(() => {

		// Store the employer ID
		refEmployer.current = props.current;

		// Get the live data
		employerFetch(refEmployer.current);

		// Track any match changes
		TwoWay.track('matches', refEmployer.current, matchMessage);

		// Track page visibility changes
		function pageVis(property, state) {
			if(state === 'visible') {
				employerFetch(refEmployer.current);
				TwoWay.track('matches', refEmployer.current, matchMessage);
			} else {
				TwoWay.untrack('matches', refEmployer.current, matchMessage);
			}
		}
		PageVisibility.subscribe(pageVis);

		// Unsubscribe on exit
		return () => {
			PageVisibility.unsubscribe(pageVis);
			TwoWay.untrack('matches', refEmployer.current, matchMessage);
		};

	// eslint-disable-next-line
	}, [props.current]);

	// Fetch the current employer and postings
	function employerFetch(_id) {
		Rest.read('main', 'employer', {
			_id: _id,
			postings: true,
			unread: true,
			urls: true
		}).done(res => {
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}
			if(res.data) {
				employerSet(res.data);
			}
		});
	}

	// Fetch all info about and for the employer
	function infoFetch() {
		Rest.read('main', 'info', {}).done(res => {
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}
			if(res.data) {
				infoSet(res.data);
			}
		});
	}

	// Called when match websocket messages come in
	function matchMessage(msg) {

		// Make sure we are on the latest data
		employerSet(val => {

			// Look for the posting
			let i = afindi(val.postings, '_id', msg.posting);

			// If it's found
			if(i > -1) {
				let oEmployer = clone(val);
				if(msg.type === 'increment') {
					oEmployer.postings[i][msg.name] += 1;
				} else if(msg.type === 'decrement') {
					oEmployer.postings[i][msg.name] -= 1;
				} else {
					console.log('invalid message type');
				}
				return oEmployer;
			}
			return val;
		});
	}

	// Called when something changes in the posting counts
	function postingCounts(_id, type) {

		// Find the posting
		let i = afindi(employer.postings, '_id', _id);

		// If it's found
		if(i > -1) {

			// Clone the employer
			let oEmployer = clone(employer);

			// Switch based on type
			switch(type) {

				// If we matched with someone
				case 'accept':
					oEmployer.postings[i].potential_matches -= 1;
					break;

				// If we rejected someone
				case 'reject':
					oEmployer.postings[i].potential_matches -= 1;
					break;

				// no default
			}

			// Update the employer
			employerSet(oEmployer);
		}
	}

	// Called when a posting is marked as filled
	function postingFilled(_id) {

		// Find the posting
		let i = afindi(employer.postings, '_id', _id);

		// If it's found
		if(i > -1) {

			// Clone the employer
			let oEmployer = clone(employer);

			// Update the filled
			oEmployer.postings[i].filled = true;

			// Update the employer
			employerSet(oEmployer);
		}
	}

	// Called when an existing posting is updated, or a new posting is added
	function postingSuccess(posting) {

		// Clone the current postings
		let lPostings = clone(employer.postings);

		// Try to find the posting
		let i = afindi(lPostings, '_id', posting._id);

		// If it exists
		if(i > -1) {

			// Updated it
			lPostings[i] = posting
		}

		// Else, add it to the top of the list
		else {
			posting.potential_matches = 0;
			lPostings.unshift(posting);
		}

		// Add it to the employer
		employer.postings = lPostings;

		// Update employer
		employerSet(clone(employer));

		// Redirect to main
		history.push('/employer');
	}

	// If there's no info yet
	if(!info) {
		return <div />
	}

	// Render
	return (
		<Switch>
			<Route exact path="/employer/create">
				<Box className="padding">
					<EmployerCreate
						locale={props.locale}
						mobile={props.mobile}
						user={props.user}
					/>
				</Box>
			</Route>
			<Route path="/employer/messaging">
				<Messaging
					current={props.current}
					info={info}
					locale={props.locale}
					mobile={props.mobile}
					user={props.user}
				/>
			</Route>
			<Route exact path="/employer/profile">
				<Profile
					employer={employer}
					locale={props.locale}
					mobile={props.mobile}
					user={props.user}
				/>
			</Route>
			<Route exact path="/employer/posting/create">
				<PostingCreate
					employer={employer}
					locale={props.locale}
					info={info}
					mobile={props.mobile}
					onCancel={() => history.push('/employer')}
					onSuccess={postingSuccess}
					user={props.user}
				/>
			</Route>
			<Route exact path="/employer/posting/edit/:_id">
				<PostingEdit
					employer={employer}
					locale={props.locale}
					info={info}
					mobile={props.mobile}
					onCancel={() => history.push('/employer')}
					onSuccess={postingSuccess}
					user={props.user}
				/>
			</Route>
			<Route exact path="/employer/posting/copy/:_id">
				<PostingCopy
					employer={employer}
					locale={props.locale}
					info={info}
					mobile={props.mobile}
					onCancel={() => history.push('/employer')}
					onSuccess={postingSuccess}
					user={props.user}
				/>
			</Route>
			<Route exact path="/employer/posting/match/:_id">
				<PostingMatch
					counts={postingCounts}
					locale={props.locale}
					info={info}
					mobile={props.mobile}
					user={props.user}
				/>
			</Route>
			<Route exact path="/employer/posting/matches/:_id">
				<PostingMatches
					locale={props.locale}
					info={info}
					mobile={props.mobile}
					user={props.user}
				/>
			</Route>
			<Route exact path="/employer">
				<Main
					employer={employer}
					filled={postingFilled}
					locale={props.locale}
					info={info}
					mobile={props.mobile}
					user={props.user}
				/>
			</Route>
		</Switch>
	);
}

// Valid props
Employer.propTypes = {
	current: PropTypes.string.isRequired,
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}

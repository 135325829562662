/**
 * Employer Profile
 *
 * Handles updating the employer account
 *
 * @author Chris Nasr <chris@ouroboroscoding.com>
 * @copyright Ouroboros Coding Inc.
 * @created 2021-03-18
 */

// NPM modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Material UI Phone Number
import MuiPhoneInput from 'material-ui-phone-number';

// Composite components
import Address from 'components/composites/Address';
import PhotoUpload from 'components/composites/PhotoUpload';
import Popup from 'components/composites/Popup';

// Shared communication modules
import Rest from 'shared/communication/rest';

// Shared generic modules
import Events from 'shared/generic/events';
import { clone, opop } from 'shared/generic/tools';

// Local data modules
import locales from 'data/locales';
import translate from 'data/translate';

/**
 * Profile
 *
 * Handles employer setup
 *
 * @name Profile
 * @access public
 * @param Object props Attributes sent to the component
 * @return React.Component
 */
export default function Profile(props) {

	// State
	let [data, dataSet] = useState(false);
	let [errors, errorsSet] = useState({});
	let [photos, photosSet] = useState(null);
	let [message, messageSet] = useState(false);
	let [ready, readySet] = useState(false);
	let [setup, setupSet] = useState({});

	// Load effect
	useEffect(() => {

		// Fetch only the active info
		Rest.read('main', 'setup', {}).done(res => {

			// Init the new structure
			let oSetup = {};

			// Go through each type and sort alphabeticall
			for(let s of ['establishmentTypes']) {
				oSetup[s] = locales.sortObject(res.data[s]);
			}

			// Set the setup
			setupSet(oSetup);
		});
	}, []);

	// Employer effect
	useEffect(() => {

		// If we have an employer
		if(props.employer) {
			employerFetch();
		} else {
			dataSet(null);
		}

	// eslint-disable-next-line
	}, [props.employer]);

	// Employer Fetch
	function employerFetch() {

		// Request it from REST
		Rest.read('main', 'employer', {
			_id: props.employer._id,
			urls: true
		}).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				Events.trigger('error', res.error);
			}

			// If we got data
			if(res.data) {

				// If we have photos
				if(res.data.photos) {
					photosSet(opop(res.data, 'photos'));
				}

				// Store the employer
				dataSet(res.data);
				readySet(true);
			}
		});
	}

	// Field changed
	function fieldChanged(name, value) {
		dataSet(data => {
			if(typeof value === 'string') {
				value = value.trim() === '' ? null : value;
			}
			if(value !== null) {
				data[name] = value;
			} else {
				delete data[name];
			}
			return clone(data);
		});
	}

	// Called when the photo changes
	function photosChange(photos) {

		// Clear errors
		errorsSet(val => {
			if(val.photos) {
				let oClone = clone(val);
				delete oClone.photos;
				return oClone;
			}
			return val;
		});

		// If we got photos
		if(photos) {

			// Set the photos state
			photosSet(photos);

			// Set or overwrite the photo for the update data
			dataSet(val => {
				let oClone = clone(val);
				oClone.photos = photos;
				return oClone;
			});
		}

		// Else, they were removed
		else {

			// Clear the photos set
			photosSet([]);

			// Remove the photo from the update data
			dataSet(val => {
				if(val.photos) {
					let oClone = clone(val);
					delete oClone.photos;
					return oClone;
				}
				return val;
			});
		}
	}

	// Called when there's an error uploading a file
	function photosError(error) {
		errorsSet(val => {
			let oClone = clone(val);
			oClone.photos = error;
			return oClone;
		});
	}

	// Called to update the employer record
	function update() {

		// Reset errors
		errorsSet({});

		// Disable update button
		readySet(false);

		// Send the data to the server
		Rest.update('main', 'employer', data).done(res => {

			// If there's an error
			if(res.error && !res._handled) {
				if(res.error.code === 1001) {
					errorsSet(Rest.toTree(res.error.msg));
				} else if(res.error.code === 1002) {
					errorsSet({photos: [res.error.msg[1], res.error.msg[2] || '']});
				} else {
					Events.trigger('error', res.error);
				}
			}

			// If we're successful
			if(res.data) {

				// Notify the site
				Events.trigger('employer_change', data);

				// Notify the user
				messageSet(translate('account', 'updated', props.locale));
			}

			// Enable update button
			readySet(true);
		});
	}

	// Render
	return (
		<Box className="employer singlePage" style={{overflow: 'hidden'}}>
			{!data ?
				<Typography>{translate('common', 'loading', props.locale)}...</Typography>
			:
				<Box className="flexRows">
					<Box className="padding flexGrow">
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} className="field">
								<Typography>{translate('common', 'establishment_name', props.locale)}</Typography>
								<TextField
									error={errors.name ? true : false}
									helperText={errors.name ? translate('errors', errors.name, props.locale) : ''}
									onChange={ev => fieldChanged('name', ev.target.value)}
									type="text"
									value={data.name || ''}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={6} className="field">
								<Typography>{translate('common', 'establishment_type', props.locale)}</Typography>
								<FormControl
									error={errors.type ? true : false}
									helperText={errors.type ? translate('errors', errors.type, props.locale) : ''}
									variant="outlined"
								>
									<InputLabel htmlFor="employerEstablishmentType"></InputLabel>
									<Select
										native
										onChange={ev => fieldChanged('type', ev.target.value)}
										value={data.type}
										inputProps={{
											id: 'employerEstablishmentType'
										}}
									>
										<option value=""></option>
										{setup.establishmentTypes && setup.establishmentTypes[props.locale].map(o =>
											<option key={o._id} value={o._id}>{o.text}</option>
										)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} lg={6} className="field">
								<Typography>{translate('common', 'bio', props.locale)}</Typography>
								<TextField
									error={errors.bio ? true : false}
									helperText={errors.bio ? translate('errors', 'bio_' + errors.bio, props.locale) : ''}
									multiline
									onChange={ev => fieldChanged('bio', ev.target.value)}
									type="text"
									value={data.bio || ''}
									variant="outlined"
									inputProps={{
										maxLength: 300
									}}
								/>
							</Grid>
							<Hidden mdDown>
								<Grid item lg={6}>&nbsp;</Grid>
							</Hidden>
							<Grid item xs={12}>
								<Address
									errors={errors.address || {}}
									fields={['line1', 'line2', 'city', 'postal_code']}
									locale={props.locale}
									onChange={address => fieldChanged('address', address)}
									value={data.address}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} className="field">
								<Typography>{translate('common', 'phone', props.locale)}</Typography>
								<MuiPhoneInput
									error={errors.phone ? true : false}
									helperText={errors.phone ? translate('errors', errors.phone, props.locale) : ''}
									defaultCountry="ca"
									onChange={number => fieldChanged('phone', number)}
									onlyCountries={['ca']}
									value={data.phone || ''}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12} lg={6}>
								<Typography>{translate('common', 'photo', props.locale)}</Typography>
								<PhotoUpload
									locale={props.locale}
									mobile={props.mobile}
									onChange={photosChange}
									onError={photosError}
									value={photos}
								/>
								{errors.photos &&
									<Typography className="error">{translate('errors', errors.photos[0], props.locale, {misc: errors.photos[1]})}</Typography>
								}
							</Grid>
						</Grid>
					</Box>
					<Box className="actions padding flexStatic">
						<Button disabled={!ready} onClick={update} variant="contained" color="primary">{translate('common', 'update', props.locale)}</Button>
					</Box>
				</Box>
			}
			{message &&
				<Popup
					content={message}
					onClose={() => messageSet(false)}
				/>
			}

		</Box>
	);
}

// Valid props
Profile.propTypes = {
	employer: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
	locale: PropTypes.string.isRequired,
	mobile: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
}
